
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  MainStepsContainer,
  ProfileInformation,
  StepDescription,
  StepDetails,
  StepInfo,
  StepTitle,
  IconImage
} from "../styled";

import { IoIosArrowForward } from "react-icons/io";
import profile from "assets/profile/usuario.svg";
import location from "assets/profile/location.svg";
import productos from "assets/profile/productos.svg";
import necesidad from "assets/profile/necesidad.svg";
import { RxExit } from "react-icons/rx";
import { MAIN_ROUTES } from "constants/routes";
import useIsLocalhost from "hooks/system/useIsLocalhost/use-is-localhost";

import { BREAKPOINTS } from "constants/index";

interface MainSectionProps {
    step: number;
    onChange: (step: number) => void;
    handleLogout: () => void;
}

const MainSection = (props: MainSectionProps) => {
    const { onChange, step, handleLogout } = props;
    const { t } = useTranslation();
    const isLocalhost = useIsLocalhost();


    return (
        <ProfileInformation
            style={{
                width: step === 0 ? "100%" : "35%",
                display: step !== 0 && window.innerWidth < BREAKPOINTS.lg ? "none" : "flex", 
            }}
        >
            <MainStepsContainer onClick={() => onChange(1)}>
                <StepDetails>
                    <img src={profile} alt="Perfil" title="Perfil" />
                    <StepInfo>
                        <StepTitle>{t('personal_information')}</StepTitle>
                        <StepDescription>
                            {`${t('name')}, ${t('last_name')}, ${t('phone')}, ${t('email_and_password')}`}
                        </StepDescription>
                    </StepInfo>
                </StepDetails>
                <IoIosArrowForward size={30} />
            </MainStepsContainer>

            <MainStepsContainer onClick={() => onChange(2)}>
                <StepDetails>
                    <img src={productos} alt="Productos" title="Productos" />
                    <StepInfo>
                        <StepTitle>{t('my_orders')}</StepTitle>
                        <StepDescription>
                            {t('historical_product_orders')}
                        </StepDescription>
                    </StepInfo>
                </StepDetails>
                <IoIosArrowForward size={30} />
            </MainStepsContainer>

            <MainStepsContainer onClick={() => onChange(3)}>
                <StepDetails>
                    <img src={necesidad} alt="Necesidad" title="Necesidad" />
                    <StepInfo>
                        <StepTitle>{t("my_requests")}</StepTitle>
                        <StepDescription>
                            {t("historical_requests_quotations")}
                        </StepDescription>
                    </StepInfo>
                </StepDetails>
                <IoIosArrowForward size={30} />
            </MainStepsContainer>

            <MainStepsContainer onClick={() => onChange(4)}>
                <StepDetails>
                    <img src={location} alt="Ubicación" title="Ubicación" />
                    <StepInfo>
                        <StepTitle>{t("my_addresses")}</StepTitle>
                        <StepDescription>
                            {t("my_addresses_description")}
                        </StepDescription>
                    </StepInfo>
                </StepDetails>
                <IoIosArrowForward size={30} />
            </MainStepsContainer>
            {
                isLocalhost && (
                    <Link to={MAIN_ROUTES.MyClassifieds}>
                        <MainStepsContainer onClick={() => { }}>
                            <StepDetails>
                                <IconImage src='https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Fclassified-64x64.png?alt=media&token=fff0f7a1-429c-4994-a6be-39f606c781f1' alt='icon classified' title='icon classified' />
                                <StepInfo>
                                    <StepTitle>{t("my_classifieds")}</StepTitle>
                                    <StepDescription>
                                        {t("view_edit_and_delete_your_classifieds")}
                                    </StepDescription>
                                </StepInfo>
                            </StepDetails>
                            <IoIosArrowForward size={30} />
                        </MainStepsContainer>
                    </Link>
                )
            }
            {/*
        <MainStepsContainer onClick={() => onChange(5)}>
          <StepDetails>
            <img src={favorito} alt="" />
            <StepInfo>
              <StepTitle>Mis favoritos</StepTitle>
              <StepDescription>
                Listado de productos y servicios favoritos
              </StepDescription>
            </StepInfo>
          </StepDetails>
          <IoIosArrowForward size={30} />
        </MainStepsContainer>
        */}
            <MainStepsContainer>
                <StepDetails>
                    <RxExit size={20} />
                    <StepInfo>
                        <StepTitle onClick={handleLogout}>{t('logout')}</StepTitle>
                    </StepInfo>
                </StepDetails>
                <IoIosArrowForward size={30} />
            </MainStepsContainer>
        </ProfileInformation>
    );
};

export default MainSection;