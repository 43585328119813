import MainLayout from 'views/layout/MainLayout'
import { Container, Content, NeedContainer, NeedImage, NeedsContainer, NeedsTitle, NeedTitle } from "./styled";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { theme } from "lib/theme";
import { ThemeProvider } from "@mui/material";
import useIsAuth from 'hooks/user/useIsAuth/useIsAuth';
import { useNotification } from 'lib/context/AlertContext';
import { MAIN_ROUTES } from 'constants/routes';

const Needs = () => {
    const navigation = useNavigate();
    const { t } = useTranslation();
    const isAuth  = useIsAuth()
    const { showWarning } = useNotification()

    const handleSelectTypeNeed = (type: string) => {
        if(!isAuth) {
            showWarning(t('you_must_login_to_be_able_to_submit_your_need'))
        } else {
            navigation(MAIN_ROUTES.NeedsList, {state: {name: type}})
        }
    }

    return (
        <MainLayout type='main'>
            <Container> 
                <Content>
                    <ThemeProvider theme={theme}>
                        <NeedsTitle>{t('we_are_a_personalized_search_platform')}</NeedsTitle>
                        <NeedsContainer>
                            <NeedContainer onClick={() => handleSelectTypeNeed('Services')} color={"#98d0ff"}>
                                <NeedImage src='https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Fneeds%2Fservices.png?alt=media&token=390801c0-4adc-408c-b2f4-9a447dca8afd'/>
                                <NeedTitle>{t('services')}</NeedTitle>
                            </NeedContainer>
                            <NeedContainer onClick={() => handleSelectTypeNeed('Products')} color={"#e3aaff"}>
                                <NeedImage src='https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Fneeds%2Fproducts.png?alt=media&token=2a45abfe-71bc-4379-829e-0bc6f47bf86c' />
                                <NeedTitle>{t('products')}</NeedTitle>
                            </NeedContainer> 
                            <Link to={MAIN_ROUTES.Chatbot}>
                                <NeedContainer onClick={() => {}} color={"#ebeafd"}>
                                    <NeedImage src='https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/assets%2Ficons%2FHabla%20con%20perry%20chat%20inteligencia%20artficial.webp?alt=media&token=a5b44345-ce6d-4db0-b89b-97c1ab5b19d0' />
                                    <NeedTitle>{t('chat_with_perry')}</NeedTitle> 
                                </NeedContainer>
                            </Link>
                            {/* <NeedContainer onClick={() => handleSelectTypeNeed('Others')} color={"#FAD49A"}>
                                <NeedImage src='https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Fneeds%2Fothers.png?alt=media&token=d22cfc4d-59bf-4ae3-b336-38dcc4b867d5' />
                                <NeedTitle>Otros</NeedTitle>
                            </NeedContainer> */}
                        </NeedsContainer>
                    </ThemeProvider>
                </Content>
            </Container>
        </MainLayout>
    )
}

export default Needs