import {useDispatch, useSelector} from 'react-redux'
import {userSelector} from 'redux/user'
import {useEffect} from 'react'
import {setAuth} from 'redux/auth'

const UseAuthentication = (): void => {

  const user = useSelector(userSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (user?.token !== '' ) {
      dispatch(setAuth({ isAuth: true }))
    }
  }, [user.token, user, dispatch])
}

export default UseAuthentication
