import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CategoryContainer, Description, GoTo, ImageIconPopUp, Name, TextPick, TextContainer } from "./styled";
import { ThemeProvider } from "@mui/material";
import ModalLoader from "ui/ModalLoader";
import { theme } from "lib/theme";
// Forms
import RealStateClassifiedForm from "views/containers/Forms/Classifieds/new-classified/real-state-form-classified/real-state-classified-form";
import VehicleClassifiedForm from "views/containers/Forms/Classifieds/new-classified/vehicle-form-classified";
import EmploymentClassifiedForm from "views/containers/Forms/Classifieds/new-classified/employment-form-classified";
import TechFormClassified from "views/containers/Forms/Classifieds/new-classified/tech-form-classified";
import CommonFormClassified from "views/containers/Forms/Classifieds/new-classified/common-form-classified";
import useCreateClassified from "hooks/classifieds/useCreateClassified/use-create-classified";
import useUpdateClassified from "hooks/classifieds/useUpdateClassified/use-update-classified";
import { MdOutlineArrowForwardIos } from "react-icons/md"; 
import { useNotification } from "lib/context/AlertContext";
import "./index.css";
import { IRealStateClassified, IVehicleClassified, ITechClassified, IEmploymentClassified, ICommonClassified } from "types";
import { formatValuesRealState, formatValuesVehicles} from "./main";
import { ClassifiedCategories } from "constants/classifieds";
import { userSelector } from "redux/user";
import { getLanguage, getCountry } from "redux/common";
import { useTranslation } from "react-i18next";

interface ITypedForm {
  category: string;
  onSubmit?: (values: IRealStateClassified | IVehicleClassified | ITechClassified | IEmploymentClassified | ICommonClassified ) => void;
  userUid: string;
  createClassifiedSuccess: boolean;
  categoryName: string;
  formProperties?: any;
  language: string;
  initialValues?: any; // Add initial values for editing
  isEditMode?: boolean; // Add isEditMode to determine if we are editing
}

const TypeFormSelector = (props: ITypedForm) => {
  const { category, onSubmit, userUid, createClassifiedSuccess, categoryName, formProperties, language, initialValues, isEditMode } = props;

  const formPropertyByType = formProperties?.find((formProperty) => formProperty.category === category)?.formProperties;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [category]);

  const commonProps = {
    categoryType: category,
    language,
    createClassifiedSuccess,
    userUid,
    category: categoryName,
    onSubmit: (values: any) => { onSubmit && onSubmit(values); },
    initialValues, // Pass initial values
    isEditMode // Pass isEditMode
  };

  switch (category) {
    case ClassifiedCategories.RealState:
      return <RealStateClassifiedForm {...commonProps} />;
    case ClassifiedCategories.Vehicles:
      return <VehicleClassifiedForm {...commonProps} formProperties={formPropertyByType} />;
    case ClassifiedCategories.Employments:
      return <EmploymentClassifiedForm {...commonProps} formProperties={formProperties} />;
    case ClassifiedCategories.Tech:
      return <TechFormClassified {...commonProps} formProperties={formProperties} />;
    case ClassifiedCategories.Home:
    case ClassifiedCategories.Construction:
    case ClassifiedCategories.Music:
    case ClassifiedCategories.Pets:
    case ClassifiedCategories.Sports:
      return <CommonFormClassified {...commonProps} formProperties={formProperties} />;
    default:
      return <></>;
  }
}

const NewClassifieds = (props: any) => {
  const { data, initialValues, isEditMode } = props; // Add initialValues and isEditMode props
  const { t } = useTranslation();
  const { showError, showSuccess} = useNotification();
  const user = useSelector(userSelector)?.userData;

  const language = useSelector(getLanguage);
  const country = useSelector(getCountry);
  const { createClassified, isLoading: isLoadingCreateError, error, response } = useCreateClassified();
  const { editClassified:updateClassified, isUpdating: isLoadingUpdateError, dataClassifiedEdited:updateResponse, updateError } = useUpdateClassified(); // Add update classified hook
  const [successCreateClassified, setSuccessCreateClassified] = useState(false);

  const [picked, setPicked] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<string>();

  useEffect(() => {
    let img = document.getElementsByClassName("uploadIcon")[0];
    if (img !== undefined) {
      img["src"] =
        "https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/assets%2Ficons%2Fadd-image.png?alt=media&token=9a56282b-da2e-4e9b-ac27-89f8a5523c05";
    }
  }, [picked]);

  useEffect(() => {
    if (response || updateResponse) { // Handle both create and update success
      showSuccess("Clasificado creado exitosamente");
      setSuccessCreateClassified(true);
    }

    if (error || updateError) { // Handle both create and update errors
      console.log("error de crear clasificado", error || updateError);
      showError("Error al crear clasificado");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error, updateResponse, updateError]);

  function handleSubmitCreateClassified(eSubmit: any) {
    const classifiedData = {
      ...eSubmit,
      userId: user.uid,
      country: country,
      contactUserData: {
        email: user.email,
        phone: user.phone,
        firstName: user.firstName,
        firstSurname: user.firstSurname,
      }
    };

    if (isEditMode) {
      if (eSubmit.categoryType === ClassifiedCategories.RealState) {
        const formattedValues = formatValuesRealState(classifiedData);
        updateClassified(formattedValues);
      }



    } else {
      if (eSubmit.categoryType === ClassifiedCategories.RealState) {
        const formattedValues = formatValuesRealState(classifiedData);
        createClassified(formattedValues);
      } else if (eSubmit.categoryType === ClassifiedCategories.Vehicles) {
        const formattedValues = formatValuesVehicles(classifiedData);
        createClassified(formattedValues);
      } else {
        createClassified(classifiedData);
      }
    }
  }

  return (
    <>
      {
        !picked && !isEditMode ? (
          <ThemeProvider theme={theme}>
            <>
              <TextPick>{t('select_a_category_for_your_classified')}</TextPick>
              {data.map((category: any, index: number) => {
                return (
                  <CategoryContainer
                    onClick={() => {
                      setPicked(category.name[language])
                      setSelectedCategory(category.category)
                    }}
                    key={index}
                  >
                    <ImageIconPopUp
                      src={category.image || ""}
                      loading="lazy"
                      alt={category.name[language]}
                      title={category.name[language]}
                    />
                    <TextContainer>
                      <Name>{category.name[language]}</Name>
                      <Description>{category.description[language]}</Description>
                    </TextContainer>
                    <GoTo>
                      <MdOutlineArrowForwardIos color={"#000000"} />
                    </GoTo>
                  </CategoryContainer>
                );
              })}
            </>
          </ThemeProvider>
        )
          : (
            <TypeFormSelector
              createClassifiedSuccess={successCreateClassified}
              formProperties={data}
              userUid={user.uid}
              category={selectedCategory || initialValues?.category || ''} // Use initialValues if in edit mode
              categoryName={picked || initialValues?.categoryName || ''}
              onSubmit={(eSubmit) => {
                handleSubmitCreateClassified(eSubmit as any as IRealStateClassified | IVehicleClassified | ITechClassified | IEmploymentClassified | ICommonClassified);
              }}
              language={language}
              initialValues={initialValues} // Pass initialValues
              isEditMode={isEditMode} // Pass isEditMode
            />
          )
      }
      <ModalLoader isLoading={isLoadingCreateError || isLoadingUpdateError} /> {/* Show loader for both create and update */}
    </>
  );
};

export default NewClassifieds;
