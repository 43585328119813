import { storage } from 'config/firebase';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject, listAll } from 'firebase/storage';

interface IOptions {
  maxSize?: string;
}

const uploadImagesToFirestorage = async (files: File[], path: string, options?: IOptions) => {
  let isLoading = true;
  let error: any = null;
  let downloadURLs: string[] = [];

  const uploadPromises = files.map(file => {
    return new Promise<string>(async (resolve, reject) => {
      const storageRef = ref(storage, `${path}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
        },
        (err: any) => {
          error = err;
          reject(err);
        },
        async () => {
          try {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(url);
          } catch (err) {
            error = err;
            reject(err);
          }
        }
      );
    });
  });

  try {
    downloadURLs = await Promise.all(uploadPromises);
  } catch (err) {
  } finally {
    isLoading = false;
  }

  return { downloadURLs, isLoading, error };
}

const deleteImageFromFirestorage = async (path: string) => {
  const storageRef = ref(storage, path);
  await deleteObject(storageRef);
}

const deleteMultipleImagesFromFirestorageInSinglePath= async (path: string) => {
  
  const listRef = ref(storage, path);

  // Find all the prefixes and items.
  listAll(listRef)
    .then((res) => {
      res.prefixes.forEach(() => {
      });
      res.items.forEach((itemRef: any) => {
        deleteObject(itemRef).then(() => {
        }).catch((error) => {
          console.error('error deletin image in firestore', error);
        }
        );
      });
    } )
    .catch((error) => {
      console.error('error deletin image in firestore', error);
    }
  );

  return { isLoading: false, error: null };


}

export { uploadImagesToFirestorage as uploadImagesToFirestore, deleteImageFromFirestorage, deleteMultipleImagesFromFirestorageInSinglePath};
