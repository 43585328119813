import {useEffect, useState} from 'react';
import {collection, getDocs, query, where} from 'firebase/firestore/lite';
import {firestore} from 'config/firebase';


interface UseImagesState {
    images: string[];
    loading: boolean;
    error: Error | null;
}


const useBanners = (collectionPath: string): UseImagesState => {
    const [images, setImages] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
 
    useEffect(() => {
        if (images.length === 0) {
            setLoading(true);
            setError(null);

            let baseQuery: any = collection(firestore, collectionPath);

            const querySnapshot = getDocs(baseQuery);

            querySnapshot
                .then((snapshot) => {
                    let listOfBanners: string[] = [];

                    snapshot.forEach((doc) => {
                        const imagesByResolution = () => {
                            if (window.innerWidth < 768) {
                                // @ts-ignore
                                return doc.data().imagesMobile
                            } else {
                                // @ts-ignore
                                return doc.data().imagesDesktop
                            }
                        }

                        listOfBanners = [...listOfBanners, ...imagesByResolution()];
                    });
                    setImages(listOfBanners);
                })

                .catch((error) => {
                    setError(error);
                    console.error("error hook get banners", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }

    }, []);

    return {images: images, loading, error};
};

export default useBanners;