import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import MainLayout from "views/layout/MainLayout";
import ModalLoader from "ui/ModalLoader";
import { H1 } from "ui/Text";
import {
  Container,
  ContainerBread,
  TopContainer,
  ContentContainer,
  ContainerExtras,
  Content,
  ContentExtras,
  ItemContainer,
  ImagesContainer,
  TitleWrapper,
  ContactButtonWrapper,
  CustomList,
  H3,
} from "./styled";
import DetailForm from "./detailForm";
import { useNotification } from "lib/context/AlertContext";
//import { Extras } from "modules/services/components/Extras/extras";
import { useGetFirestoreDoc } from "hooks/useGetDocumentFirestore";
//import { useGetFirestoreCollection } from "hooks/useGetCollectionFirestore/useGetCollectionFirestore";
//import { EXTRAS_PATH } from "modules/services/pages/tourism/dist/constants";
import { getCountry, getLanguage } from "redux/common";
import { useTranslation } from "react-i18next";
import parseHtmlToText from "utils/parseHtmlToText";
import { Button, BackButton } from "ui/Buttons";
import { LOGISTIC_PANAMA_PHONE_NUMBER } from "constants/constants";
import { THEME } from "constants/theme/theme";


const DetailEarnMoney = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showError } = useNotification();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id") || null;
  console.log("el is ed", id);
  const country = useSelector(getCountry);
  const language = useSelector(getLanguage);

  const {
    fetchDoc: fetchPost,
    docData: postData,
    loadingGetFirestoreDoc: loadingFetchPost,
  } = useGetFirestoreDoc();

  {
    /* const { collectionData: extraServicesData, fetchCollection: fetchExtraServiceData} = useGetFirestoreCollection(); */
  }

  useEffect(() => {
    {
      /*
        fetchExtraServiceData({
            path: EXTRAS_PATH,
          });
        */
    }

    if (id) {
      fetchPost({
        path: `${country}/Earnmoney/Posts`,
        uid: id,
      });
    } else {
      showError("No se encontró el post");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    console.log("data de money", postData);
  }, [postData]);

  if (!postData) {
    return <div>{t("loading_information")}</div>;
  }

  return (
    <MainLayout type="earn-money">
      <Container>
        <ContainerBread
          style={{
            background: THEME.colors.black200,
          }}
        ></ContainerBread>
        <Content>
          <Container>
          <BackButton onClick={() => navigate(-1)} text='none' />
            <TopContainer>

                <TitleWrapper>
                  <H1>{postData?.name?.[language] || "Error de base datos"}</H1>
                  </TitleWrapper>
                  
                  <ContactButtonWrapper>
                    <Button
                      onClick={() => {
                        const name = postData?.name?.[language] || "";
                        const message = `${t("earn_money_message")} ${name} - ${t("earn_money")}.`;
                        window.open(
                          `https://wa.me/${LOGISTIC_PANAMA_PHONE_NUMBER}?text=${encodeURIComponent(
                            message
                          )}`
                        );
                      }}
                    >
                      {t("contact")}
                  </Button>
                </ContactButtonWrapper>
                
            </TopContainer>

            <ContentContainer>
              <ImagesContainer>
                <img
                  src={postData?.images[0]}
                  loading="lazy"
                  alt={postData?.name?.[language] || "Error de base datos"}
                  title={postData?.name?.[language] || "Error de base datos"}
                />
              </ImagesContainer>

              <ItemContainer>
                <H3>{t("general_details")}:</H3>

                {parseHtmlToText(
                  postData?.generalDetails?.[language] || "Error de base datos"
                )}

                <H3>{t("includes")}:</H3>

                <CustomList>
                  {parseHtmlToText(
                    postData?.includes?.[language] || "Error de base datos"
                  )}
                </CustomList>

                <DetailForm />

              </ItemContainer>
            </ContentContainer>
            <BackButton onClick={() => navigate(-1)} text={`${t('back')}`} />
          </Container>
        </Content>
      </Container>
      {/* 
             <ContainerExtras>
                <ContentExtras>
                   <Extras data={extraServicesData} hasRedirectToDetail={true} />
                </ContentExtras>
            </ContainerExtras>
             */}

      <ModalLoader isLoading={loadingFetchPost} />
    </MainLayout>
  );
};

export default DetailEarnMoney;
