import React, { useEffect, useState } from 'react';

import MainLayout from 'views/layout/MainLayout';
import { Container, Content } from "../Classifieds/styled";
import { collection, getDocs } from "firebase/firestore/lite";
import { firestore } from "config/firebase";
import ContentOffers from "views/containers/Offers/ContentOffers";


const Offers = () => {
    const [offers, setOffers] = useState([])

    useEffect(() => {
        // setting classifieds
        if (offers.length === 0) {
            const querySnapshot = getDocs(collection(firestore, 'PANAMA/Offers/Posts'));
            querySnapshot.then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    // @ts-ignore
                    setOffers(items => [...items, { ...doc.data(), id: doc.id }]);
                });
            }
            );
        }

    }, []);
    return (
        <MainLayout type='offers'>
            <Container>
                <Content>
                    <ContentOffers data={offers} />
                </Content>
            </Container>
        </MainLayout>
    );
};

export default Offers;
