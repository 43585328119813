import { useState } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import {Container, Grid, GridItem, ContentChoiceConfig, SubItemGrid} from './styled'
import { BackButton } from 'ui/Buttons';
import LanguageContentConfigModal from './components/language-content-config-modal';
import CountryContentConfigModal from './components/country-content-config-modal';
import LanguageIcon from '@mui/icons-material/Language';
import FlagIcon from '@mui/icons-material/Flag';
import  { getLanguage, setLanguage } from 'redux/common';
import { DBLanguageReference } from 'types/common'
import { useNotification } from 'lib/context/AlertContext';
import { useTranslation } from 'react-i18next';

const ModalConfig = () => {
    const  { showSuccess} = useNotification();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const language = useSelector(getLanguage);

    const [optionConfigSelected, setOptionConfigSelected] = useState('');

    function handleSetLanguage(lang: DBLanguageReference) {
        dispatch(setLanguage(lang));
        setTimeout(() => {
            showSuccess(t('success_lang_changed'));
        }, 1500);
    }

    return (
        <Container>
            <Grid isOtherSelected={optionConfigSelected ? true : false }>
                <GridItem 
                    isSelected={optionConfigSelected === 'language'}
                    isOtherSelected={optionConfigSelected !== '' && optionConfigSelected !== 'language' ? true : false }
                    onClick={() => setOptionConfigSelected('language')}
                >
                    <SubItemGrid>
                        <LanguageIcon />
                        <span>{t('lang')}</span>
                    </SubItemGrid>
                </GridItem>
                <GridItem
                    isSelected={optionConfigSelected === 'country'}
                    isOtherSelected={optionConfigSelected !== '' && optionConfigSelected !== 'country' ? true : false }
                    onClick={() => setOptionConfigSelected('country')}
                >
                    <SubItemGrid>
                        <FlagIcon />
                        <span>{t('country')}</span>
                    </SubItemGrid>
                </GridItem>
            </Grid>

            <ContentChoiceConfig>
                {
                    optionConfigSelected === 'language' &&
                    <LanguageContentConfigModal
                        language={language}
                        setLanguage={(e) => {
                            handleSetLanguage(e);
                        }}
                        />
                }
                {
                    optionConfigSelected === 'country' &&
                    <CountryContentConfigModal />
                }
            </ContentChoiceConfig>
            {
                optionConfigSelected !== '' &&
                <BackButton onClick={() => setOptionConfigSelected('')} text={`${t('back')}`} style={{margin: '1rem 0'}} />
            }
        </Container>
    );

}

export default ModalConfig;