import { ChangeEvent,  FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Container, ContentForm, InputWrap, InputsContainer, SectionTitleWithBackground, Grid } from "../styled";
import ImagePickerV2 from "ui/ImagePickerV2"
import Select from "ui/Select";
import { Formik, Form, Field } from 'formik';
import Input from 'ui/TextInput';
import { P } from "ui/Text";
import GoogleMapInput from "ui/GoogleMapInput";
import { TextError } from "ui/Text";
import TextArea from "ui/TextArea";
import { Button } from "ui/Buttons";
import { useNotification } from "lib/context/AlertContext";
import useGetVehicleBrand from "hooks/common/useGetVehicleBrand";
import YesOrNot from "components/YesOrNot";
import { classifiedVehicleFormSchema } from '../main'
import { IVehicleClassified, IFormPropertyVehicleClassified } from "types";
import { MAX_LENGTH_DESCRIPTION, MAX_LENGTH_TITLE, MAX_IMAGES_12 } from "constants/constants";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { onlyNumbers } from "utils/common";
import YearPicker from "ui/YearPicker/year-picker";
import { languageCollectionTransform, transformItemNameToValueLabel } from "utils/language-transform-collection";
// Icons
import { EnumClassifiedPropertyIconUrls } from 'constants/classifieds';
import { getPropertyValues } from "utils/classifieds/classifieds";
interface Props {
    category?: string;
    userUid: string;
    onSubmit?: (values: IVehicleClassified) => void;
    createClassifiedSuccess: boolean;
    formProperties: IFormPropertyVehicleClassified;
    language: string;
    categoryType: string;
    initialValues?: any; // Add initial values for editing
}



const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_WDYN_GOOGLE_MAPS_API_KEY;


const NewVehiclesForm: FC<Props> = (props: Props) => {
    const { category, onSubmit, userUid, createClassifiedSuccess, formProperties, language, categoryType, initialValues } = props;
    const formRef: any = useRef(null);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [brands] = useGetVehicleBrand();
    const { showError } = useNotification();

    const [formErrors, setFormErrors] = useState<any>({});
    
    const initialPosition = Object.keys.length > 0 ? initialValues?.location?.value?.coordinates : { lat: 8.98251176638921, lng: -79.528036556417 };

    useEffect(() => {
        if (createClassifiedSuccess && formRef.current) {
            formRef.current.resetForm();
            setTimeout(() => {
                navigate('/classifieds');
            }, 2000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createClassifiedSuccess]);

    const buttonText = initialValues ? t('edit_classified') : t('create_classified');
    

    return (
        <Container>
            <h2 style={{ color: '#000' }}>{category || ''}</h2>
            
            <Formik
                innerRef={formRef}
                initialValues={{
                    title: initialValues?.title || '',
                    description: initialValues?.description || '',
                    category: initialValues?.category || category,
                    vehicleType: getPropertyValues(initialValues?.properties, 'Vehicle type') || '',
                    fuelType: getPropertyValues(initialValues?.properties, 'Fuel type') || '',
                    brand: getPropertyValues(initialValues?.properties, 'Brand') || '',
                    model: getPropertyValues(initialValues?.properties, 'Model') || '',
                    year: getPropertyValues(initialValues?.properties, 'Year') || '',
                    mileage: getPropertyValues(initialValues?.properties, 'Mileage') || '',
                    isNew: getPropertyValues(initialValues?.properties, 'New') || false,
                    numberOfDoors: '4',
                    numberOfSeats: '5',
                    transmissionType: getPropertyValues(initialValues?.properties, 'Transmission') || '',
                    hasCrashed: getPropertyValues(initialValues?.properties, 'Crashed') || false,
                    location: initialValues?.location || '',
                    price: initialValues?.price || '',
                    images: initialValues?.images || [],
                    userId: userUid,
                    categoryType: categoryType,
                }}
                validationSchema={classifiedVehicleFormSchema}
                onSubmit={(values: IVehicleClassified) => {
                    onSubmit && onSubmit(values);
                }}
            >
                {({ errors, touched, values, setFieldValue, handleSubmit, handleChange }) => (
                    <ContentForm>
                        <Form>
                            <div>
                                <ImagePickerV2
                                    maxImages={MAX_IMAGES_12}
                                    listImages={values?.images || [] as any}
                                    onChange={(files) => {
                                        setFieldValue('images', files);
                                    }}
                                />
                            </div>
                            <InputsContainer>
                                <SectionTitleWithBackground>{t('general_information')}</SectionTitleWithBackground>
                                <InputWrap>
                                    <Field as={Input} label={t('classified_title')} name="title" maxLength={MAX_LENGTH_TITLE} />
                                    <TextError>{touched.title && errors.title && errors.title}</TextError>
                                </InputWrap>
                                <InputWrap>
                                    <Field as={TextArea} label={t('description')} name="description" maxLength={MAX_LENGTH_DESCRIPTION} />
                                    <TextError>{touched.description && errors.description && errors.description}</TextError>
                                </InputWrap>
                                <SectionTitleWithBackground>{t('vehicle_information')}</SectionTitleWithBackground>
                                <Grid>
                                    <InputWrap>
                                    <Field
                                        as={Select}
                                        label={t('vehicle_type')}
                                        value={values.vehicleType}
                                        defaultValue={values.vehicleType}
                                        options={formProperties?.vehicleType ? languageCollectionTransform(formProperties.vehicleType, language) : []}
                                        onChange={(e: any) => { setFieldValue('vehicleType', e) }}
                                        hideNeverChoice
                                        />
                                        <TextError>
                                        {touched.vehicleType && errors.vehicleType && errors.vehicleType}
                                        </TextError>
                                    </InputWrap>
                                    <InputWrap>
                                    <Field
                                        as={Select}
                                        value={values.brand}
                                        onChange={(e: string | ChangeEvent<any>) => { handleChange('brand')(e); }}
                                        label={t('brand')}
                                        RightIcon={EnumClassifiedPropertyIconUrls.Brand}
                                        options={brands.length > 0 ? transformItemNameToValueLabel(brands) : []}
                                        hideNeverChoice
                                        />
                                        <TextError>
                                        {touched.brand && errors.brand && <span>{errors.brand}</span>}
                                        </TextError>
                                    </InputWrap>
                                    <InputWrap>
                                        <Field
                                            as={YearPicker}
                                            label={t('year')}
                                            RightIcon={EnumClassifiedPropertyIconUrls.Bathrooms}
                                            value={values.year}
                                            onChange={(e: string | ChangeEvent<any>) => {
                                                handleChange('year')(e);
                                            }} />
                                        <TextError>{touched.year && errors.year && errors.year}</TextError>
                                    </InputWrap>
                                    <InputWrap>
                                        <Field
                                            as={Input}
                                            label={t('model')}
                                            RightIcon={EnumClassifiedPropertyIconUrls.Model}
                                            value={values.model}
                                            onChange={(e: { target: { value: string | ChangeEvent<any>; }; }) => { handleChange('model')(e.target.value); }} />
                                        <TextError>{touched.model && errors.model && errors.model}</TextError>
                                    </InputWrap>
                                    <InputWrap>
                                        <Field
                                            as={Input}
                                            label={t('mileage')}
                                            maxLength={40}
                                            RightIcon={EnumClassifiedPropertyIconUrls.Mileage}
                                            value={values.mileage}
                                            onChange={(e: { target: { value: string | number | Event; }; }) => { handleChange('mileage')(onlyNumbers(e.target.value)); }}
                                        />
                                        <TextError>{touched.mileage && errors.mileage && errors.mileage}</TextError>
                                    </InputWrap>
                                    <InputWrap>
                                    <Field
                                        as={Select}
                                        label={t('transmission_type')}
                                        onChange={(e: any) => { setFieldValue('transmissionType', e); }}
                                        value={values.transmissionType}
                                        options={languageCollectionTransform(formProperties?.transmissionType, language) || []} // Asegurar que options sea un array
                                        hideNeverChoice
                                        />
                                    </InputWrap>
                                    <InputWrap>
                                    <Field
                                        as={Select}
                                        label={t('fuel_type')}
                                        value={values.fuelType}
                                        onChange={(e: any) => { setFieldValue('fuelType', e); }}
                                        errorMessage={touched.fuelType && errors.fuelType && <div>{errors.fuelType}</div>}
                                        options={languageCollectionTransform(formProperties?.fuelType, language) || []} // Asegurar que options sea un array
                                        hideNeverChoice
                                        />
                                        <TextError>
                                        {touched.fuelType && errors.fuelType && <div>{errors.fuelType}</div>}
                                        </TextError>
                                    </InputWrap>
                                </Grid>
                                <Grid columns={window.innerWidth < 768 ? 2 : 3}>
                                    <InputWrap>
                                        <YesOrNot
                                            label={t('is_new')}
                                            isYes={values.isNew}
                                            onChange={(e: any) => { setFieldValue('isNew', e); }}
                                        /> 
                                        <TextError>{touched.isNew && errors.isNew && errors.isNew}</TextError>
                                    </InputWrap>
                                    <InputWrap>
                                        <YesOrNot
                                            label={t('has_crashed')}
                                            isYes={values.hasCrashed}
                                            onChange={(e: any) => { setFieldValue('hasCrashed', e); }}
                                        />
                                        <TextError>{touched.hasCrashed && errors.hasCrashed && errors.hasCrashed}</TextError>
                                    </InputWrap>
                                </Grid>

                                <SectionTitleWithBackground>{t('additional_information')}</SectionTitleWithBackground>
                                <InputWrap>
                                    <P>{t('location')}</P>
                                    <GoogleMapInput
                                        initialPosition={initialPosition}
                                        apiKey={GOOGLE_MAPS_API_KEY || ''}
                                        onChange={(e) => {
                                            setFieldValue('location', e);
                                        }}
                                        fullLocation={values?.location as any}
                                    />
                                    <TextError>{touched.location && errors.location && <div>{errors.location}</div>}</TextError>
                                </InputWrap>
                                <SectionTitleWithBackground>{t('price')}</SectionTitleWithBackground>
                                <InputWrap>
                                    <Field as={Input} label={t('price')} name="price" maxLength={12} />
                                    <TextError>{touched.price && errors.price && <div>{errors.price}</div>}</TextError>
                                </InputWrap>
                            </InputsContainer>
                            <InputsContainer style={{ marginTop: '2rem' }}>
                            <Button
                                    type="submit"
                                    onClick={() => handleSubmit()}
                                    disabled={Object.keys(errors).length > 0}
                                    onDisabledClick={() => {
                                        showError(t(`${t('please_complete_all_required_fields')
                                            }: ${Object.values(errors).map((error: any) => {
                                                return error + ', '
                                            })}
                                            `))
                                        setFormErrors(errors)
                                        console.log('errors', errors)
                                    }

                                    }
                                    IconRight={<NewspaperIcon />}
                                >
                                    {buttonText}
                                </Button>
                            </InputsContainer>
                        </Form>
                    </ContentForm>
                )}
            </Formik>
        </Container>
    );
}

export default NewVehiclesForm;
