import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import './styles.css';


import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
interface ICountryPickerProps {
  value: { country: string; region?: string };
  onChange: (value: { country: string; region?: string }) => void;
  disabled?: boolean;
  onlyCountry?: boolean;
}

const CountryPicker: React.FC<ICountryPickerProps> = ({ value, onChange, disabled, onlyCountry }) => {
  const { t } = useTranslation();
  const [country, setCountry] = useState(value.country);
  const [region, setRegion] = useState(value.region);

  // useEffect(() => {
  //   setCountry(value.country);
    
  //   if (!onlyCountry && value.region) {
  //     setRegion(value.region);
  //   }
  // }, [value, onlyCountry]);

  const handleCountryChange = useCallback(
    (val: string) => {
      setCountry(val);
      setRegion(onlyCountry ? '' : '');
      onChange({ country: val, region: onlyCountry ? '' : '' });
    },
    [onChange, onlyCountry]
  );

  const handleRegionChange = useCallback(
    (val: string) => {
      if (!onlyCountry) {
        setRegion(val);
        onChange({ country, region: val });
      }
    },
    [country, onChange, onlyCountry]
  );

  const countryDropdown = useMemo(
    () => (
      <>
        <CountryDropdown
          value={country}
          defaultOptionLabel={`${t('select_a_country')}`}
          onChange={handleCountryChange}
          priorityOptions={['PA', 'US', 'CN', 'CO', 'RD', 'ES', 'AW',]}
          classes="input"
          disabled={disabled}
        />
      </>
    ),
    [country, handleCountryChange, disabled]
  );

  const regionDropdown = useMemo(() => {

    if (country === '' || onlyCountry) {
      return null;
    }

    return (
      <>
        {
          (
            <RegionDropdown
              country={country}
              value={region || ''}
              defaultOptionLabel={`${t('select_a_region')}`}
              onChange={handleRegionChange}
              blankOptionLabel="No seleccionado"
              classes="input"
              disabled={disabled}
            />
          )
        }
      </>
    );
  }, [country, region, handleRegionChange, disabled, onlyCountry]); // Agregar onlyCountry como dependencia

  return (
    <div className="container">
      <div
        className="input-picker"
        style={{
          background: `${disabled ? '#F3F3F3' : 'transparent'} `,
          border: `2px solid ${disabled ? 'rgba(145, 158, 171, 0.8)' : '#3a36db'}`,
        }}
      >{countryDropdown}</div>

      {!onlyCountry && value.country && (
        <div
          className="input-picker"
          style={{
            background: `${disabled ? '#F3F3F3' : 'transparent'} `,
            border: `2px solid ${disabled ? 'rgba(145, 158, 171, 0.8)' : '#3a36db'}`,
            marginTop: '.5rem'
          }}
        >{regionDropdown}</div>
      )}
    </div>
  );
};

export default CountryPicker;
