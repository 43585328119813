import { registerLog, registerEvent } from 'lib/analytics';

interface Analytics {
  screenName: string;
  pageTitle: string;
  pageLocation: string;
  pagePath: string;
}

interface AnalyticsEvent {
  pagePath: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
}

const useAnalytics = () => {
  const registerLogAnalytics = ({ screenName, pageTitle, pageLocation, pagePath }: Analytics) => {

    registerLog(screenName, {
      page_title: pageTitle,
      page_location: pageLocation,
      page_path: pagePath,
    });

  };

  return registerLogAnalytics;
};

export const useRegisterEvent = () => {
  const registerEventAnalytics = ({  pagePath, eventCategory, eventAction, eventLabel }: AnalyticsEvent) => {

    registerEvent(eventLabel,{
      page_path: pagePath,
      event_category: eventCategory,
      event_action: eventAction,
      event_label: eventLabel,
    });
  };

  return registerEventAnalytics;
}

export default useAnalytics;