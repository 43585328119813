import styled from 'styled-components'

export const ContentProductsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 2rem;
  margin: 2rem 0;

  @media only screen and (max-width: 992px){
    flex-direction: column;
    margin: 0;
  }


`;

export const CartTitle = styled.h2`
  vertical-align: middle;
  ${props => props.theme.typography.subheading.md.semibold};
`
export const SpanMiddle = styled.span`
  vertical-align: middle;
`
export const ContentItems = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  @media only screen and (max-width: 992px){
    width: 100%;
  }

  @media only screen and (max-width: 600px){
    padding: 0 1rem;
  }
  
`;

export const ContentDiscount = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border: 1px solid #CDD0D4;
  padding: 1rem;
  border-radius: 1rem;
  max-height: 16.5rem;
  @media only screen and (max-width: 992px){
    width: 40%;
  }

  @media only screen and (max-width: 992px){
    width: 100%;
  }

  @media only screen and (max-width: 600px){
    display: none;
  } 
`;

export const Cart = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  margin: 1rem auto;

  @media only screen and (max-width: 992px){
    width: 100%;
    padding: 0 1rem;
  }

  @media only screen and (max-width: 600px){
    padding: 0;
    margin: 0 0 -1rem 0;
  }
`;

export const CouponTitle = styled.div`
  ${props => props.theme.typography.body.lg.medium};
`
export const CouponInput = styled.input`
  padding: 0 1rem;
  border-radius: 10px;
  border: 1px solid #69727F;
  width: 65%;
  @media only screen and (max-width: 1200px){
    width: 100%;
    padding: 1rem;
  }
`

export const TransButton = styled.button`
  display: block;
  margin: 0 auto;
  width: ${props => props.size || "auto"};
  background: ${props => props.background || "#ffffff"};
  color: ${props => props.background === '#3A36DB' ? "#F5F5F6" : "#3A36DB"};
  border-radius: 50px;
  padding: .4rem 1rem;
  border: 1px solid #406385;
  ${props => props.theme.typography.body.md.bold};
  @media only screen and (max-width: 1200px){
   margin: 0;
  }
`


export const TransButtonS = styled.button`
  display: block;
  margin: 0 auto;
  align-self: center;
  width: ${props => props.size || "auto"};
  background: ${props => props.background || "#ffffff"};
  color: ${props => props.background === '#3A36DB' ? "#F5F5F6" : "#3A36DB"};
  border-radius: 50px;
  padding: 1rem;
  border: 1px solid #406385;
  ${props => props.theme.typography.body.md.bold};
  @media only screen and (max-width: 1200px){
   margin: 0;
  }
`
export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  @media only screen and (max-width: 1200px){
    flex-direction: column;
    justify-content: flex-start;
    gap: .5rem;
  }
  @media only screen and (max-width: 600px){
    align-items: flex-start;
  }
`

export const SpaceBetweenS = styled.div`
  display: flex;
  justify-content: space-between;
  max-height: 100px;
`

export const CuponBig = styled.div`
  @media only screen and (max-width: 600px){
    display: none;
  }
`
export const CuponSmall = styled.div`
  display: none;
  @media only screen and (max-width: 600px){
    width: 100%;
    display: flex;
    align-self: flex-end;
    bottom: 0;
    flex-direction: column;
    background: #3A36DB;
    padding: 1rem;
  }
`
export const HideSmall = styled.div`
  /* width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -2rem; */
  display: block;

  @media only screen and (max-width: 992px){
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media only screen and (max-width: 600px){
    display: none;
  }
`

export const SmallBack = styled.div`
  background: #FDFDFE;
  padding: 2rem;
  display: flex;
  border-radius: 1rem;
  flex-direction: column;
  justify-content: center;
`
export const TotalText = styled.span`
  color:#406385;
  ${props => props.theme.typography.subheading.xs.semibold};
`
export const Total = styled.span`
  ${props => props.theme.typography.subheading.sm.bold};
`
