import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container} from './styled'
import { APIProvider, Map, Marker} from '@vis.gl/react-google-maps';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import useGeocoding from 'hooks/maps/useGeocoding';
import useReverseGeocoding from 'hooks/maps/useReverseGeocoding';
import useCurrentLocation from 'hooks/maps/useGetCurrentLocation';
import { getLanguage } from 'redux/common';


interface FullLocation {
    label: string;
    value: {
        description: string;
        place_id: string;
        coordinates: { lat: number, lng: number };
    }
}
interface InputGoogleMapProps {
    apiKey: string;
    initialPosition: { lat: number, lng: number };
    fullLocation?: FullLocation;
    zoom?: number;
    onChange?: (e: any) => void;
}

const InputGoogleMap: React.FC<InputGoogleMapProps> = ({ apiKey, initialPosition, fullLocation, zoom = 13, onChange }) => {
    const { t } = useTranslation();
    const geoCoder = useGeocoding({apiKey});
    const { getReverseGeocoding } = useReverseGeocoding({apiKey});
    const language = useSelector(getLanguage);
    const { location: currentLocation } = useCurrentLocation();
    const locationFormatted = Object.keys(fullLocation || {}).length ? fullLocation : null;
    
    const [position, setPosition] = useState(fullLocation?.value?.coordinates || initialPosition);
    const [, setPlaceId] = useState<string>();
    const [place, setPlace] = useState<string>();
    const [valuePlace, setValuePlace] = useState<FullLocation | null>(locationFormatted || null);

    const { getGeocoding } = geoCoder;

    useEffect(() => {
        if (place) {
            (async () => {
                const newPosition = await getGeocoding({
                    place: place 
                });
                setPosition(newPosition?.results[0]?.geometry?.location);
            } )();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [place]);

    useEffect(() => {
        if ((currentLocation.lat && currentLocation.lng) && !fullLocation?.value?.coordinates) {
            setPosition(currentLocation as any);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLocation]);

    const onMarkerDragEnd = async (e: any) => {
        const newPos = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
        };
        setPosition(newPos);
        
        const newLocation = await getReverseGeocoding({
            lat: newPos.lat,
            lng: newPos.lng
        });

        const placeFormatted = {
            label: newLocation?.results[0]?.formatted_address,
            value: {
                description: newLocation?.results[0]?.formatted_address,
                place_id: newLocation?.results[0]?.place_id || '',
                coordinates: newPos
            }
        }

        setValuePlace(placeFormatted);
    };

    useEffect(() => {


        onChange && onChange(valuePlace);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [valuePlace]);


    return (
        <Container>
            <GooglePlacesAutocomplete
                apiKey={apiKey}
                apiOptions={{ language }}
                selectProps={{
                    placeholder: `${t('write_here_location')}...`,
                    [valuePlace ? 'value' : 'defaultValue']: valuePlace || null,
                    onChange: async (e: any) => {
                        setPlaceId(e.value?.place_id);
                        setPlace(e.value?.description);

                        const newCoordinates = await getGeocoding({
                            place: e.value.description
                        });

                        const placeFormatted = {
                            label: e.value.description,
                            value: {
                                description: e.value?.description,
                                place_id: e.value?.place_id,
                                coordinates: newCoordinates?.results[0]?.geometry?.location
                            }
                        }
                        
                        setValuePlace(placeFormatted);
                        
                    }
                }
                }
            />
            <div style={{height:'80%'}}>
                <APIProvider apiKey={apiKey}>
                    <Map 
                        center={position} 
                        zoom={zoom} 
                        initialViewState={{ latitude: position?.lat, longitude: position?.lng, zoom: zoom }}
                        >
                        <Marker 
                            position={position}
                            draggable={true}
                            onDragEnd={onMarkerDragEnd}
                        />
                    </Map>
                </APIProvider>
            </div>
        </Container>
    );
};

export default InputGoogleMap;