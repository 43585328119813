import { useEffect, useState, useCallback, useRef } from 'react';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Info, ShipmentContainer, ShipmentCheckContainer, InputWrap, CreateAddressFormContainer, DirTitle, ButtonsNavsContainer, Error, AddressesContainer, ContainerActionsForm, SwitchContainer } from "../styled";
import Input from 'ui/TextInput';
import PhoneInput from 'ui/PhoneInput';
import TextArea from 'ui/TextArea';
import ModalLoader from 'ui/ModalLoader';
import useGetUserAddressesListener from 'hooks/user/useGetUserAddress/useGetUserAddress';
import { StrokedButton, Button, CancelButton, BackButton } from 'ui/Buttons';
import CountryPicker from "ui/CountryPicker/country-region-picker";
import CardAddress from 'components/Address/card-address';
// import Switch from 'ui/Switch/switch';
import ModalNewAddress from 'components/modals/modal-form-new-address';
import ModalConfirm from 'components/modals/modal-confirm';
import CardSelectTypeAddress from 'components/Address/card-select-type-address';
import { useNotification } from 'lib/context/AlertContext';
import { User, Address } from "types/common";
import useDeleteAddress from 'hooks/user/useDeleteSingleUserAddress/use-delete-single-user-address';
import { setAddress, getOfflineAddress, setOfflineAddress } from 'redux/checkout';
import { createNewAddress, updateAddress } from 'services/user/updateInfo/service-update-user-info';
import { addressSchema } from 'constants/validators';
import { capitalize } from 'utils/common';
import { getIpInfoSelector, offlineUserInfoSelector } from "redux/user";

import { STEPS_CHECKOUT } from '../constants';

interface StepProps {
    onSubmit: (step: number) => void;
    onBack: () => void;
    setData: (data: any) => void;
    user: User[];
    address: Address[];
}

const StepTwo = (props: StepProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { deleteAddressById } = useDeleteAddress();
    const { onSubmit, setData, user, onBack } = props;
    const { showSuccess, showError } = useNotification();
    const ipInfo = useSelector(getIpInfoSelector);
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const countryParam = capitalize(urlParams.get('country') || '');
    const countryIp = ipInfo.location.country.name;
    const regionIp = ipInfo.location.region.name;
    const cityIp = ipInfo.location.city;
    const postalCodeIp = ipInfo.location.postal;

    const userUid: string = user?.length ? user[0].uid : '';

    const listAddresses: Address[] = useGetUserAddressesListener(userUid);
    const offlineAddress = useSelector(getOfflineAddress);
    const offlineUserInfo = useSelector(offlineUserInfoSelector);

    const [addressSelected, setAddressSelected] = useState<Address>();
    const [editingAddress, setEditingAddress] = useState<boolean>(false);
    const [showModalNewAddress, setShowModalNewAddress] = useState<boolean>(false);
    const [showModalConfirmDeleteAddress, setShowModalConfirmDeleteAddress] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const formikRef: any = useRef(null);

    const handleCreateAddress = async (values: any): Promise<any> => {

        if (userUid) {
            if (!listAddresses.length) {
                values.type = ['shipment', 'billing'];
            }

            if (!values.type && listAddresses.length) {
                values.type = ['shipment', 'billing'];
            }

            createNewAddress(user[0].uid, values).then(res => {
                showSuccess(t('Address created successfully'));
            }
            ).catch(err => {
                console.error('error creating the address', err);
                showError(t('error_creating_address'));
            })
        } else {

            const addressData = {
                createdAt: new Date().toISOString(),
                city: values.city,
                phone: values.phone,
                street: values.street,
                state: values.state,
                additionalComments: values.additionalComments,
                country: values.country,
                fullName: values.fullName,
                postalCode: values.postalCode,
                type: ['shipment', 'billing'],
                isPrimary: values.isPrimary
            };

            dispatch(setOfflineAddress(addressData as any));
            onSubmit(STEPS_CHECKOUT.PAYMENT_METHOD);
        }
    }


    function handleConfirmDeleteAddress() {
        setShowModalConfirmDeleteAddress(true);
    }

    const handleDeleteUserAddressById = async (userId: string, addressId: string) => {
        const result = await deleteAddressById(userId, addressId);

        if (result.success) {
            showSuccess(t('address_deleted_successfully'))
            // luego necesitaría ver si entre todas las direcciones que quedan hay alguna que sea main, si no hay ninguna, entonces la primera de la lista la convierto en main
        } else {
            console.error('Error deleting address:', result.error);
        }
    };

    const handleUpdateAddress = async (values: any): Promise<any> => {

        updateAddress(user[0].uid, values).then(res => {
            showSuccess(t('address_updated_successfully'));
        }
        ).catch(err => {
            console.error('error updating the address', err);
            showError(t('error_updating_address'));
        }).finally(() => {
            setEditingAddress(false);
        })
    }

    const handleNextStep = useCallback(() => {
        if (addressSelected) {
            setData(prev => ({
                ...prev, addressInfo: { ...addressSelected }
            }));

            const infoAddressSelected = listAddresses.find((item: Address) => item.id === addressSelected.id);

            if (infoAddressSelected) {
                dispatch(setAddress(infoAddressSelected));
            }

            onSubmit(STEPS_CHECKOUT.PAYMENT_METHOD);
        } else {
            showError(t('must_select_an_address'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressSelected])

    useEffect(() => {
        if (listAddresses.length > 0) {
            // const mainAddress = listAddresses.find((item: Address) => item.type.includes('main')) || listAddresses[0] || {};
            // setAddressSelected(mainAddress);
            dispatch(setAddress(listAddresses[0]));

        
        } else {
            setAddressSelected({
                id: '',
                country: '',
                city: '',
                state: '',
                street: '',
                fullName: '',
                phone: '',
                postalCode: '',
                additionalComments: '',
                type: ['shipment', 'billing'],
                createdAt: new Date().toISOString(),
                isPrimary: false
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listAddresses])

    useEffect(() => {
        if (formikRef.current) {
            formikRef.current.setValues({
                id: addressSelected?.id || '',
                currentAddress: '',
                type: addressSelected?.type || offlineAddress?.type || ['shipment', 'billing'],
                country: addressSelected?.country || offlineAddress?.country || countryIp || countryParam || '',
                city: addressSelected?.city || offlineAddress?.city || cityIp || '',
                state: addressSelected?.state || offlineAddress?.state || regionIp || '',
                fullName: addressSelected?.fullName || offlineAddress?.fullName || `${offlineUserInfo?.firstName || ''} ${offlineUserInfo?.firstSurname || ''}` || '',
                phone: addressSelected?.phone || offlineAddress?.phone || offlineUserInfo?.phone || '',
                street: addressSelected?.street || offlineAddress?.street || '',
                postalCode: addressSelected?.postalCode || offlineAddress?.postalCode || postalCodeIp || '',
                additionalComments: addressSelected?.additionalComments || offlineAddress?.additionalComments || '',
                isPrimary: addressSelected?.isPrimary || false
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressSelected]);


    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={{
                    id: addressSelected?.id || '',
                    currentAddress: addressSelected?.id || '',
                    type: addressSelected?.type || [],
                    country: addressSelected?.country || countryIp || countryParam || '',
                    city: addressSelected?.city || cityIp ||  '',
                    state: addressSelected?.state || regionIp || '',
                    street: addressSelected?.street ||  '',
                    fullName: addressSelected?.fullName || `${offlineUserInfo?.firstName || ''} ${offlineUserInfo?.firstSurname || ''}` || '',
                    phone: addressSelected?.phone || offlineUserInfo?.phone || '',
                    postalCode: addressSelected?.postalCode || postalCodeIp || '',
                    additionalComments: addressSelected?.additionalComments || '',
                    isPrimary: addressSelected?.isPrimary || false
                }}
                validationSchema={addressSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    setLoading(true);
                    setSubmitting(true);
                    const isSuccesAddressCreated = await handleCreateAddress(values)
                    if (isSuccesAddressCreated) {
                        setSubmitting(false);
                    }
                    setLoading(false);
                }}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                }) => (
                    <>
                        <ShipmentContainer >
                            {
                                listAddresses.length > 0 ? (
                                    <CreateAddressFormContainer>
                                        <DirTitle>{t('choose_a_shipping_address')}</DirTitle>
                                        <AddressesContainer>
                                            <CardAddress
                                                isNewAddress
                                                onClickNewAddress={() => setShowModalNewAddress(true)}
                                            />
                                            {
                                                listAddresses.length > 0 && listAddresses.map((item: Address) => (
                                                    <CardAddress
                                                        key={item.id}
                                                        isMainAddress={item.isPrimary}
                                                        isSelected={addressSelected?.id === item.id}
                                                        fullName={item.fullName || ''}
                                                        street={item.street || ''}
                                                        phone={item.phone || ''}
                                                        country={item.country || ''}
                                                        city={item.city || ''}
                                                        onClick={() => {
                                                            setAddressSelected(item);
                                                            setFieldValue('currentAddress', item.id)
                                                        }}
                                                        onEdit={() => {
                                                            setEditingAddress(true);
                                                            setAddressSelected(item);
                                                        }}
                                                        onDelete={() => {
                                                            handleConfirmDeleteAddress();
                                                            setAddressSelected(item);
                                                        }}
                                                    />
                                                ))
                                            }
                                        </AddressesContainer>
                                        <Info>{t('selecting_from_list_will_override_current_addres')}</Info>
                                    </CreateAddressFormContainer>
                                ) : (
                                    null
                                )
                            }
                            <CreateAddressFormContainer isListAdressEmpty={listAddresses.length <= 0}>
                                <DirTitle >
                                    {
                                        listAddresses.length <= 0 ? `${t('create_a_new_address')}` : editingAddress ? `${t('label_editing_address')}` : addressSelected ? `${t('address_information')}` : `${t('write_a_new_one')}`
                                    }
                                </DirTitle>

                                <InputWrap style={{ marginBottom: '-14px', }}>
                                    <CountryPicker
                                        disabled={!editingAddress && addressSelected && listAddresses.length ? true : false}
                                        value={{ country: values.country, region: values.state }}
                                        onChange={(data) => {
                                            setFieldValue("country", data.country)
                                            setFieldValue("state", data.region)
                                        }} />
                                    {
                                        errors.country && (
                                            <Error>{errors.country}</Error>
                                        )
                                    }
                                </InputWrap>

                                <InputWrap style={{ marginTop: '.5rem' }} >
                                    <Input
                                        label={t('city') || ''}
                                        name="city"
                                        onChange={handleChange}
                                        value={values.city}
                                        type="text"
                                        disabled={!editingAddress && addressSelected && listAddresses.length ? true : false}
                                    />
                                    {
                                        errors.city && (
                                            <Error>{errors.city}</Error>
                                        )
                                    }
                                </InputWrap>

                                <InputWrap >
                                    <Input
                                        label={t('address') || ''}
                                        name="street"
                                        onChange={handleChange}
                                        value={values.street}
                                        type="text" disabled={!editingAddress && addressSelected && listAddresses.length ? true : false}
                                    />
                                    {
                                        errors.street && (
                                            <Error>{errors.street}</Error>
                                        )
                                    }
                                </InputWrap>

                                <InputWrap>
                                    <Input
                                        label={`${t('full_name')} (${t('name_and_last_name')})*`}
                                        name="fullName" onChange={handleChange}
                                        value={values.fullName}
                                        type="text"
                                        disabled={!editingAddress && addressSelected && listAddresses.length ? true : false}
                                    />
                                    {
                                        errors.fullName && (
                                            <Error>{errors.fullName}</Error>
                                        )
                                    }
                                </InputWrap>

                                <InputWrap style={{ zIndex: 10 }}>
                                    <PhoneInput
                                        placeholder={t('phone') || ''}
                                        value={values.phone}
                                        onChange={(e) => {
                                            setFieldValue('phone', e)
                                        }}
                                        maxLength={20}
                                        disabled={!editingAddress && addressSelected && listAddresses.length ? true : false}
                                    />
                                    {
                                        errors.phone && (
                                            <Error>{errors.phone}</Error>
                                        )
                                    }
                                </InputWrap>

                                <InputWrap>
                                    <Input
                                        label={t('zip_code') || ''}
                                        name="postalCode"
                                        maxLength={6}
                                        onChange={(e) => {
                                            handleChange('postalCode')(e)
                                        }}
                                        value={values.postalCode}
                                        type="text" disabled={!editingAddress && addressSelected && listAddresses.length ? true : false}
                                    />
                                    {
                                        errors.postalCode && (
                                            <Error>{errors.postalCode}</Error>
                                        )
                                    }
                                </InputWrap>

                                <InputWrap>
                                    <CardSelectTypeAddress
                                        value={values.type}
                                        onChange={(e) => {
                                            setFieldValue('type', e)
                                        }}
                                        disabled={ Object.keys(urlParams).length === 0 ? true : (!editingAddress && addressSelected && listAddresses.length) ?  true : false}
                                    />
                                    {
                                        errors.type && (
                                            <Error>{errors.type}</Error>
                                        )
                                    }
                                </InputWrap>
                                
                                <InputWrap>
                                    <TextArea label={t('additional_comments')} name="additionalComments" onChange={handleChange} value={values.additionalComments} disabled={!editingAddress && addressSelected && listAddresses.length ? true : false} />
                                    {
                                        errors.additionalComments && (
                                            <Error>{errors.additionalComments}</Error>
                                        )
                                    }
                                </InputWrap>

                                {/* {
                                    Object.keys(urlParams).length > 0 && (
                                        <SwitchContainer>
                                            {
                                                !addressSelected?.type.includes('main') && (
                                                    <div style={{ width: 'fit-content' }}>
                                                        <Switch
                                                            label={t('make_primary_address') || ''}
                                                            onChange={(e) => {
                                                                if (e) {
                                                                    setFieldValue('type', ['main'])
                                                                } else {
                                                                    setFieldValue('type', () => {
                                                                        return values.type.filter((item: string) => item !== 'main');
                                                                    })
                                                                }
                                                            }}
                                                            value={values.type.includes('main')}
                                                            disabled={!editingAddress && addressSelected && listAddresses.length ? true : false}
                                                            style={{ width: '100%' }}
                                                        />
                                                    </div>
                                                )
                                            }
                                        </SwitchContainer>
                                    )
                                } */}

                                {
                                    (!addressSelected || !listAddresses.length) && (
                                        <StrokedButton onClick={handleSubmit} disabled={Object.keys(errors).length > 0 || isSubmitting} margin='20px 0px' type='submit'>{t('save_address')}</StrokedButton>
                                    )
                                }

                                {listAddresses.length > 0 && addressSelected && (
                                    <ContainerActionsForm isEditing={editingAddress}>
                                        {
                                            editingAddress && (
                                                <CancelButton onClick={() => setEditingAddress(false)} type='button'>{t('cancel')}</CancelButton>
                                            )
                                        }
                                        {
                                            !editingAddress ? (
                                                <StrokedButton onClick={() => setEditingAddress(true)} margin='20px 0px' type='submit'>{t('edit_address')}</StrokedButton>
                                            )
                                                :
                                                (
                                                    <StrokedButton margin='20px 0px' onClick={() => handleUpdateAddress(values)}>{t('update_address')}</StrokedButton>
                                                )
                                        }
                                    </ContainerActionsForm>
                                )}
                            </CreateAddressFormContainer>
                        </ShipmentContainer>
                        <ShipmentCheckContainer>
                            <ButtonsNavsContainer width='100%'>
                                <BackButton onClick={onBack} text={t('back') || ''} />
                                {
                                    !editingAddress && listAddresses.length > 0 && (
                                        <Button
                                            disabled={(Object.keys(errors).length > 0 || !addressSelected || !listAddresses.length)}
                                            onClick={handleNextStep}
                                            onDisabledClick={() => {
                                                showError(t('must_select_an_address'));
                                            }}
                                        >{t('continue')}</Button>
                                    )
                                }
                            </ButtonsNavsContainer>
                        </ShipmentCheckContainer>
                    </>
                )}
            </Formik>
            <ModalNewAddress
                isOpen={showModalNewAddress}
                onClose={() => setShowModalNewAddress(false)}
                onSubmit={handleCreateAddress}
            />
            {
                showModalConfirmDeleteAddress && (
                    <ModalConfirm
                        isOpen={showModalConfirmDeleteAddress}
                        onClose={() => setShowModalConfirmDeleteAddress(false)}
                        onSubmit={() => {
                            handleDeleteUserAddressById(user[0].uid, addressSelected?.id || '');
                            setShowModalConfirmDeleteAddress(false);
                        }}
                        title={t('delete_address')}
                        description={t('are_you_sure_delete_address')}
                        titleButton={t('delete')}
                    />
                )
            }
            <ModalLoader isLoading={loading} />
        </>
    );
}

export default StepTwo;