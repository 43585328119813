import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { InputWrap, Error, Container, Label } from './styled';
import Input from 'ui/TextInput';
import PhoneInput from 'ui/PhoneInput';
import TextArea from 'ui/TextArea';
import { Button } from 'ui/Buttons';
import Select from 'ui/Select';
import CountryPicker from 'ui/CountryPicker';
import { H2 } from 'ui/Text';
import { validationSchema } from './main';
import { LogisticInquiryType } from 'types';
import useLogistictInquiry from 'hooks/modules/services/logistic/useCreateLogisticInquiry/use-create-logistic-inquiry';
import ModalLoader from 'ui/ModalLoader';
import { useNotification } from 'lib/context/AlertContext';
import { useTranslation } from "react-i18next";


const TYPE_SERVICE = [
    { label: 'Compra directa en fábrica', value: 'Compra directa en fábrica' },
    { label: 'Envío internacional', value: 'Envío internacional' },
    { label: 'Verificaciones', value: 'Verificaciones' },
    { label: 'Pagos internacionales', value: 'Pagos internacionales' },
    { label: 'Inspección de calidad', value: 'Inspección de calidad' },
];

interface Props {

}

function isCountryDestination(typeService: string): boolean {

    if (typeService === 'Envío internacional' || typeService === 'Compra directa en fábrica' || typeService === 'Pagos internacionales') {
        return true;
    } else {
        return false;
    }
};


const FormInquiryLogistic: React.FC<Props> = (props: Props) => {
    const { showSuccess, showError, showWarning } = useNotification();
    const { sendInquiry, loading, error } = useLogistictInquiry();
    const { t } = useTranslation();

    async function handleCreateLogisticInquiry(values: LogisticInquiryType): Promise<boolean> {
        const response = await sendInquiry(values as any);
        let success = false;

        if (response) {
            showSuccess('Su consulta ha sido enviada con éxito');
            success = true;
        } else {
            console.log('err', error);
            showError('Ha ocurrido un error, por favor intente de nuevo');
        }

        return success;
    }


    return (
        <Container>
            <H2>{t('express_logistics_consultation')}</H2>
            <Formik
                initialValues={{
                    typeService: '',
                    countryOrigin: '',
                    countryDestination: '',
                    additionalComments: '',
                    createdAt: new Date().toISOString(),
                    status: 'pending',
                    phone: '',
                    fullName: '',
                }}
                validationSchema={validationSchema}
                onSubmit={async (values: LogisticInquiryType, { setSubmitting, resetForm }) => {
                    try {
                        const response = await handleCreateLogisticInquiry(values);
                        if (response) {
                            resetForm();
                        }
                    } catch (error) {
                        console.log('err', error);
                        alert('Ha ocurrido un error, por favor intente de nuevo');
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ isSubmitting, errors, setFieldValue, handleSubmit, values }) => (
                    <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>

                        <InputWrap>
                            <Field as={Select} label={t('type_of_service')} name="typeService" options={TYPE_SERVICE} hideNeverChoice onChange={(e: any) => setFieldValue('typeService', e)
                            } />
                            <ErrorMessage name="typeService" component={Error} />
                        </InputWrap>

                        <InputWrap>
                            <Label>{`${t('country')} ${isCountryDestination(values.typeService) ? t('of_origin') : ''}`}</Label>
                            <Field as={CountryPicker} label={`${t('country')} ${isCountryDestination(values.typeService) ? t('of_origin') : ''}`} name="countryOrigin" onlyCountry={true} onChange={(e: any) => {
                               console.log('eli country', e);
                               setFieldValue('countryOrigin', e.country);
                            }} />
                            <ErrorMessage name="countryOrigin" component={Error} />
                        </InputWrap>

                        {
                            isCountryDestination(values.typeService) && (
                                <InputWrap>
                                    <Label>{t('destination_country')}</Label>
                                    <Field as={CountryPicker} label={t('destination_country')} name="countryDestination" onlyCountry={true} onChange={(e: any) => setFieldValue('countryDestination', e.country)}  />
                                    <ErrorMessage name="countryDestination" component={Error} />
                                </InputWrap>
                            )
                        }

                        <InputWrap>
                            <Field as={Input} label={`${t('full_name')} (${t('name_and_last_name')})`} name="fullName" type="text" />
                            <ErrorMessage name="fullName" component={Error} />
                        </InputWrap>

                        <InputWrap style={{ zIndex: 10 }}>
                            <Field as={PhoneInput} placeholder={t('phone')} name="phone" maxLength={20}
                                onChange={(e: any) => setFieldValue('phone', e)}
                            />
                            <ErrorMessage name="phone" component={Error} />
                        </InputWrap>

                        <InputWrap>
                            <Field as={TextArea} label={t('additional_comments')} name="additionalComments" />
                            <ErrorMessage name="additionalComments" component={Error} />
                        </InputWrap>

                        <Button style={{ marginTop: '1rem' }} type="submit" onClick={handleSubmit} onDisabledClick={() => {
                            showWarning('Debes agregar todos los datos necesarios para la consulta.')
                            console.log('errors', errors);
                        }} disabled={isSubmitting || Object.keys(errors).length > 0}>
                            {t('consult')}
                        </Button>
                    </Form>
                )}
            </Formik>
            <ModalLoader isLoading={loading} />
        </Container>
    );
};

export default FormInquiryLogistic;
