import React, {FC} from 'react'
import {Container, ContainerBar, Footer, FooterDesc, FooterIcon, FooterItem, FooterTitle} from './styled'
import GoogleMaps from "simple-react-google-maps"
// CONTAINERS
import MainLayout from 'views/layout/MainLayout'
import {ThemeProvider} from "@mui/material";
import {theme} from "lib/theme";
import {MdMap, MdOutlineEmail, MdOutlinePhone} from 'react-icons/md';
import { useTranslation } from "react-i18next";


const Contact: FC = () => {
    const { t } = useTranslation();

    return (

        <MainLayout>
            <Container id="home-container">
                <ContainerBar id="home-wrap">
                    <ThemeProvider theme={theme}>

                        <GoogleMaps
                            apiKey={"AIzaSyCg8H3567SXAr4kBnTElZNbkfaL_iSZz7o"}
                            style={{height: "100%", width: "100%"}}
                            zoom={10}
                            center={{lat: 9.150719251765754, lng: -79.33686345010405}}
                            markers={{lat: 9.150719251765754, lng: -79.33686345010405}}
                        />
                        <Footer>
                            <FooterTitle>{t('contact_us')}</FooterTitle>
                            <FooterItem>
                                <FooterIcon>
                                    <MdOutlineEmail size={20}/>
                                </FooterIcon>
                                <FooterDesc>
                                    admin@whatdoyouneed7.com
                                </FooterDesc>
                            </FooterItem>
                            <FooterItem>
                                <FooterIcon>
                                    <MdOutlinePhone size={20}/>
                                </FooterIcon>
                                <FooterDesc>
                                    +507 6405-5638
                                </FooterDesc>
                            </FooterItem>
                            <FooterItem>
                                <FooterIcon>
                                    <MdMap size={20}/>
                                </FooterIcon>
                                <FooterDesc>
                                    Ricardo J. Alfaro, Centro comercial siglo XXI, local 9, Ciudad de Panamá, Panamá
                                </FooterDesc>
                            </FooterItem>
                        </Footer>
                    </ThemeProvider>
                </ContainerBar>
            </Container>
        </MainLayout>
    )
}

export default Contact
