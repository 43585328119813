import styled from 'styled-components';
import { THEME } from 'constants/theme/theme';

interface ContainerProps {
    isSelected: boolean;
    isNewAddress?: boolean;
}

export const Container = styled.div<ContainerProps>`
    border: 1px solid ${props => props.isSelected ? THEME.colors.gray60 : THEME.colors.gray40};
    border-radius: 8px;
    padding: 8px 12px;
    // shadow
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.05);

    cursor: ${props => props.isNewAddress ? 'pointer' : 'default'};


    &:hover {
        transform: ${props => props.isNewAddress ? 'scale(1.03)' : 'scale(1)'};
        transition: all 0.2s ease-in-out;
    }

    @media only screen and (max-width: 768px) {
        width: 46vw;
    }

    
`;

export const Label = styled.span`
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${THEME.colors.black80};
    margin-bottom: 8px;
`;

// Create a new card 

export const NewCardContainer = styled.div`
    min-height: 240px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    text-align: center;
`;


export const ActionContainer = styled.div`
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
    align-self: flex-end;

    @media only screen and (max-width: 380px) {
        margin-top: 1rem;
        justify-content: space-between;
    }
`;


export const ActionButton = styled.div`
    display: flex;
    align-items: flex-end;
    border-radius: 4px;
    padding: 0px 6px;
    height: 36px;
    cursor: pointer; 


    &:hover {
        transform: scale(1.03);
        transition: all 0.2s ease-in-out;
    }

    @media only screen and (max-width: 380px) {
        width: 100%;
        margin: 0px;
        transform: scale(0.8);
        justify-content: space-between;
    }
`;

interface ActionTextProps {
    isDelete?: boolean;
}

export const ActionText = styled.span<ActionTextProps>`
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${props => props.isDelete ? THEME.colors.alert100 : THEME.colors.black80};
    cursor: pointer;
`;

export const BadgeContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const Badge = styled.div`
    background-color: ${THEME.colors.primary};
    color: ${THEME.colors.white};
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    padding: 4px 12px;
`;
