import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {  Item, ItemDesc, ItemImg, ItemName, ItemPrice, ItemQty, Summary, SubtitleSpan, SummarySubTitle, SummaryTitle, Resume, SpaceBetween, SumFinalText, SummaryFinalPrice, SummarySubtotal, SummaryText, SummaryTotal, FinalFooter, FooterItem, FooterItemDesc,FooterItemSpan, FooterItemTitle } from "./styled";
import Dialog from "@mui/material/Dialog";
import { Button } from "ui/Buttons";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { MdArrowBack } from "react-icons/md";
import { PaymentMethods, StatusPayment, Product as ProductType } from "types";
import useGetSingleUserAddress from "hooks/user/useGetSingleUserAddress/useGetSingleUserAddress";
import { userSelector } from "redux/user";
import { parseTimestampToDate} from 'utils/common'
import { getColorByStatus, getTextByStatus } from "./main";


interface Data {
    date: { seconds: number; nanoseconds: number;}
    status: StatusPayment;
    id: string;
    items: ProductType[] | any
    totalAmount: number;
    paymentMethod: PaymentMethods;
    userUid: string;
    shippingAddressId: string;
    numericOrderId: number;
}

interface Props {
    open: boolean;
    handleClose: () => void;
    data: Data;
}

const OrderResume = (props: Props) => {
    const { open, handleClose, data } = props;
    const user = useSelector(userSelector);
    const { t } = useTranslation();
    
    const { date, status, items, totalAmount, paymentMethod, userUid, shippingAddressId, numericOrderId} = data; //id,
    const addressData = useGetSingleUserAddress(userUid, shippingAddressId);

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title2"
        >
            <DialogTitle onClick={handleClose} id="responsive-dialog-title2">
                {<MdArrowBack />}
            </DialogTitle>
            <DialogContent>
                <Summary>
                <SummaryTitle>{t('order_detail')} #{numericOrderId}</SummaryTitle>
                <SummarySubTitle>
                    {t('date')}: {parseTimestampToDate(date)} &nbsp;&nbsp;&nbsp;&nbsp; {t('status')}: 
                    <SubtitleSpan style={{color: getColorByStatus(status)}}>{getTextByStatus(status)}</SubtitleSpan>
                </SummarySubTitle>
                {
                        items.map((item: any, index: number) => {
                            return (
                                <Item key={index}>
                                    <ItemImg src={item.image} alt={item.name} title={item.name} />
                                    <ItemDesc>
                                        <ItemName>{item.name}</ItemName>
                                        <ItemPrice>${item.price}</ItemPrice>
                                        <ItemQty>{t('quantity')}: {item.count}</ItemQty>
                                    </ItemDesc>
                                </Item>
                            )
                        })
                }
                <Resume>
                    <SpaceBetween>
                    <SumFinalText>{t('summary')}</SumFinalText>
                    <SummarySubtotal>
                        <SummaryText>{t('subtotal')}: </SummaryText>
                        <SummaryFinalPrice>${totalAmount}</SummaryFinalPrice>
                    </SummarySubtotal>
                    </SpaceBetween>
                    {/* <SummaryTotal>
                        <SummaryText>Envio: </SummaryText>
                        <SummaryFinalPrice>$50,30</SummaryFinalPrice>
                    </SummaryTotal> */}
                    <hr />
                    <SummaryTotal>
                    <SummaryText>{t('total')}: </SummaryText>
                    <SummaryFinalPrice>${totalAmount}</SummaryFinalPrice>
                    </SummaryTotal>
                </Resume>
                <hr />
                <FinalFooter>
                    <FooterItem>
                    <FooterItemTitle>{t('payment_methods')}</FooterItemTitle>
                    <FooterItemDesc>{paymentMethod}</FooterItemDesc>
                    </FooterItem>
                    <hr />
                    <FooterItem>
                    <FooterItemTitle>{t('shipment_information')}</FooterItemTitle>
                    <FooterItemDesc>
                        {t('name')}: <FooterItemSpan>{user.userData.firstName}</FooterItemSpan>
                        {t('surname')}: <FooterItemSpan>{user.userData.firstSurname}</FooterItemSpan>
                        {t('country')}: <FooterItemSpan>{addressData?.country}</FooterItemSpan>
                        {t('city')}: <FooterItemSpan>{addressData?.city}</FooterItemSpan>
                        {t('address')}:<FooterItemSpan>{addressData?.street}</FooterItemSpan>
                        {/* Telefono: <FooterItemSpan>57515014510 </FooterItemSpan> */}
                    </FooterItemDesc>
                    </FooterItem>
                    {/* <hr />
                    <FooterItem>
                        <FooterItemTitle>Dirección de facturación</FooterItemTitle>
                        <FooterItemDesc>
                            Nombre: <FooterItemSpan>Mariela </FooterItemSpan>
                            Apellido: <FooterItemSpan>Rivas </FooterItemSpan>
                            Pais: <FooterItemSpan>Panama </FooterItemSpan>
                            Ciudad: <FooterItemSpan>Panama </FooterItemSpan>
                            Direccion:{" "}
                            <FooterItemSpan>C. 68 Este 68, Panamá </FooterItemSpan>
                            Telefono: <FooterItemSpan>57515014510 </FooterItemSpan>
                        </FooterItemDesc>
                    </FooterItem> */}
                </FinalFooter>
                </Summary>
            </DialogContent>
            <DialogActions style={{width: '55%'}}>
                <Button onClick={handleClose}>{t('exit')}</Button>
            </DialogActions>
        </Dialog>
    )

}

export default OrderResume;