import { FC, useEffect } from 'react'
import { BrowserRouter, } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { errorLoginUserSelector } from 'redux/user'
import { useNotification } from 'lib/context/AlertContext'
// import {BugPopupContainer} from './styled'
import useMapPlaces from 'hooks/maps/useMapPlaces'
import useIpGeolocation from "hooks/system/useIpGeolocation/use-ip-geolocation";
import PopupBugReporter from 'components/popup-bug-reporter'
import { PayPalScriptProvider } from "@paypal/react-paypal-js"
import PrivateRouter from './Main'
import { getCurrency } from 'redux/common'
import useIsLocalhost from "hooks/system/useIsLocalhost/use-is-localhost";
import UseAuthentication from 'hooks/useAuthentication'

const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID || 'error'
const PAYPAL_SANDBOX_CLIENT_ID = process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_ID || 'error'


const Router: FC = () => {
  const { showError } = useNotification()
  useMapPlaces()
  UseAuthentication()
  useIpGeolocation({});
  const isLocalhost = useIsLocalhost()
  const errorLogin = useSelector(errorLoginUserSelector)
  const { isAuth } = useSelector((state: any) => state.auth)
  const currency = useSelector(getCurrency)

  useEffect(() => {
    if (errorLogin) {
      showError(errorLogin)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, errorLogin])

  return (
    <PayPalScriptProvider options={{
      clientId:  isLocalhost ? PAYPAL_SANDBOX_CLIENT_ID : PAYPAL_CLIENT_ID,
      currency: currency,
      components: 'buttons',
      // merchantId: process.env.REACT_APP_PAYPAL_MERCHANT_ID,
    }}>
      <BrowserRouter>
        <PrivateRouter />
        <div style={{
          position: 'fixed',
          background: 'transparent',
          bottom: 10,
          left: 10,
          zIndex: 1000000,
          cursor: 'pointer',
        }}>
          <PopupBugReporter />
        </div>
      </BrowserRouter>
    </PayPalScriptProvider>
  )

}

export default Router
