import { FC } from 'react';
import { useState } from 'react';
import { Container, FormContainer } from './styled';
import { H2 } from 'ui/Text';
import Input from 'ui/TextInput';
import PhoneInput from 'ui/PhoneInput';
import { Button } from 'ui/Buttons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { UserBasicDataFormProps } from 'types';
import { useTranslation } from "react-i18next";


const validationSchema = Yup.object({
    firstName: Yup.string().required('El nombre es obligatorio'),
    firstSurname: Yup.string().required('El apellido es obligatorio'),
    email: Yup.string().email('Correo inválido').required('El correo es obligatorio'),
    phoneNumber: Yup.string().matches(/^[0-9]+$/, 'El teléfono debe ser numérico').required('El teléfono es obligatorio'),
});

interface IUserBasicDataFormProps {
    onSubmit: (values: UserBasicDataFormProps) => void;
}

const UserBasicDataForm: FC<IUserBasicDataFormProps> = (props: IUserBasicDataFormProps) => {
    const { onSubmit } = props;

    const { t } = useTranslation();
    const [submitMessage, setSubmitMessage] = useState<string | null>(null);

    const formik = useFormik({
        initialValues: {
            firstName: '',
            firstSurname: '',
            email: '',
            phoneNumber: '',
            // country: '',
            // state: '',
            // city: '',
        },
        validationSchema,
        onSubmit: values => {
            console.log('Formulario enviado:', values); // Agregar console.log para verificar el envío del formulario
            onSubmit(values);
            setSubmitMessage('Formulario enviado con éxito');
            setTimeout(() => {
                setSubmitMessage(null);
            }, 5000);  // Ocultar el mensaje después de 5 segundos
        },
    });

    return (
        <Container>
            <H2>{t('personal_information')}</H2>
            <form onSubmit={formik.handleSubmit}>
                <FormContainer>
                    <Input
                        type='text'
                        label={t('name')}
                        name="firstName"
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                        helperText={formik.touched.firstName && formik.errors.firstName}
                    />
                    <Input
                        type='text'
                        label={t('last_name')}
                        name="firstSurname"
                        onChange={formik.handleChange}
                        value={formik.values.firstSurname}
                        helperText={formik.touched.firstSurname && formik.errors.firstSurname}
                    />
                    <Input
                        type='email'
                        label={t('email')}
                        name="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                    <PhoneInput
                        placeholder="Teléfono"
                        onChange={(e) => {
                            formik.setFieldValue('phoneNumber', e);
                        }}
                        value={formik.values.phoneNumber}
                        helperText={formik.touched.phoneNumber ? formik.errors.phoneNumber : ''}
                    />
                </FormContainer>
                <Button
                    type="submit"
                    //disabled={Object.keys(formik.errors).length > 0}
                    disabled={!formik.isValid || formik.isSubmitting}
                    onClick={() => {
                        formik.handleSubmit();
                      }
                    }
                >{t('send')}</Button>
                {submitMessage && <p>{submitMessage}</p>}
            </form>
        </Container>
    );
};

export default UserBasicDataForm;
