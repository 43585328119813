import { useState } from "react"; 
import { ContentProductsContainer, Filter, ListProductsContainer, SpaceBetween, TextFilter, Row, GridContainer } from './styled';
import { H2, H3 } from 'ui/Text';
import CarouselGeneral from "components/carousel-general";
import { MdFilter } from "react-icons/md";
import { ThemeProvider } from "@mui/material";
import { theme } from "lib/theme";
import Product from "ui/Products";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import ProductsSidebarFilter from "views/containers/SidebarFilters/Products";
import { Product as ProductType, ProductCategory as ProductCategoryType, FiltersProducts } from 'types';
import { STORE_PRODUCTS_CATEGORIES } from 'constants/index';
import { ProductsCategoryType } from 'types';
import { getButtonText, getPriceTypeByCategoryType } from "./main";
import { getLanguage } from "redux/common";
import SkeletonsProducts from "./skeleton-products";
import { Link } from "react-router-dom";

interface Props {
    titleSection?: string;
    data: ProductType[][];
    categories: ProductCategoryType[];
    setRoute?: any;
    setApply?: any;
    setFilters: (filters: FiltersProducts) => void;
    type?: ProductsCategoryType;
    filters: FiltersProducts;
}

const SKELETONS_COUNT = Array(8).fill(0);

// Main component
const ContentProducts = (props: Props) => {
    const { data, categories, titleSection, setFilters, type, filters } = props;
    console.log('data' , data)
    const { t } = useTranslation();
    const language = useSelector(getLanguage);
    const [open, setOpen] = useState(false);

    const handleSetFilters = (filters: FiltersProducts) => {
        setFilters(filters);
    }

    function getCategoryName(id: number) {
        const category = STORE_PRODUCTS_CATEGORIES.find((category) => category.id === id);
     //   console.log(category);
        return category?.name[language] || '';
    }

    function getProductMerged() {
        const productsMerged = data.reduce((acc, val) => acc.concat(val), []);
        return productsMerged || [];
    }

    function getCategoryIdFromItemArray(itemArray) {
        if (itemArray.length === 0) return null;
        return itemArray[0].idCategory;
    }

    function createCategoryLink(categoryId) {
        const category = categories.find(cat => cat.id === categoryId);
        const categoryRoute = category?.route || '';
        
        // Capitalizar cada palabra y eliminar espacios
        const formattedRoute = categoryRoute
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join('')
            .replace(/\s+/g, '');

        return `/products/category?uid=${encodeURIComponent(formattedRoute)}`;
    }

    return (
        <div>
            <ThemeProvider theme={theme}>
                <Row justifyContent='space-between' padding='0px 0px'>
                    <H2>{titleSection || `${t('products')}`}</H2>
                    <Filter>
                        <TextFilter onClick={() => setOpen(true)}><MdFilter />{t('filter')}</TextFilter>
                    </Filter>
                </Row>
                {
                    !data.length && (
                        <div style={{ width: '100%', marginTop: '1rem' }}>
                            {
                                SKELETONS_COUNT.map((item, index) => (
                                    <SkeletonsProducts key={index} />
                                ))
                            }
                        </div>
                    )
                }
                <ContentProductsContainer>
                    {
                        (!type || type === 'store') && data.length > 0 && data.map((item: ProductType[], index) => {
                            const categoryId = getCategoryIdFromItemArray(item);
                            const linkUrl = createCategoryLink(categoryId);
                            // console.log('item data', item)

                            return (
                                <ListProductsContainer pad={"1rem"} key={index}>
                                    <SpaceBetween>
                                        <H3 margin='0rem 0rem 1.2rem 0rem'>
                                            <Link to={linkUrl}>{getCategoryName(categoryId) || categoryId}</Link>
                                        </H3>
                                    </SpaceBetween>
                                    {
                                        item.length > 0 ? <CarouselGeneral items={item} renderItem={Product} itemsToShow={5} itemsMobile={2} />
                                            :
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <H3>{t('error_no_products_in_this_category')}</H3>
                                            </div>
                                    }
                                </ListProductsContainer>
                            )
                        })
                    }
                    {
                        (type === 'machinery' || type === 'wholesale') && data.length > 0 && getProductMerged().length > 0 && (
                            <GridContainer>
                                {
                                    getProductMerged().map((item: ProductType, index) => (
                                        <Product
                                            key={index}
                                            data={item}
                                            priceType={getPriceTypeByCategoryType(type)}
                                            buttonText={getButtonText(type)}
                                            mainCategory={type}
                                        />
                                    ))
                                }
                            </GridContainer>
                        )
                    }
                </ContentProductsContainer>
                <ProductsSidebarFilter
                    onSetFilters={handleSetFilters}
                    categories={categories}
                    open={open}
                    setOpen={() => setOpen(false)}
                    filters={filters}
                />
            </ThemeProvider>
        </div>
    );
}
export default ContentProducts;
