import { FC } from "react";
import Carousel from "react-multi-carousel";
import ServicesCard from "components/cards/services-card";
import "./directory.css";
import { Container, FlexColumn, Title, WrapperTitle } from "./styled"; // Link
import { RESPONSIVE_BREAKPOINTS } from "modules/services/constants";
import { servicesTitles } from "__mocks__/servicesMock";
// import { BREAKPOINTS } from "constants/index";

interface DirectoryProps {
  title: string;
  services: any[];
}
export const Directory: FC<DirectoryProps> = ({ title, services }) => {
  // const isDesktop = window.innerWidth > BREAKPOINTS.lg;

  return (
    <Container>
      <FlexColumn>
        <WrapperTitle>
          <Title>{servicesTitles[title]}</Title>
          {/* {isDesktop && <Link href={""}>Ver todas</Link>} */}
        </WrapperTitle>

        <Carousel
          responsive={RESPONSIVE_BREAKPOINTS}
          infinite={true}
          autoPlay={!true}
          autoPlaySpeed={4000}
          arrows={true}
          itemClass="carouselItem"
        >
          {services.length > 0 && services.map((service, index) => {
            return (
              <ServicesCard
                linkButton={true}
                showPrice={false}
                key={index}
                id={service?.id || service?.uid}
                title={service?.name?.es}
                description={service?.description?.es || service?.shortDescription?.es}
                image={service?.images?.length ? service?.images[0] : service?.image || ''}
                price={service?.price || service?.price?.es}
                shortDescription={service?.shortDescription?.es}
                service={service}
              />
            );
          })}
        </Carousel>
      </FlexColumn>
    </Container>
  );
};

export default Directory;
