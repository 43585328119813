import {useEffect, useState} from 'react'
import MainLayout from 'views/layout/MainLayout'
import {Container, Content, YellowBar} from "./styled";
import DetailsOffer from "views/containers/Offers/DetailsOffer";
import {ContentProductsContainer, DescriptionGrid, DescriptionItem, GalleryContainer, General, Header, ItemContainer, Options, SpaceBetween} from "views/containers/Offers/DetailsOffer/styled";
import Skeleton from "@mui/material/Skeleton";
import { useGetFirestoreDoc } from 'hooks/useGetDocumentFirestore/useGetDocumentFirestore';
 
const PATH = "PANAMA/Offers/Posts"

const OfferDetail = (props) => {

    const { fetchDoc, docData } = useGetFirestoreDoc();

    const [idOffer, setIdOffer] = useState<string | null>(null)
   
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        setIdOffer(id)
    }, [])

    useEffect(() => {
        if (idOffer) {
            fetchDoc({
                path: PATH,
                uid: idOffer
            });
        }
    }, [idOffer])

    return (
        <MainLayout type='offers'>
            <Container>
                <Content>
                    <YellowBar/>
                    {
                        docData  ? <DetailsOffer item={docData}/> :
                            <div>
                                <Skeleton variant={"text"}/>
                                <ContentProductsContainer>
                                    <GalleryContainer>
                                        <Skeleton variant={"rectangular"} width={"100%"} height={"100%"}/>
                                    </GalleryContainer>
                                    <ItemContainer>
                                        <Header>
                                            <SpaceBetween>
                                                <Skeleton variant={"text"} width={"70%"}/>
                                                <Skeleton variant={"rectangular"} width={100} height={25}/>
                                            </SpaceBetween>
                                        </Header>
                                        {/*<FullName>{item.description}</FullName>*/}

                                        <hr/>
                                        <Options>
                                            <Skeleton variant={"text"}/>
                                            <DescriptionGrid>
                                                <DescriptionItem>
                                                    <Skeleton variant={"text"}/>
                                                    <Skeleton variant={"text"}/>
                                                </DescriptionItem>
                                            </DescriptionGrid>
                                        </Options>
                                        <hr/>
                                        <General>
                                            <Skeleton variant={"text"}/>
                                            <Skeleton variant={"text"}/>
                                        </General>
                                    </ItemContainer>
                                </ContentProductsContainer>
                            </div>
                    }
                </Content>
            </Container>
        </MainLayout>
    )
}

export default OfferDetail