import styled from 'styled-components'
import { Typography } from '@mui/material'

interface IText {
  margin?: string
  weight?: number
  color?: string
  fontSize?: string
  fontWeight?: number
  size?: 's' | 'm' | 'l' | 'xl';
  typeFont?: 'outfit' | 'montserrat'
  maxLines?: number
}

const fontSizes = {
  s: '12px',
  m: '16px',
  l: '20px',
  xl: '24px',
};


export const H1 = styled(Typography).attrs({
  variant: 'h1',
})`
  && {
    font-weight: 700;
    font-family: ${(props) => props.typeFont === 'outfit' ? 'outfit' : 'montserrat'};
    font-size: 2.5rem;
    line-height: 3rem;
    letter-spacing: 0.00735rem;
    color: #000000;
    }

    @media (max-width: 992px) {
      font-size: 2.25rem;
      line-height: 2.75rem;
    }

    @media (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.5rem;
    }

    @media (max-width: 540px) {
      font-size: 1.75rem;
      line-height: 2.25rem;
    }
  

`

interface IH2 {
  margin?: string
}

export const H2 = styled(Typography).attrs({
  variant: 'h2',
}) <IH2>`
  && {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.00735rem;
    color: #0C121D;
    margin: ${(props) => props.margin};

    @media (max-width: 1024px) {
      font-size: 24px;
      line-height: 36px;
    }

    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 36px;
    }

    @media (max-width: 540px) {
      font-size: 22px;
      line-height: 28px;
    }

    @media (max-width: 450px) {
      font-size: 18px;
      line-height: 26px;
    }
    
  }
`

export const H3 = styled(Typography).attrs({
  variant: 'h3',
})`
  && {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 0.00735rem;
    color: ${(props) => props.color ? props.color : '#000000'};
    font-family: ${(props) => props.family ? props.family : 'Montserrat'};
    margin: ${(props) => props.margin};

    @media (max-width: 992px) {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    @media (max-width: 768px) {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    @media (max-width: 540px) {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }

  }
`

export const H4 = styled(Typography).attrs({
  variant: 'h4',
})`
  && {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.75rem;
    letter-spacing: 0.00735rem;
    color: #000000;
    text-align: ${(props) => props.textAlign || 'left'};
    margin: ${(props) => props.margin || '0px'};
  }
`


export const H5 = styled(Typography).attrs({
  variant: 'h5',
}) <IText>`
  && {
    // INTER FONT FAMILY
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.00735rem;
    color: #1F2124;
    margin: ${(props) => props.margin};
  }
`

export const H6 = styled(Typography).attrs({
  variant: 'h6',
})`
  && {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.00735rem;
    color: #000000;
  }


  @media (max-width: 540px) {
    font-size: 0.775rem!important;
    line-height: 1.25rem;
  }

`

// componente p
export const P = styled(Typography).attrs({
  variant: 'body1',
}) <IText>`
  && {
    font-family: 'Inter', sans-serif;
    font-weight: ${(props) => props.fontWeight || 400};
    margin: ${(props) => props.margin};
    font-size: ${(props) => props.fontSize || '16px'};
    line-height: 26px;
    letter-spacing: 0.00735rem;
    color: ${(props) => props.color || '#303030'};
  }

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 24px;
    text-align: ${(props) => props.textAlign || 'left'};;
  }

  @media (max-width: 540px) {
    font-size: 12px;
    line-height: 22px;
  }
`

// componente span
export const Span = styled(Typography).attrs({
  variant: 'body2',
}) <IText>`
  && {
    margin: ${(props) => props.margin};
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.00735rem;
    color: ${(props) => props.color || '#303030'};
  }
`


export const TextError = styled(Typography).attrs({
  variant: 'body2',
})`

  && {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.00735rem;
    color: #FF0000;
  }
`

export const Semibold = styled(Typography).attrs({
  variant: 'body2',
}) <IText>`
  && {
    font-weight: 600;
    font-size: ${(props) => fontSizes[props.size] || '2.5rem'};
    line-height: ${(props) => fontSizes[props.size] || '2.3rem'};
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => props.maxLines || 10};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: 0.00735rem;
    color: #303030;
  }
`


