import { useDispatch } from 'react-redux';
import { logout, setUser} from 'redux/user';
import { setAuth } from 'redux/auth';


const useLogout = () => {
    const dispatch = useDispatch();
    
    function logoutUser() {
        dispatch(logout());
        dispatch(setUser(null));
        dispatch(setAuth({isAuth: false}));
    }

    return logoutUser;

}

export default useLogout;
