import { httpsCallable } from 'firebase/functions';
import { functions } from 'config/firebase';
import { DeleteClassified } from 'types';


async function deleteClassified(deleteService: DeleteClassified) {
  const deleteClassifiedFunction = httpsCallable(functions, 'deleteClassified');

  try {
    const result = await deleteClassifiedFunction( deleteService );
    return result.data;
  } catch (error) {
    console.error('Error: eliminando classi', error);
    throw error;
  }
}

export default deleteClassified;