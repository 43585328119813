import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { slide as Menu } from 'react-burger-menu';
import './Sidebar.scss';
import { Button } from 'ui/Buttons';
import home from "assets/Sidebar/inicio.svg";
import carrito from "assets/Sidebar/carrito.svg";
import usuario from "assets/Sidebar/usuario.svg";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import Banner from 'assets/banner.png';
import { Link } from 'react-router-dom';
import { CardUser, FirstInfo, FullImg, Icon, ImageIcon, InfoDesc, LoginButton, ListItem, ListLink, ListU, Logout, MenuItem, ProfileInfo, Socials, ImageProfile } from "./styled";
import { RxExit } from "react-icons/rx";
import MenuIcon from 'assets/icons/MenuIcon';
import useIsAuth from "hooks/user/useIsAuth/useIsAuth";
import useLogout from "hooks/useLogout/useLogout";
import ServicesIcon from 'assets/ServicesIcon.png';
import ProductsIcon from 'assets/ProductsIcon.png';
import { MAIN_ROUTES } from 'constants/routes';
import { userSelector } from 'redux/user';
import { BREAKPOINTS } from 'constants/index';
interface SidebarProps {
    pageWrapId: string,
    outerContainerId: string
}

export const Sidebar = ({ pageWrapId, outerContainerId }: SidebarProps) => {
    const logout = useLogout();
    const { t } = useTranslation();
    const isAuth = useIsAuth();
    const { userData } = useSelector(userSelector);
    const [userInfo, setUserInfo] = useState<any | null>(null);

    useEffect(() => {
        if (userData) {
            setUserInfo(userData);
        } else {
            setUserInfo(null);
        }
    }, [userData]);


    return (
        <Menu 
            pageWrapId={pageWrapId}
            outerContainerId={outerContainerId}
            customBurgerIcon={<MenuIcon />}
            styles={{
                bmBurgerButton: {
                    position: 'fixed',
                    left: '12px',
                    top: window.innerWidth > BREAKPOINTS.lg ? '22px' : '10px',
                },
            }}
            className="sidebar"
        >
            <CardUser> 
                {
                    userInfo?.firstName || userInfo?.displayName ? (
                        <FirstInfo>
                            <ImageProfile src={userInfo.picture ? userInfo.picture : 'https://cdn-icons-png.flaticon.com/512/3177/3177440.png'} loading="lazy" alt={`${userInfo.firstName} profile picture`}/>
                            <ProfileInfo>
                                <InfoDesc>{t('hello')},</InfoDesc>
                                <InfoDesc>{userInfo?.firstName ? `${userInfo.firstName} ${userInfo.firstSurname}` : `${userInfo?.displayName}`}</InfoDesc>
                            </ProfileInfo>
                        </FirstInfo>
                    )
                        :
                        (
                            <>
                                <LoginButton >
                                    <a style={{ color: '#fff' }} href="/login" >{t('login')}</a>
                                </LoginButton>
                            </>
                        )
                }
            </CardUser>
            <MenuItem className="menu-item" href="/">
                <img src={home} loading='lazy' alt="home" title="home" />
                <Icon data-text={'Home'}>{t('home')}</Icon>
            </MenuItem>
            {
                isAuth && (
                    <MenuItem className="menu-item" href={MAIN_ROUTES.Profile}>
                        <img src={usuario} loading='lazy' alt="Usuario" title="Usuario" />
                        <Icon data-text={'Profile'}>{t('profile')}</Icon>
                    </MenuItem>
                )
            }
            {
                isAuth && (
                    <MenuItem className="menu-item" href={MAIN_ROUTES.Cart}>
                        <img src={carrito} loading='lazy' alt="Carrito" title="Carrito" />
                        <Icon data-text={'Cart'}>{t('cart')}</Icon>
                    </MenuItem>
                )
            }
            {/* {
                isAuth && (
                    <MenuItem className="menu-item" href="/">
                        <img src={corazon} alt="" />
                            <Icon data-text={'Favorite'}>Mis Favoritos</Icon>
                    </MenuItem>
                )
            } */}
            <MenuItem className="menu-item" href="/products">
                <ImageIcon src={ProductsIcon} loading='lazy' alt={t('products')} title={t('products')} />
                <Icon data-text={'ProductsIcon'}>{t('products')}</Icon>
            </MenuItem>
            <MenuItem className="menu-item" href="/services">
                <ImageIcon src={ServicesIcon} loading='lazy' alt={t('services')} title={t('services')} />
                <Icon data-text={'Services'}>{t('services')}</Icon>
            </MenuItem>
            {/* <MenuItem className="menu-item" href="/classifieds">
                <img src={clasificado} alt="" />
                <Icon data-text={'Classifieds'}>Clasificados</Icon>
            </MenuItem>
            <MenuItem className="menu-item" href="/offers">
                <ImageIcon src={OffersIcon} alt='Categoria de negocios sidebar' />
                <Icon data-text={'Offers'}>Ofertas</Icon>
            </MenuItem>
            <MenuItem className="menu-item" href="/business">
                <ImageIcon src={BussinessIconSideBar} alt='Categoria de negocios sidebar' />
                <Icon data-text={'bussiness'}>Gana Dinero</Icon>
            </MenuItem> */}

            <Link to="/needs"><FullImg src={Banner} loading="lazy" alt="¡Dinos qué necesitas hoy!" title="¡Dinos qué necesitas hoy!" /></Link>
            <hr />
            {/* <ListU>
                <ListItem><ListLink href="#">Noticias</ListLink></ListItem>
                <ListItem><ListLink href="#">Entretenimiento</ListLink></ListItem>
                <ListItem><ListLink href="#">Sobre nosotros</ListLink></ListItem>
                <ListItem><ListLink href="#">Blog</ListLink></ListItem>
            </ListU> */}
            <hr />
            <ListU>
                <ListItem><ListLink href={MAIN_ROUTES.Terms}>{t('terms_and_conditions')}</ListLink></ListItem>
                <ListItem><ListLink href={MAIN_ROUTES.Politics}>{t('privacy_policy')}</ListLink></ListItem>
            </ListU>
            <Socials>
                <Link to="https://www.facebook.com/Whatdoyouneed7" rel="noopener noreferrer nofollow" target="_blank" ><FaFacebook size={30} style={{ marginRight: '10px' }} /></Link>
                <Link to="https://www.instagram.com/whatdoyouneed7" rel="noopener noreferrer nofollow" target="_blank"><FaInstagram size={30} /></Link>
            </Socials>
            <Logout >
                {
                    userInfo?.firstName && (
                        <>
                            <div style={{ width: '20%' }}>
                                <RxExit size={20} />
                            </div>
                            <div style={{ width: '80%' }}>
                                <Button onClick={logout}>{t('logout')}</Button>
                            </div>
                        </>
                    )
                }
            </Logout>
        </Menu>
    )
        ;
};
