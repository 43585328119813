import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import usePaymentProcessing from "hooks/checkout/usePaymentCheckout";
import {  BackButton } from 'ui/Buttons';
import { Methods, StepTitle, ButtonsNavsContainer, StepThreeContainer } from "../styled";
import { RadioGroup } from "@mui/material";
import ModalLoader from "ui/ModalLoader/modal-loader";
import CreditCard from "views/containers/Payments/CreditCard";
import Paypal from "views/containers/Payments/Paypal";
import { totalCart, itemsCart as cartItems } from 'redux/cart';
import { setPaymentMethod, getPaymentMethod, getAddress, clearCheckout, setUidCheckout } from 'redux/checkout';
import { userSelector, offlineUserInfoSelector, getIpInfoSelector } from "redux/user";
import { getCountry, getLanguage, getCurrency } from "redux/common";
import { useTranslation } from "react-i18next";
import useCreateOrder from "hooks/checkout/order/use-create-order";
import { useNotification } from "lib/context/AlertContext";
import { STEPS_CHECKOUT } from "../constants";
import { PaymentMethodIdEnum } from "constants/constants";
import { BREAKPOINTS } from "constants/index";
// import { getPaymentMethodByCountry } from "../main";
import { IPaypalResponse } from "types/checkout"

interface StepThreeProps {
    onSubmit: (step: number) => void;
    onBack: () => void;
    setData: (data: any) => void;
}

const StepThree = ({ onSubmit, onBack, setData }: StepThreeProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { showSuccess, showError } = useNotification();
    const { paymentResponse, processPayment, error: errorCheckout, success: successPayment } = usePaymentProcessing();
    const  { createOrder, error: errorCreateOrder, response: responseCreateOrder, } = useCreateOrder();
    const paymentMethod = useSelector(getPaymentMethod);
    const itemsCart = useSelector(cartItems);
    const totalAmount = parseFloat(useSelector(totalCart).toFixed(2));
    const address = useSelector(getAddress);
    const userData = useSelector(userSelector).userData;
    const offlineUserInfo = useSelector(offlineUserInfoSelector);
    const country = useSelector(getCountry);
    const language = useSelector(getLanguage);
    const currency = useSelector(getCurrency);
  
    const { firstName, firstSurname, email, phone } = userData || offlineUserInfo;

    interface IOrderCreatedIds {
        orderUidFirestore: string;
        orderUidPostgre: number;
    }

    const [dataOrder, setDataOrder] = useState<IOrderCreatedIds  | null>(null);
    const [paymentSuccess, setPaymentSuccess] = useState<boolean | null>(null);
    const [dataSuccessPayment, setDataSuccessPayment] = useState<any>(null);
    const [dataToCreateInvoice, setDataToCreateInvoice] = useState<any>(null);
    const [loading, setLoading] = useState(false);


    // FUNCIONES

    const handleBeginPaymentAndCreateOrder = (paymentMethodId) => {
        setLoading(true);
        createOrder({
            userUid: userData.uid,
            items: itemsCart,
            totalAmount,
            orderType: "product_payment",
            paymentMethodId,
            country,
            status: "pending",
        });
    }
    

    // maneja el seteo de la data para pasar  al checkout
    const handleFinishAndSetData = (data) => {
        console.log('data finish payment', data)

        setData(prev => ({
            ...prev,
            itemsCart,
            paymentMethod,
            dataSuccessPayment,
            totalAmount,
            shippingCost: 0,
            taxAmount: 0,
            date: dayjs().format(),
            id: dataOrder?.orderUidPostgre || 'error',
            uid: dataOrder?.orderUidFirestore || 'error',
        }));

        showSuccess(t('order_placed_successfully'));
        // dispatch(clearCheckout());
        onSubmit(STEPS_CHECKOUT.RESUME_CHECKOUT);
    }


    // MANEJA PAGO CON TARJETA DE CREDITO
    const handlePaymentSuccessfulWithCreditCard = (data: any) => {
        showSuccess(t('payment_successful_thank_you'));
        setPaymentSuccess(true);

        const dataCreateInvoice = {
            userUid: userData?.uid || null,
            orderId: null,
            orderUid: null,
            paymentId: data.codOper,
            paymentMethodId: PaymentMethodIdEnum.paypal,
            shippingAddressId: address?.id || null,
            items: itemsCart,
            paypalTransactionId: data.id,
            shippingCost: 0,
            taxAmount: 0,
            country,
            language,
            currency,
            typeTransaction: "product_payment",
            userInfo: {
                email: email,
                firstName: firstName,
                firstSurname: firstSurname,
                phone: phone,
            },
        };

        setDataToCreateInvoice(dataCreateInvoice);

    };


    // process payment with paypal
    const handlePaymentPaypal = (data: IPaypalResponse) => {
        setLoading(true);
        setPaymentSuccess(true);
        const dataCreateInvoice = {
            userUid: userData?.uid || null,
            orderId: dataOrder?.orderUidPostgre || null,
            orderUid: dataOrder?.orderUidFirestore || null,
            paymentId: data.id,
            paymentMethodId: PaymentMethodIdEnum.paypal,
            shippingAddressId: address?.id || null,
            items: itemsCart,
            paypalTransactionId: data.id,
            shippingCost: 0,
            taxAmount: 0,
            country,
            language,
            currency,
            typeTransaction: "product_payment",
            userInfo: {
                email: email,
                firstName: firstName,
                firstSurname: firstSurname,
                phone: phone,
            },
        };

        setDataToCreateInvoice(dataCreateInvoice);  

    }

    // Maneja el procesamiento del pago
    const handleCreateInvoice = (data: any) => {

        console.log('dataCreateInvoice', data);
        console.log('dataOrder', dataOrder);

        processPayment({
            userUid: userData?.uid || null,
            orderId: dataOrder?.orderUidPostgre || null,
            orderUid: dataOrder?.orderUidFirestore || null,
            paymentId: data.paymentId,
            paymentMethodId: PaymentMethodIdEnum.card,
            shippingAddressId: address?.id || null,
            items: itemsCart,
            shippingCost: 0,
            taxAmount: 0,
            country,
            language,
            currency,
            typeTransaction: "product_payment",
            userInfo: {
                email: email,
                firstName: firstName,
                firstSurname: firstSurname,
                phone: phone,
            },
        });


          // setData(prev => ({
        //     ...prev,
        //     dataCreditCardPayment: { ...data },
        //     dataPaypalPayment: null,
        //     paymentMethod: PaymentMethodEnum.card,
        //     itemsCart,
        //     totalAmount,
        //     shippingCost: 0,
        //     taxAmount: 0,
        // }));

    }


    // EFFECTS

    useEffect(() => {
        if (errorCreateOrder) {
            showError(t('error_during_payment_check_data'));
            setLoading(false);
        }


        if (responseCreateOrder) {
            if (responseCreateOrder.status === 'ERROR') {
                showError(t('error_during_payment_check_data'));
            } else {
                console.log('responseCreateOrder1', responseCreateOrder);
                setDataOrder(responseCreateOrder.data as IOrderCreatedIds);
            }
            
        
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorCreateOrder, responseCreateOrder]);

    // handle finish payment
    // useEffect(() => {
    //     console.log('paymentResponse de crear invoice', paymentResponse);    
    //     if (successPayment && dataOrder?.orderUidPostgre) {
    //         handleFinishAndSetData(paymentResponse);
    //     } else if (successPayment === false) {

    //         if (paymentResponse?.code === 409) {
    //             alert('Borro id de checkout');
    //             dispatch(setUidCheckout(null));
    //         }

    //         showError(t('Ha ocurrido un error durante el pago, el monto ha sido debitado, estamos investigando lo sucedido.'));
    //         setLoading(false);
    //     } else {
    //         setLoading(false);
    //     }
    // }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //     , [paymentResponse, successPayment, dataOrder, dataOrder?.orderUidPostgre]);


    // HANDLE CREATE INVOICE
    useEffect(() => {
        if (paymentSuccess && dataToCreateInvoice) {
            console.log('dataToCreateInvoice', dataToCreateInvoice);

            const dataWithOrderIds  = {
                ...dataToCreateInvoice,
                orderId: dataOrder?.orderUidPostgre,
                orderUid: dataOrder?.orderUidFirestore,
            };

            handleCreateInvoice(dataWithOrderIds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataToCreateInvoice]);

    useEffect(() => {
        console.log('paymentResponse de crear invoice', paymentResponse);

        if (paymentResponse) {
            if (paymentResponse.status === 'ERROR') {
                showError(t('error_during_payment_check_data'));
            } else {
                setLoading(false);
                handleFinishAndSetData(paymentResponse.data);

            }
        }

    }, [paymentResponse]);


    return (
        <StepThreeContainer>
            <Methods >
                <StepTitle>{t('choose_payment_method')}</StepTitle>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="card"
                    name="payment"
                >
                    <CreditCard
                        onBeginPayment={() => {
                            handleBeginPaymentAndCreateOrder(PaymentMethodIdEnum.card);
                        }}
                        onSuccessfulPayment={(e) => {
                            setTimeout(() => {
                                handlePaymentSuccessfulWithCreditCard(e);
                            }, 1000);
                        }}
                        onFailedPayment={() => {
                            showError(t('error_during_payment_check_data'));
                            setLoading(false);
                        }}
                        setChecked={() => {
                            dispatch(setPaymentMethod("card"));
                        }}
                        checked={paymentMethod === "card"}
                        infoTransaction={{
                            amount: totalAmount,
                            description: `${t('credit_card_payment_for_products_services_needed')}`,
                            email,
                            firstName,
                            firstSurname,
                            phone,
                            concept: `${t('online_payment_for_products_services_needed')}`,
                            taxAmount: 0,
                            lang: language,
                        }}
                    />
                    <Paypal
                        checked={paymentMethod === "paypal"}
                        setChecked={() => {
                            dispatch(setPaymentMethod("paypal"));
                        }}
                        onFailedPayment={() => {
                            showError(t('error_during_payment_check_data'));
                            setLoading(false);
                        }}
                        onBeginPayment={() => {
                            handleBeginPaymentAndCreateOrder(PaymentMethodIdEnum.paypal);
                        }}
                        onSuccessfulPayment={(e) => {
                            handlePaymentPaypal(e);
                        }}
                        infoTransaction={{
                            amount: totalAmount,
                            description: `${t('paypal_payment_for_products_services')}`,
                            email,
                            firstName,
                            firstSurname,
                            phone,
                            concept: `${t('paypal_payment_for_products_services')}`,
                            taxAmount: 0,
                            lang: language
                        }}
                    />

                    {/* {paymentMethodByCountry.map((method, index) => {
                        const Component = method === "yappy" ? Yappy : method === "cash" ? Cash : method === "ach" ? Ach : method === "paypal"  ? Paypal : Paypal;
                        return (
                            <Component
                                key={index}
                                checked={paymentMethod === method}
                                setChecked={() => {
                                    dispatch(setPaymentMethod(method));
                                }}
                                onFailedPayment={() => { }}
                                onBeginPayment={() => {}}
                                onSuccessfulPayment={(e) => {
                                    if (e.details) { // if exist details is paypal
                                        handlePaymentPaypal(e.details);
                                    } else {
                                        handleSetDataSuccessPayment(e);
                                    }
                                }}
                                infoTransaction={{
                                    amount: totalAmount,
                                    description: `${t('paypal_payment_for_products_services')}`,
                                    email,
                                    firstName,
                                    firstSurname,
                                    phone,
                                    concept: `${t('paypal_payment_for_products_services')}`,
                                    taxAmount: 0,
                                    lang: language
                                }}
                            />
                        )
                    })} */}
                </RadioGroup>
            </Methods>

            <ButtonsNavsContainer width={window.innerWidth < BREAKPOINTS.lg ? '90%' : '80%'}>
                <BackButton onClick={onBack} text={`${t('back')}`} />
                {/* <Button
                    type="submit"
                    onClick={handlePaymentProcessing}
                    disabled={!paymentMethod || (paymentMethod === "card" && !paymentResponse) || (paymentMethod !== "card" && !dataSuccessPayment)}
                >
                    {t('go_to_summary')}
                </Button> */}
            </ButtonsNavsContainer>
            <ModalLoader isLoading={loading} />
        </StepThreeContainer>
    )
};

export default StepThree;