import {AmountInput, ItemImage, Container, CartName, CartNameSmall, CartPrice, CartRemove, ItemContainer, CartSection2, NameContainer, CartSection3, CountContainer, ButtonUpdateQty} from "./styled";
import {IoMdTrash} from "react-icons/io";
import Xbox from "assets/xbox.png";
import {AiOutlineMinusCircle, AiOutlinePlusCircle} from "react-icons/ai";

interface ICartItem {
    id: number | string;
    price: number;
    name: string;
    quantity: number;  
    image: string;
    onUpdateQuantity: (quantity: number) => void;
    onDeleteItem: (id: number | string) => void;
}


const CartItem = (props: ICartItem) => {
    const {price, name, quantity, onUpdateQuantity, image, onDeleteItem, id} = props;

    return (
        <Container>
            <ItemContainer>
                <CartRemove onClick={() => onDeleteItem(id)}>
                    <IoMdTrash size={25} color={"#F72C1E"}/>
                </CartRemove>
                <ItemImage  src={image || Xbox}/>
                <NameContainer>
                    <CartName>{name || 'Error at name'}</CartName>
                </NameContainer>
               
            </ItemContainer>
            
            <CartSection3>
                <CartPrice>${price || 'error price'}</CartPrice>
                <CountContainer>
                        <CartNameSmall>Cantidad</CartNameSmall>
                        <ButtonUpdateQty  onClick={ () => {
                            if (quantity > 1) {
                                onUpdateQuantity(quantity - 1)
                            }
                        }} >
                            <AiOutlineMinusCircle color={"#5C7C9A"} size={window.innerWidth < 500 ? 20:25}/>
                        </ButtonUpdateQty>
                        <AmountInput sizeW={window.innerWidth < 500 ? "1.8rem":"2.2rem"} sizeH={window.innerWidth < 500 ? "1.5rem":"2rem"} value={quantity}/>
                        <ButtonUpdateQty onClick={ () => {
                            onUpdateQuantity(quantity + 1)
                        }}>
                            <AiOutlinePlusCircle  color={"#5C7C9A"} size={window.innerWidth < 500 ? 20:25}/>
                        </ButtonUpdateQty>
                </CountContainer>
            </CartSection3>
        </Container>

    )
};

export default CartItem;