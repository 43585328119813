import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  gap: .5rem;
  align-items: center;
  justify-content: space-between;
  padding: .8rem;
  background: #F5F5F6;
  box-shadow: 0 4px 8px 2px rgba(2, 64, 154, 0.16);
  border-radius: 10px;
  @media only screen and (max-width: 540px){
    flex-direction: column-reverse;
  }
`
export const CartRemove = styled.div`
`
export const ItemImage = styled.img`
  width: 8%;
  @media only screen and (max-width: 768px){
    width: 15%;
  }

  @media only screen and (max-width: 500px){
    width: 15%;
  }
`
export const CartName = styled.span`
  ${props => props.theme.typography.body.sm.regular};
`

export const CountContainer = styled.div`
  display: flex;
  flex: 1;
  gap: .5rem;
  justify-content: flex-end;
  align-items: flex-end;
`

export const ButtonUpdateQty = styled.div`
  cursor: pointer;
  // hover
  &:hover {
    transform: scale(1.05);
  }
`

export const CartNameSmall = styled.span`
  ${props => props.theme.typography.body.sm.regular};
  font-weight: 600;
  margin-bottom: 2px;
`
export const CartAmount = styled.span`
  ${props => props.theme.typography.body.sm.regular};
  margin-right: .5rem;
`
export const AmountInput = styled.input`
  text-align: center;
  width: ${props=>props.sizeW};
  height: ${props=>props.sizeH};
  border-radius: 5px;
  border: 2px solid #CDD0D4;
`
export const CartPrice = styled.span`
  white-space: nowrap;
  ${props => props.theme.typography.body.sm.bold};
`

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  @media only screen and (max-width: 600px){
   justify-content: flex-end;
    gap: .5rem;
  }
`
export const CartSection2 = styled.div`
  display: flex;
  gap: .5rem;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 600px){
    display: none;
  }
`

export const NameContainer = styled.div`
  gap: .5rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 600px){
    flex-direction: column;
    align-items: flex-end;
  }
`
export const CartSection3 = styled.div`
  text-align: right;
  height: 110%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media  (max-width: 540px){
    width: 100%;
    height: 40px;
    min-height: 20px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`
