import {initializeApp} from "firebase/app";
import {getFirestore, Firestore} from 'firebase/firestore/lite';
import {getFirestore as getFirestoreFull, Firestore as FirestoreFull} from 'firebase/firestore';

import {getFunctions} from "firebase/functions";

import {getAnalytics} from "firebase/analytics";
import {getStorage} from "firebase/storage";
import {getAuth } from "firebase/auth";

const firebaseConfig = {
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

export const firestore: Firestore  = getFirestore(app);
export const firestoreFull: FirestoreFull = getFirestoreFull(app);
export const storage = getStorage(app, "gs://wdyn7-app.appspot.com");
export const analytics = getAnalytics(app);
export const functions = getFunctions(app);
export const auth = getAuth(app);

