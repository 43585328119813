import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Availability, ContentProductsContainer, DescriptionGrid, DescriptionItem, DescriptionItemDesc, DescriptionItemTitle, DescriptionTitle, GalleryContainer, General, GeneralText, GeneralTitle, Header, ItemContainer, Location, Options, SpaceBetween, ContainerReasonToReject, LabelSalary } from './styled'
import  Price  from 'components/Price';
import { ThemeProvider } from "@mui/material";
import { theme } from "lib/theme";
import "react-image-gallery/styles/css/image-gallery.css";
// import ImageGallery from 'react-image-gallery';
import ImageGallery from "components/media-gallery";
import { MdLocationPin, MdHighlightOff, MdPending } from "react-icons/md";
import ModalUserContactInfo from './modals/modal-user-contact-info';
import { H2, P } from "ui/Text";
import { Button, BackButton } from "ui/Buttons";
import { getLanguage } from 'redux/common';
import { IClassified } from 'types';
import { userSelector } from 'redux/user';

interface IDetailsClassifiedProps {
    item: IClassified
}

const DetailsClassified = (props: IDetailsClassifiedProps) => {
    const {item} = props;
    const { t } = useTranslation();
    const userData = useSelector(userSelector)?.userData;
    const isOwner = userData?.id === item.userId;
    console.log('user data', userData);
    console.log('item detail', item);
    const navigate = useNavigate();
    const { properties, location, contactUserData } = item as any;
    const language = useSelector(getLanguage);
    const [showContactModal, setShowContactModal] = useState(false);
    
    let images: any = [];

    for (let i = 0; i < item.images.length; i++) {
        images.push({
            original: item.images[i],
            thumbnail: item.images[i],
        },)
    }

    let statuses = {
        "available": "#038A25",
        "pending": "#d0b81f",
        "inactive": "#ff0000",
    }

    return (
        <ThemeProvider theme={theme}>
            <div style={{margin: '0px 0px 12px 0px'}}>
                <BackButton 
                    onClick={() => navigate(-1)}
                />
            </div>
            <div>
                <H2>{item.title}</H2>
                <ContentProductsContainer>
                    <GalleryContainer>
                        <ImageGallery  data={images} currentSelected={0} />
                    </GalleryContainer>
                    <ItemContainer>
                        <Header>
                            <SpaceBetween>
                                {
                                    item.price ? (
                                        <Price value={item.price} />
                                    ) : item.salary ? (
                                        <div>
                                            <Price value={item.salary} />
                                            <LabelSalary>{t('salary')}</LabelSalary>
                                        </div>
                                    ) : null
                                }
                                {
                                    item.status === "approved" ? (
                                        <div style={{ width: '30%' }}>
                                            <Button onClick={() => setShowContactModal(true)}>Contactar</Button>
                                        </div>
                                    ) : null
                                }
                                </SpaceBetween>
                        </Header>
                        {/*<FullName>{item.description}</FullName>*/}
                        <SpaceBetween>
                            <Location><MdLocationPin /> {location?.label}</Location>
                            <Availability color={statuses[item.status]}>
                                {
                                    (isOwner && item.status) === "pending" ? (
                                        <>
                                            <MdPending size='1.2rem' />
                                            {t(item.status)}
                                        </>
                                    ) : null
                                }
                                {
                                    (isOwner && item.status) === "rejected" ? (
                                        <div style={{display: 'flex', gap: '.5rem', color: 'red'}}>
                                            <MdHighlightOff  size='1.2rem'/>
                                            <p style={{margin: 0}}>{t(item.status)}</p>
                                        </div>
                                    ) : null
                                }
                            </Availability>
                        </SpaceBetween>
                        {
                                (isOwner && item.status) === "rejected" ? (
                                <ContainerReasonToReject >
                                    <h6>Motivo de rechazo</h6>
                                    <GeneralText>{item.reasonToReject}</GeneralText>
                                </ContainerReasonToReject >
                                ) : null
                            }
                        <hr />
                        {
                            properties && properties.length > 0 ? (
                                <Options>
                                    <DescriptionTitle>Características</DescriptionTitle>
                                    <DescriptionGrid>
                                        {
                                            properties.map((property: any, index: number) => {
                                                return (
                                                    <DescriptionItem key={index}>
                                                        <DescriptionItemTitle>{property.key[language]}</DescriptionItemTitle>
                                                        <DescriptionItemDesc>{property.value}</DescriptionItemDesc>
                                                    </DescriptionItem>
                                                )
                                            })
                                        }
                                    </DescriptionGrid>
                                    <hr />
                                </Options>
                            ) : null
                        }

                        <General>
                            <GeneralTitle>Descripción del clasificado</GeneralTitle>
                            <P style={{
                                marginTop: '1rem',
                                fontFamily: 'Montserrat',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '1rem'
                            }}>{item.description}</P>
                        </General>
                    </ItemContainer>
                </ContentProductsContainer>
            </div>
            <ModalUserContactInfo 
                isOpen={showContactModal}
                onClose={() => setShowContactModal(false)} 
                userData={contactUserData}
            />
        </ThemeProvider>
    )
}

export default DetailsClassified
