import React, { useCallback, useMemo, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import './styles.css';
import es from 'react-phone-input-2/lang/es.json';
import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';
import * as yup from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const StyledPhoneInput = styled(PhoneInput)`
`;

interface IPhoneInputProps {
  value: string;
  onChange: (phone: string) => void;
  country?: string;
  placeholder?: string;
  enableSearch?: boolean;
  disableSearchIcon?: boolean;
  disableCountryCode?: boolean;
  disableDropdown?: boolean;
  maxLength?: number;
  disabled?: boolean;
  helperText?: string;
}

const phoneNumberValidation = (value) => {
  const phoneNumber = parsePhoneNumberFromString(value);
  return phoneNumber && phoneNumber.isValid();
};

const validationSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .required('Número de teléfono es requerido')
    .test('is-valid-phone', 'Número de teléfono no es válido', phoneNumberValidation)
});

const PhoneInputComponent: React.FC<IPhoneInputProps> = ({
  value,
  onChange,
  country = 'pa',
  placeholder,
  enableSearch,
  disableSearchIcon,
  disableCountryCode,
  disableDropdown,
  maxLength,
  disabled,
  helperText,
}) => {
  const [error, setError] = useState<string | null>(null);

  const handlePhoneChange = useCallback(
    (phone: string) => {
      if (maxLength && phone.length > maxLength) {
        return;
      } else {
        onChange(phone);
        validationSchema
          .validate({ phoneNumber: `+${phone}` })
          .then(() => setError(null))
          .catch((err) => setError(err.message));
      }
    },
    [onChange, maxLength]
  );

  const PhoneInputMemo = useMemo(
    () => (
      <StyledPhoneInput
        localization={es}
        country={country}
        preferredCountries={['pa', 'us', 'co', 'es']}
        value={value || ''}
        onChange={(phone) => {
          if (!disabled) handlePhoneChange(phone);
        }}
        inputExtraProps={{
          pattern: '[0-9]*',
        }}
        defaultMask="............"
        containerStyle={{
          width: '100%',
          height: '54px',
          backgroundColor: disabled ? '#f5f5f5' : '#fff',
          borderRadius: '10px',
          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
          border: disabled ? '2px solid rgba(145, 158, 171, 0.8)' : '2px solid blue',
          overFlow: 'hidden',
        }}
        inputStyle={{
          width: '100%',
          borderRadius: '12px',
          border: 'none',
          height: '100%',
          backgroundColor: disabled ? '#f5f5f5' : '#fff',
        }}
        buttonStyle={{
          border: 'none',
          borderTopLeftRadius: '12px',
          borderBottomLeftRadius: '12px',
        }}
        buttonClass=".button-style"
        dropdownStyle={{ width: '100%', border: '2px solid blue', backgroundColor: '#fff' }}
        searchStyle={{
          width: '100%',
          height: '54px',
          borderRadius: '10px',
          border: '2px solid blue',
        }}
        placeholder={placeholder}
        enableSearch={enableSearch}
        disableSearchIcon={disableSearchIcon}
        disableCountryCode={disableCountryCode}
        disableDropdown={disableDropdown}
        disabled={disabled}
        defaultErrorMessage={helperText}
      />
    ),
    [
      country,
      value,
      handlePhoneChange,
      placeholder,
      enableSearch,
      disableSearchIcon,
      disableCountryCode,
      disableDropdown,
      disabled,
      helperText,
    ]
  );

  return (
    <div style={{ width: '100%' }}>
      {PhoneInputMemo}
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </div>
  );
};

export default PhoneInputComponent;


// import React, { useCallback, useMemo } from 'react';
// import 'react-phone-input-2/lib/style.css';
// import './styles.css';
// import es from 'react-phone-input-2/lang/es.json';
// import PhoneInput from 'react-phone-input-2';
// import styled from 'styled-components';

// const StyledPhoneInput = styled(PhoneInput)`
// `;

// interface IPhoneInputProps {
//   value: string;
//   onChange: (phone: string) => void;
//   country?: string;
//   placeholder?: string;
//   enableSearch?: boolean;
//   disableSearchIcon?: boolean;
//   disableCountryCode?: boolean;
//   disableDropdown?: boolean;
//   maxLength?: number;
//   disabled?: boolean;
//   helperText?: string;
// }

// const PhoneInputComponent: React.FC<IPhoneInputProps> = ({
//   value,
//   onChange,
//   country = 'pa',
//   placeholder,
//   enableSearch,
//   disableSearchIcon,
//   disableCountryCode,
//   disableDropdown,
//   maxLength,
//   disabled,
//   helperText,
// }) => {
//   const handlePhoneChange = useCallback(
//     (phone: string) => {
//       if (maxLength && phone.length > maxLength) {
//         return;
//       } else {
//         onChange(phone);
//       }
//     },
//     [onChange, maxLength]
//   );

//   const PhoneInput = useMemo(
//     () => (
//       <StyledPhoneInput
//         localization={es}
//         country={country}
//         preferredCountries={['pa', 'us', 'co', 'es']}
//         value={value || ''}
//         onChange={(phone) => {
//             if (!disabled) handlePhoneChange(phone);
//         }}
//         inputExtraProps={{
//           pattern: '[0-9]*',
//         }}
//         defaultMask="............"
//         containerStyle={{
//           width: '100%',
//           height: '54px',
//           backgroundColor: disabled ? '#f5f5f5' : '#fff',
//           borderRadius: '10px',
//           boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
//           border: disabled ? '2px solid rgba(145, 158, 171, 0.8)' : '2px solid blue',
//           overFlow: 'hidden',
//         }}
//         inputStyle={{
//           width: '100%',
//           borderRadius: '12px',
//           border: 'none',
//           height: '100%',
//           backgroundColor: disabled ? '#f5f5f5' : '#fff',
//         }}
//         buttonStyle={{
//           border: 'none',
//           borderTopLeftRadius: '12px',
//           borderBottomLeftRadius: '12px',
//         }}
//         buttonClass=".button-style"
//         dropdownStyle={{ width: '100%', border: '2px solid blue', backgroundColor: '#fff' }}
//         searchStyle={{
//           width: '100%',
//           height: '54px',
//           borderRadius: '10px',
//           border: '2px solid blue',
//         }}
//         placeholder={placeholder}
//         enableSearch={enableSearch}
//         disableSearchIcon={disableSearchIcon}
//         disableCountryCode={disableCountryCode}
//         disableDropdown={disableDropdown}
//         disabled={disabled}
//         defaultErrorMessage={helperText}
//       />
//     ),
//     [
//       country,
//       value,
//       handlePhoneChange,
//       placeholder,
//       enableSearch,
//       disableSearchIcon,
//       disableCountryCode,
//       disableDropdown,
//       disabled,
//       helperText,
//     ]
//   );

//   return <div style={{ width: '100%' }}>{PhoneInput}</div>;
// };

// export default PhoneInputComponent;
