import { useState } from 'react';
import { updateUserBasicInfo } from 'services/user/updateInfo/service-update-user-info';
import { UserBasicDataFormProps } from "types/common";
import { useNotification } from 'lib/context/AlertContext';

/**
 * Custom hook para actualizar la información básica del usuario.
 *
 * @returns {object} - Objeto con propiedades y funciones para actualizar la información del usuario.
 */
const useUpdateUserBasicInfo = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean | null>(null);
  const {showError } = useNotification();

  const updateUserInfo = async (userUid: string, userInfo: UserBasicDataFormProps) => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await updateUserBasicInfo(userUid, userInfo);

      if (response.success) {
        setSuccess(true);
      } else {
        showError('Error al actualizar al usuario')
        setError(response.message || 'Failed to update user info');
      }
    } catch (error: any) {
      setError(error.message || 'An error occurred while updating user info');
      showError('Error al actualizar al usuario')
    } finally {
      setLoading(false);
    }
  };

  return {
    updateUserInfo,
    loading,
    error,
    success,
  };
};

export default useUpdateUserBasicInfo;
