import React, { useEffect, useState, useCallback } from "react";
import {
  Confirmed,
  ConfirmedImg,
  ConfirmedMsg,
  ConfirmedText,
  Container,
  Content,
  ContentHeader,
  ContentHeaderTitle,
  ListTitle,
  NeedTitle,
  Option,
  OptionImage,
  Options,
  Loading
} from "./styled";
import { useSelector } from "react-redux";
import logo from "assets/logo_white_wdyn7.png";
import { MdArrowBack, MdCheckCircle } from "react-icons/md";
import { theme } from "lib/theme";
import { ThemeProvider, createTheme } from "@mui/material";
import { collection, getDocs } from "firebase/firestore/lite";
import { firestore } from "config/firebase";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import ProductsForm from "../Forms/productsForm/products-form-need";
import ServicesForm from "../Forms/servicesForm/services-form-need";
import NeedsOthersForm from "../Forms/othersForm/others-form-need";
// import ConfirmNeed from "../ConfirmNeed/confirm-need"; // verificar si se usa en el futuro ojo
import "./index.css";
import { CreateNeedRequestProps, DBCountryReference } from "types";
import { userSelector } from "redux/user";
import { getCountry } from "redux/common";
import { DB_ROUTES } from "constants/routes";
import { useCreateNeedRequest } from "hooks/useNeeds";
import { STEPS_NEED_REQUEST } from "../constants";
import { getLanguage } from "redux/common";
import MainLayout from "views/layout/MainLayout/main-layout";
import AutoModeIcon from '@mui/icons-material/AutoMode';

import _ from "lodash";
import { BREAKPOINTS } from "constants/index";

const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
});

const NeedsCategories = () => {
  const { userData } = useSelector(userSelector);
  const location = useLocation();
  const language = useSelector(getLanguage);
  const { t } = useTranslation();
  const currentCountry = useSelector(getCountry);
  const { createNeedRequest } = useCreateNeedRequest();
  const needUid = `${_.uniqueId()}${_.random(1000, 9999)}`;

  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState<any>({});
  const { name } = location?.state;
  const navigate = useNavigate();
  const [category, setCategory] = useState(-1);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [formProperties, setFormProperties] = useState([]);
  const [needCategory, setNeedCategory] = useState<any>({});

  useEffect(() => {
    if (categories.length === 0) {
      const querySnapshot = getDocs(
        collection(
          firestore,
          `${currentCountry}${DB_ROUTES.NEEDS_CATEGORIES_COLLECTION}/${name}/Categories/`
        )
      );
      querySnapshot.then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.data().id = doc.id;

          // @ts-ignore
          setCategories((categories) => [...categories, doc.data()]);
        });
      });
    }
  }, []);

  useEffect(() => {
    if (step === 0) {
      window.scrollTo(0, 0);
    }

  }, [step]);


  function handleSelectCategory(item) {
    setNeedCategory(item);
    setPicked(item.name[language] as string);  
    setFormProperties([]);
    if (item.subCategories) {
      // @ts-ignore
      setSubcategories((subcategories) => [...item["subCategories"]]);
    }

    if (item.formProperties) {
      let keys = Object.keys(item.formProperties);

      keys.forEach((e, index) => {
        // @ts-ignore
        setFormProperties((selects) => [...selects, e]);
      });
    }
    setStep(1);
  }

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState(`${t('sending_your_need')}...`);
  const [color, setColor] = React.useState("");
  const [isSuccess, setIsSuccess] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendNeed = useCallback(
    async (propsSendNeed: CreateNeedRequestProps) => {
      setOpen(true);

      const responseCreateNeed = await createNeedRequest({
        userId: userData.uid,
        country: currentCountry as DBCountryReference,
        needData: {
          needCategory: propsSendNeed.needData.needCategory,
          needReference: propsSendNeed.needData.needReference,
          needTitle: propsSendNeed.needData.needTitle,
          needUrgencyLevel: propsSendNeed.needData.needUrgencyLevel,
          needDescription: propsSendNeed.needData.needDescription,
          needImage: propsSendNeed.needData.needImage,
        },
      });

      if (responseCreateNeed.status === "OK") {
        setColor("#038a26");
        setIsSuccess(true);
        setMessage(t('your_need_has_been_sent_successfully') as string);
        setTimeout(function () {
          setOpen(false);
          navigate("/");
        }, 4000);
      } else {
        setColor("#ff0000");
        setMessage(t('error_sending_your_need') as string);
        setIsSuccess(false);
        setTimeout(function () {
          setOpen(false);
          setColor("");
        }, 4000);
      }
    },
    []
  );

  const [picked, setPicked] = useState("");

  useEffect(() => {
    let img = document.getElementsByClassName("uploadIcon")[0];
    if (img !== undefined) {
      img["src"] =
        "https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/assets%2Ficons%2Fadd-image.png?alt=media&token=9a56282b-da2e-4e9b-ac27-89f8a5523c05";
    }
  }, [picked]);
  return (
    <ThemeProvider theme={lightTheme}>
      <MainLayout>
        <Container style={{ height: step === 3 ? "100vh" : "auto" }}>
          <Content>
            <ThemeProvider theme={theme}>
              {step !== 3 && (
                <ContentHeader
                  onClick={() =>
                    step === 0 ? navigate("/needs") : setStep(step - 1)
                  }
                >
                  <MdArrowBack size={30} color={"#3e36c2"} />
                  <ContentHeaderTitle>{t('request')}</ContentHeaderTitle>
                </ContentHeader>
              )}
              {step === STEPS_NEED_REQUEST.select_category && (
                <>
                  <ListTitle>{t('choose_a_option')}</ListTitle>
                  <Options>
                    {categories.map((item: any, index) => (
                      <Option
                        key={index}
                        onClick={() => handleSelectCategory(item)}
                        color={item["color"]}
                      >
                        <OptionImage src={item?.image} />
                        <NeedTitle>{item?.name[language]}</NeedTitle>
                      </Option>
                    ))}
                  </Options>
                </>
              )}
              {step === STEPS_NEED_REQUEST.form && name === "Services" && (
                <ServicesForm
                  subcategories={subcategories}
                  category={category}
                  setCategory={setCategory}
                  setStep={setStep}
                  onSubmit={(e) => {
                    setFormData(e);
                    handleSendNeed({
                      userId: userData.uid,
                      country: currentCountry as DBCountryReference,
                      needData: {
                        needCategory: needCategory.name[language] as string,
                        needReference: e.needReference,
                        needTitle: e.title,
                        needUrgencyLevel: e.urgencyLevel,
                        needDescription: e.extraComments,
                        needImage: e.link || null,
                      },
                    });
                  }}
                  userData={userData}
                  needUid={needUid}
                />
              )}
              {step === STEPS_NEED_REQUEST.form && name === "Products" && (
                <ProductsForm
                  subcategories={subcategories}
                  category={category}
                  setStep={setStep}
                  setCategory={setCategory}
                  onSubmit={(e) => {
                    setFormData(e);
                    handleSendNeed({
                      userId: userData.uid,
                      country: currentCountry as DBCountryReference,
                      needData: {
                        needCategory: needCategory.name[language] as string,
                        needReference: e.needReference,
                        needTitle: e.title,
                        needUrgencyLevel: e.urgencyLevel,
                        needDescription: e.extraComments,
                        needImage: e.link || null,
                      },
                    });
                  }}
                  userData={userData}
                  needUid={needUid}
                />
              )}
              {step === STEPS_NEED_REQUEST.form && name === "Others" && (
                <NeedsOthersForm setStep={setStep} onSubmit={() => { }} />
              )}
            </ThemeProvider>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title2"
              fullScreen={window.innerWidth < BREAKPOINTS.lg}
            >
              <DialogContent
                sx={{ margin: 0, padding: "1rem", background: "#3A36DB" }}
              >
                <Confirmed>
                  <ConfirmedImg src={logo} loading="lazy" />
                  <ConfirmedMsg color={color}>
                    {isSuccess ? (
                      <MdCheckCircle size={50} color='#fff' />
                    ) : (
                        <Loading>
                          <AutoModeIcon
                            sx={{ fontSize: 50, color: '#fff' }}
                          />
                        </Loading>
                    )  
                    }
                    <ConfirmedText>{message}</ConfirmedText>
                  </ConfirmedMsg>
                </Confirmed>
              </DialogContent>
            </Dialog>
          </Content>
        </Container>
      </MainLayout>
    </ThemeProvider>
  );
};

export default NeedsCategories;
