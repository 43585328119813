import { useEffect, useState } from "react";
import { uniqBy } from "lodash";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainLayout from "views/layout/MainLayout";
import { Step, Stepper } from "react-form-stepper";
import { Container, Content } from "./styled";
import { collection, getDocs, query, where } from "firebase/firestore/lite";
import { firestore } from "config/firebase";
import { useSelector, useDispatch } from "react-redux";
import { userSelector } from "redux/user";
import { totalCart} from 'redux/cart'
import { getLastStepCheckout, setLastStep, setUidCheckout, getUidCheckout} from "redux/checkout";
import { useIsDataStepOneCompleted } from "hooks/checkout/useCheckout";
import { useNotification } from "lib/context/AlertContext";
import useIsAuth from "hooks/user/useIsAuth/useIsAuth";
import useExistOrderUid from "hooks/checkout/use-verify-order-uid";
import useGenerateFirestoreId from "hooks/database/firestore/use-get-firestore-unique-id/use-get-firestore-unique-id"
// STEPS 
import StepOne from "./steps/step-one";
import StepTwo from "./steps/step-two";
import StepThree from "./steps/step-three";
import StepFour from "./steps/step-four";
import StepFive from "./steps/step-five";

const Checkout = () => {
  const isDataStepOneCompleted = useIsDataStepOneCompleted();
  const { showWarning } = useNotification();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { generateId, uid: uidOrder, loading: loadingGenerateId, error: errorGenerateId } = useGenerateFirestoreId();
  const{ verifyOrderUid} = useExistOrderUid();
  const uidCheckout = useSelector(getUidCheckout);
  const totalAmount = useSelector(totalCart);
  const isAuth = useIsAuth();

  const lastStep = useSelector(getLastStepCheckout);

  const initStep = isDataStepOneCompleted ? 1 : lastStep ||  0;

  const [step, setStep] = useState(initStep);
  const [address, setAddress] = useState([]);
  const { userData } = useSelector(userSelector);
  const [user, setUser] = useState<any>([]);
  const [finalData, setFinalData] = useState({});


  function handleSetStep (step: number) {
    dispatch(setLastStep(step));
    setStep(step);

  }

  async function handleVerifyOrderUid(uid: string) {
    
    try {
      const response = await verifyOrderUid(uid);
      if (response) {
        dispatch(setUidCheckout(null));
      }
      return response;
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    if (errorGenerateId) {
        alert('Ha ocurrido un error al generar el id de la orden');
    }

    if (uidOrder) {
        dispatch(setUidCheckout(uidOrder));
    }
}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [errorGenerateId, uidOrder]);

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
    
    if (uidCheckout) {
      handleVerifyOrderUid(uidCheckout);
    }

    if (!uidCheckout) {
      generateId({ path: 'Checkouts' });
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uidCheckout]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (step  && !totalAmount) {
      showWarning(t('your_cart_is_empty'));
      dispatch(setLastStep(0));
      setStep(0);
      if (isAuth)  {
        navigate('/cart');
      } else  {
        navigate('/');
      }
      
    }  

  }, [step]);


  useEffect(() => {
    if (user.length > 0) {
      fetchAddressData();
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const fetchData = async () => {
    if (userData) {
      const q = query(
        collection(firestore, "Users"),
        where("email", "==", userData.email)
      );
      const querySnapshot = await getDocs(q);
      const tempUsers = uniqBy( querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })), "id" );
      setUser(tempUsers);
    } else { 
      showWarning(t('complete_your_details_to_continue'));
    }
  };

  const fetchAddressData = async () => {
    const path = "Users/" + user[0]["id"] + "/Addresses";
    const querySnapshot2 = await getDocs(collection(firestore, path));
    setAddress(
      querySnapshot2.docs.map((doc) => ({ ...doc.data(), id: doc.id })) as any
    );
  };

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
  };
  
  const renderStep = {
    0: <StepOne setData={setFinalData} user={user} onSubmit={handleSetStep} />,
    1: (
      <StepTwo
        address={address}
        setData={setFinalData}
        user={user}
        onSubmit={handleSetStep}
        onBack={handleBack}
      />
    ), 
    2: (
      <StepThree setData={setFinalData} onSubmit={handleSetStep} onBack={handleBack} />
    ),
    3: <StepFour setData={(e) => {
      setFinalData(e)
    }} data={finalData} onSubmit={handleSetStep} />,
    4: <StepFive data={finalData} onSubmit={handleSetStep} />,
  };

  const StepperContainer = () => (
    <div>
      <Stepper
        activeStep={step}
        styleConfig={{
          activeBgColor: 'rgb(58, 54, 219)',
          completedBgColor: '#0089FF',
          size: '1.8rem'
        } as any}
        connectorStyleConfig={{
          size: .8,
        } as any}
      >
        <Step label={t('data') || ''} />
        <Step label={t('shipping_address') || ''} />
        <Step label={t('payment_methods') || ''} />
        <Step label={t('summary') || ''} />
      </Stepper>
    </div>
  );

  return (
    <MainLayout type="main">
      <Container>
        <Content>
          <StepperContainer />
          {renderStep[step]}
        </Content>
      </Container>
    </MainLayout>
  );
};

export default Checkout;
