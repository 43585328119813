import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Container, ContentForm, InputWrap, InputsContainer, SectionTitleWithBackground, Grid } from "../styled";
import ImagePickerV2 from "ui/ImagePickerV2"
import { Formik, Form, Field } from 'formik';
import Input from 'ui/TextInput';
import { P } from "ui/Text";
import GoogleMapInput from "ui/GoogleMapInput";
import { TextError } from "ui/Text";
import TextArea from "ui/TextArea";
import { Button } from "ui/Buttons";
import { useNotification } from "lib/context/AlertContext";
import YesOrNot from "components/YesOrNot";
import { classifiedEmploymentFormSchema} from '../main'
import { IEmploymentClassified, IFormPropertyEmploymentClassified } from "types";
import { MAX_LENGTH_DESCRIPTION, MAX_LENGTH_TITLE } from "constants/constants";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { onlyNumbers } from "utils/common";
// Icons
import { EnumClassifiedPropertyIconUrls } from 'constants/classifieds';
import { getPropertyValues } from "utils/classifieds/classifieds";
interface Props {
    category?: string;
    userUid: string;
    onSubmit?: (values: IEmploymentClassified) => void;
    createClassifiedSuccess: boolean;
    formProperties: IFormPropertyEmploymentClassified;
    language: string;
    categoryType: string;
    initialValues?: any;
}

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_WDYN_GOOGLE_MAPS_API_KEY;

const NewEmploymentForm: FC<Props> = (props: Props) => {
    const { category, onSubmit, userUid, createClassifiedSuccess, categoryType, initialValues} = props; //formProperties, language, 
    const formRef: any = useRef(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { showError } = useNotification();

    const [formErrors, setFormErrors] = useState<any>({});
    
    const initialPosition = Object.keys.length > 0 ? initialValues?.location?.value?.coordinates : { lat: 8.98251176638921, lng: -79.528036556417 };

    useEffect(() => {
        if (createClassifiedSuccess && formRef.current) {
            formRef.current.resetForm();
            setTimeout(() => {
                navigate('/classifieds');
            }, 2000);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createClassifiedSuccess]);

    const buttonText = initialValues ? t('edit_classified') : t('create_classified');

    return (
        <Container>
            <h2 style={{ color: '#000' }}>{category || ''}</h2>
            <Formik
                innerRef={formRef}
                initialValues={{
                    title: initialValues?.title || '',
                    description: initialValues?.description || '',
                    category: initialValues?.category || category,
                    location: initialValues?.location || '',
                    salary: initialValues?.salary || '',
                    isFullTime: initialValues?.isFullTime || false,
                    images: initialValues?.images || [],
                    userId: userUid,
                    categoryType: categoryType || ''
                }}
                validationSchema={classifiedEmploymentFormSchema}
                onSubmit={(values: IEmploymentClassified) => {
                    onSubmit && onSubmit(values);
                }}
            >
                {({ errors, touched, values, setFieldValue, handleSubmit, handleChange }) => (
                    <ContentForm>
                        <Form>
                            <div>
                                <ImagePickerV2
                                    maxImages={3}
                                    listImages={values?.images || [] as any}
                                    onChange={(files) => {
                                        setFieldValue('images', files);
                                    }}
                                />
                            </div>
                            <InputsContainer>
                                <SectionTitleWithBackground>{t('general_information')}</SectionTitleWithBackground>
                                <InputWrap>
                                    <Field as={Input} label={t('employment_title')} name="title" maxLength={MAX_LENGTH_TITLE} />
                                    <TextError>{touched.title && errors.title && errors.title}</TextError>
                                </InputWrap>
                                <InputWrap>
                                    <Field as={TextArea} label={t('employment_description')} name="description" maxLength={MAX_LENGTH_DESCRIPTION} />
                                    <TextError>{touched.description && errors.description && errors.description}</TextError>
                                </InputWrap>
                                <SectionTitleWithBackground>{t('employment_information')}</SectionTitleWithBackground>
                                <Grid>
                                    <InputWrap>
                                        <Field
                                            as={Input}
                                            label={t('salary')}
                                            maxLength={40}
                                            RightIcon={EnumClassifiedPropertyIconUrls.Salary}
                                            value={values.salary}
                                            onChange={(e) => { handleChange('salary')(onlyNumbers(e.target.value)); }}
                                        />
                                        <TextError>{touched.salary && errors.salary && errors.salary}</TextError>
                                    </InputWrap>
                                    <InputWrap style={{paddingLeft: '16px'}}>
                                        <YesOrNot
                                            label={t('is_full_time')}
                                            isYes={values.isFullTime} 
                                            onChange={(e) => { setFieldValue('isFullTime', e); }}
                                        />
                                        <TextError>{touched.isFullTime && errors.isFullTime && errors.isFullTime}</TextError>
                                    </InputWrap>
                                </Grid>
                                {/* <Grid columns={window.innerWidth < 768 ? 2 : 3}>                           
                                </Grid> */}
                                <SectionTitleWithBackground>{t('additional_information')}</SectionTitleWithBackground>
                                <InputWrap>
                                    <P>{t('location')}</P>
                                    <GoogleMapInput
                                        initialPosition={initialPosition}
                                        apiKey={GOOGLE_MAPS_API_KEY || ''}
                                        onChange={(e) => {
                                            setFieldValue('location', e);
                                        }}
                                        fullLocation={values?.location as any}
                                    />
                                    <TextError>{touched.location && errors.location && <div>{errors.location}</div>}</TextError>
                                </InputWrap>
                            </InputsContainer>
                            <InputsContainer style={{ marginTop: '2rem' }}>
                            <Button
                                    type="submit"
                                    onClick={() => handleSubmit()}
                                    disabled={Object.keys(errors).length > 0}
                                    onDisabledClick={() => {
                                        showError(t(`${t('please_complete_all_required_fields')
                                            }: ${Object.values(errors).map((error: any) => {
                                                return error + ', '
                                            })}
                                            `))
                                        setFormErrors(errors)
                                        console.log('errors', errors)
                                    }

                                    }
                                    IconRight={<NewspaperIcon />}
                                >
                                    {buttonText}
                                </Button>
                            </InputsContainer>
                        </Form>
                    </ContentForm>
                )}
            </Formik>
        </Container>
    );
}

export default NewEmploymentForm;
