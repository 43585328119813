import React, { useState } from "react";

import {
    AditionalComment,
    ContactMessage,
    Pedido,
    PedidoBody,
    PedidoDesc,
    PedidoHeader,
    PedidoHeaderItem,
    PedidoName,
    PedidoPrice,
    PedidosContainer,
    PedidoTotal,
    RequestBody,
    RequestDesc,
    RequestHBItemLarge,
    RequestHeader,
    RequestHeaderDesc,
    RequestHeaderPrice,
    RequestImg,
    RequestName,
    Request,
    Resume,
    SubtitleSpan,
    SumFinalText,
    Summary,
    SummarySubTitle,
    SummaryTitle,
    TransButton,
    VerticalLine,
    SummaryId
} from "../../styled";
import { useTranslation } from "react-i18next";
import { MdArrowBack } from "react-icons/md";
import { Tab, Tabs } from "@mui/material";
import { IoMdEye } from "react-icons/io";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { THEME } from "constants/theme/theme"
import { getStatusText, getUrgencyLevelText } from "utils/text-helpers";

import Skeleton from "@mui/material/Skeleton";

interface RequestsHistorialProps {
    request: any;
}

const RequestsHistorial = ({ request }: RequestsHistorialProps) => {
    const { t } = useTranslation();

    const [tabIndex, setTabIndex] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [requestSelected, setRequestSelected] = useState<any>({});

    const handleClickOpen = (item: any) => {
        setOpen(true);
        setRequestSelected(item);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };
    return (
        <>
            <p>{t("historical_recent_requests")}</p>
            <Tabs
                style={{ marginBottom: "1rem" }}
                variant="fullWidth"
                value={tabIndex}
                onChange={handleTabChange}
            >
                {/* <Tab label="Cotizaciones" /> */}
                <Tab label={t("needs")} />
            </Tabs>
            {tabIndex === 0 && (
                <PedidosContainer>
                    {request.length > 0 ? (
                        request.map((item, index) =>
                            item["type"] !== "need" ? (
                                <Pedido key={index}>
                                    <PedidoHeader>
                                        <PedidoHeaderItem>
                                            <PedidoName>{t("request")}: </PedidoName>
                                            <PedidoDesc>{item?.id}</PedidoDesc>
                                        </PedidoHeaderItem>
                                        <VerticalLine></VerticalLine>
                                        <PedidoHeaderItem>
                                            <PedidoName>{t("date")}: </PedidoName>
                                            <PedidoDesc>
                                                {new Date( item?.date?.seconds * 1000 ).toLocaleDateString()}
                                            </PedidoDesc>
                                        </PedidoHeaderItem>
                                        <VerticalLine></VerticalLine>
                                        <PedidoHeaderItem>
                                            <PedidoName>{t("status")}: </PedidoName>
                                            <PedidoDesc color={"#000"}>
                                                {getStatusText(item?.status) || "Procesando"}
                                            </PedidoDesc>
                                        </PedidoHeaderItem>
                                    </PedidoHeader>
                                    <PedidoHeaderItem>
                                        <PedidoName>{t('need')}: </PedidoName>
                                        <PedidoDesc color={THEME.colors.black80}>
                                            {item?.needTitle}
                                        </PedidoDesc>
                                    </PedidoHeaderItem>
                                    <PedidoBody>
                                        <PedidoTotal>
                                            {t('category')}: <PedidoPrice>{item?.needCategory}</PedidoPrice>
                                        </PedidoTotal>
                                        <TransButton onClick={() => handleClickOpen(item)} margin={"0"}>
                                            <IoMdEye />{t('view')}
                                        </TransButton>
                                    </PedidoBody>
                                </Pedido>
                            ) : (
                                ""
                            )
                        )
                    ) : (
                        <Pedido>
                            <Skeleton variant={"rounded"} width={"100%"} height={300} />
                        </Pedido>
                    )}
                </PedidosContainer>
            )}

            {tabIndex === 1 && (
                <PedidosContainer>
                    {request.length > 0 ? (
                        request.map((item, index) =>
                            item["type"] === "need" ? (
                                <Pedido key={index}>
                                    <PedidoHeader>
                                        <PedidoHeaderItem>
                                            <PedidoName>{t('request')}: </PedidoName>
                                            <PedidoDesc>{123}</PedidoDesc>
                                        </PedidoHeaderItem>
                                        <VerticalLine></VerticalLine>
                                        <PedidoHeaderItem>
                                            <PedidoName>{t('date')}: </PedidoName>
                                            <PedidoDesc>
                                                {new Date(
                                                    item["date"]["seconds"] * 1000
                                                ).toLocaleDateString()}
                                            </PedidoDesc>
                                        </PedidoHeaderItem>
                                        <VerticalLine></VerticalLine>
                                        <PedidoHeaderItem>
                                            <PedidoName>{t('status')}: </PedidoName>
                                            <PedidoDesc color={"#038A25"}>
                                                {getStatusText(item?.status) || "Procesando"}
                                            </PedidoDesc>
                                        </PedidoHeaderItem>
                                    </PedidoHeader>
                                    <PedidoBody>
                                        <PedidoTotal>
                                            {t('category')}: <PedidoPrice>{1234}</PedidoPrice>
                                        </PedidoTotal>
                                        <TransButton onClick={() => handleClickOpen(item)} margin={"0"}>
                                            <IoMdEye /> {t('view')}
                                        </TransButton>
                                    </PedidoBody>
                                </Pedido>
                            ) : (
                                ""
                            )
                        )
                    ) : (
                        <Pedido>
                            <Skeleton variant={"rounded"} width={"100%"} height={300} />
                        </Pedido>
                    )}
                </PedidosContainer>
            )}

            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title2"
            >
                <DialogTitle onClick={handleClose} id="responsive-dialog-title2">
                    {<MdArrowBack />}
                </DialogTitle>
                <DialogContent>
                    <Summary>
                        <SummaryTitle>{t("need_detail")}
                            <SummaryId>
                                #{requestSelected?.id}
                            </SummaryId>
                        </SummaryTitle>
                        <SummarySubTitle>
                            {t("date")}:
                            {new Date(
                                requestSelected?.date?.seconds * 1000
                            ).toLocaleDateString()}
                            &nbsp;&nbsp;&nbsp;&nbsp; {t("status")}:
                            <SubtitleSpan>
                                {
                                    getStatusText(requestSelected?.status) || "Procesando"
                                }
                            </SubtitleSpan>
                        </SummarySubTitle>
                        <Request>
                            <RequestHeader>
                                {requestSelected.needImage && <RequestImg src={requestSelected.needImage} />}
                                <RequestHeaderDesc>
                                    {requestSelected.needTitle}
                                </RequestHeaderDesc>
                                {tabIndex === 0 && (
                                    <RequestHeaderPrice>{requestSelected.needCategory}</RequestHeaderPrice>
                                )}
                            </RequestHeader>
                            <RequestBody>
                                {tabIndex === 0 && (
                                    <>
                                        {/* <RequestHBItem>
                                            <RequestName>Cantidad: </RequestName>
                                            <RequestDesc>5 días</RequestDesc>
                                        </RequestHBItem>
                                        <VerticalLine></VerticalLine>*/}
                                        <RequestHBItemLarge>
                                            <RequestName>{t("need_description")}: </RequestName>
                                            <RequestDesc>{requestSelected?.needTitle}</RequestDesc>
                                        </RequestHBItemLarge>
                                        <RequestHBItemLarge>
                                            <RequestName>{t("urgency_level")}: </RequestName>
                                            <RequestDesc>{getUrgencyLevelText(requestSelected?.needUrgencyLevel)}</RequestDesc>
                                        </RequestHBItemLarge>
                                        <VerticalLine></VerticalLine>
                                    </>
                                )}
                            </RequestBody>
                        </Request>


                        {tabIndex === 1 && (
                            <Resume>
                                <SumFinalText>{t('additional_comments')}</SumFinalText>
                                <hr />
                                <AditionalComment>{t('this_is_an_additional_comment_from_the_customer_regarding_this_service_request')}</AditionalComment>
                            </Resume>
                        )}
                        <ContactMessage>{t('this_is_an_additional_comment_from_the_customer_regarding_this_service_request')}</ContactMessage>
                    </Summary>
                </DialogContent>
                <DialogActions>
                    <TransButton onClick={handleClose}>{t('exit')}</TransButton>
                </DialogActions>
            </Dialog>
        </>
    );
};


export default RequestsHistorial;