import { Container, FlexColumn, SponsorBuy, Titles, Link } from "./styled";
import Carousel from "react-multi-carousel";
import ExtraCard from "components/cards/services/extraServices";
import Skeleton from "@mui/material/Skeleton";
//import ModalSelector from "modules/services/pages/detail/components/modal-selector/modal-selector";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setServiceDetail } from "modules/services/store";
import { ServiceDetail } from "modules/services/store";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";


interface ExtraServicesYachtsProps {
  data: any;
  title: string;
  backgroundColor: string;
  hasLink: boolean;
  hasRedirectWhatsApp?: boolean;
  hasRedirectToDetail?: boolean;
}

const ExtrasServicesContainer: FC<ExtraServicesYachtsProps> = ({
  data,
  title,
  backgroundColor,
  hasLink,
  hasRedirectWhatsApp,
  hasRedirectToDetail,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const ItemSkeletons = [1, 2, 3, 4];
  const [showContactModal, setShowContactModal] = useState(false);
  const [subService, setSubService] = useState({} as ServiceDetail);

  const handleClick = (subService: ServiceDetail) => {
    if (hasRedirectWhatsApp) setShowContactModal(true);
    if (hasRedirectToDetail) navigate(`detail?id=${subService.uid}`);
    dispatch(setServiceDetail(subService));
    setSubService(subService);
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1023, min: 500 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 2,
    },
  };

  return (
    <Container background={backgroundColor} pav="1rem" pah="1rem">
      <FlexColumn background={backgroundColor} pav={"2rem"}>
        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
          <Titles alignM="center" size="1.5rem">
            {title}
          </Titles>
        </div>
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={2000}
          arrows={false}
        >
          {data !== null
            ? data.map((subService, index) => (
              <div style={{ marginBottom: "2rem" }} key={index}>
                <ExtraCard
                  data={subService}
                  onClick={() => handleClick(subService)}
                  textButton={`${t('know_more')}`}
                />
              </div>
            ))
            : ItemSkeletons.map((item) => (
              <div key={item}>
                <div>
                  <Skeleton variant="rounded" width="95%" height={200} />
                  <Skeleton variant="text" width="20%" />
                  <Skeleton variant="text" width="60%" />
                  <Skeleton variant="text" width="100%" />
                  <SponsorBuy>
                    <Skeleton variant="rounded" width="25%" height={25} />
                    <Skeleton variant="rounded" width="25%" height={25} />
                  </SponsorBuy>
                </div>
              </div>
            ))}
        </Carousel>
        {hasLink && (
          <div className="my-3">
            <Link href="">{t('ver_todas')}</Link>
          </div>
        )}
      </FlexColumn>
      {/* <ModalSelector
        docData={subService}
        showContactModal={showContactModal}
        setShowContactModal={setShowContactModal}
        extraServiceModalTitle={title}
        language={language}
      /> */}
    </Container>
  );
};

export default ExtrasServicesContainer;
