import { Container, Image } from './styled'
import { P } from '../Text';

const loader = require('assets/loader.gif');

const Loader = () => {  

    return (
        <Container>
            <Image src={loader} loading="lazy" alt="loader QUENECESITAS7" />
            <P color='#000' margin='10px 0px'>Cargando...</P>
        </Container>
    );
    };

export default Loader;