import React, { useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';

type DrawerSide = 'left' | 'right';

interface SideDrawerProps {
    buttonLabel?: string;
    side: DrawerSide;
    content: React.ReactNode;
    width?: string | number;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

const SideDrawer: React.FC<SideDrawerProps> = ({
    buttonLabel = 'Open',
    side,
    content,
    width,
    isOpen,
    setIsOpen = () => {},
}) => {

    return (
        <div>
            <Drawer
                anchor={side}
                open={isOpen}
                onClose={() => setIsOpen(false)}
                sx={{
                    borderRadius: '18px',
                }}
            >
                <Box
                    sx={{ 
                        width: width ? width : '75vw',
                        maxWidth: '400px',
                        height: '100%',
                        padding: '0rem 1rem 0rem 1rem',
                        backgroundColor: '#fafafa'
                    }}
                    role="presentation"
                >
                    {content}
                </Box>
            </Drawer>
        </div>
    );
};

export default SideDrawer;
