import Destinos1 from 'assets/destinos (1).png';
import Destinos2 from 'assets/destinos (2).png';
import Destinos3 from 'assets/destinos (3).png';
import Destinos4 from 'assets/destinos (4).png';

export const OPTIONS_FORM_MARKETING = [
    'Branding',
    'Publicidad Exterior',
    'Marketing Digital',
    'Desarrollo Web',
    'Creación de Contenido',
    'Manejo de Redes Sociales',
    'Diseño Gráfico',
]

export enum MarketingTypeCard {
    general = 'general',
    plans = 'marketingPlans',
    extras = 'marketingExtraServices',
}

export const DATA = [
    {
        image: Destinos1,
        name: {
            es: "Pasa día isla Taboga",
            en: "TABOGA ISLAND Day Trip"
        },
        shortDescription: {
            es: "Sea comercial o residencial te podemos ayudar con todo lo referente a tu propiedad",
            en: "Whether commercial or residential, we can help you with everything related to your property."
        }
    },
    {
        image: Destinos2,
        name: {
            es: "Pasa día isla Taboga",
            en: "ARCHIPIELAGO DE LAS PERLAS Day Trip"
        },
        shortDescription: {
            es: "Sea comercial o residencial te podemos ayudar con todo lo referente a tu propiedad",
            en: "Whether commercial or residential, we can help you with everything related to your property."
        }
    },
    {
        image: Destinos3,
        name: {
            es: "Pasa día isla Taboga",
            en: "BAHIA DE PANAMA Day Trip"
        },
        shortDescription: {
            es: "Sea comercial o residencial te podemos ayudar con todo lo referente a tu propiedad",
            en: "Whether commercial or residential, we can help you with everything related to your property."
        }
    }, {
        image: Destinos4,
        name: {
            es: "Pasa día isla Taboga",
            en: "PUENTE DE LAS AMERICAS Day Trip"
        },
        shortDescription: {
            es: "Sea comercial o residencial te podemos ayudar con todo lo referente a tu propiedad",
            en: "Whether commercial or residential, we can help you with everything related to your property."
        }
    },
];

export const DATA_MOCK = [
    {
        image: "https://cdn.pixabay.com/photo/2016/07/30/00/03/winding-road-1556177_1280.jpg",
        price: 34900,
        dimensions: "7,015.82 mts2",
        location: "Provincia de Coclé",
        title: {
            es: "Lote Oportunidad ubicado en el retiro, Antón, provincia de Coclé",
            en: "Opportunity Lot located in Retiro, Anton, province of Cocle"
        },
    },
    {
        image: "https://cdn.pixabay.com/photo/2016/07/30/00/03/winding-road-1556177_1280.jpg",
        price: 34900,
        dimensions: "7,015.82 mts2",
        location: "Provincia de Coclé",
        title: {
            es: "Lote Oportunidad ubicado en el retiro, Antón, provincia de Coclé",
            en: "Opportunity Lot located in Retiro, Anton, province of Cocle"
        },
    },
    {
        image: "https://cdn.pixabay.com/photo/2016/07/30/00/03/winding-road-1556177_1280.jpg",
        price: 34900,
        dimensions: "7,015.82 mts2",
        location: "Provincia de Coclé",
        title: {
            es: "Lote Oportunidad ubicado en el retiro, Antón, provincia de Coclé",
            en: "Opportunity Lot located in Retiro, Anton, province of Cocle"
        },
    },
    {
        image: "https://cdn.pixabay.com/photo/2016/07/30/00/03/winding-road-1556177_1280.jpg",
        price: 34900,
        dimensions: "7,015.82 mts2",
        location: "Provincia de Coclé",
        title: {
            es: "Lote Oportunidad ubicado en el retiro, Antón, provincia de Coclé",
            en: "Opportunity Lot located in Retiro, Anton, province of Cocle"
        },
    },
    {
        image: "https://cdn.pixabay.com/photo/2016/07/30/00/03/winding-road-1556177_1280.jpg",
        price: 34900,
        dimensions: "7,015.82 mts2",
        location: "Provincia de Coclé",
        title: {
            es: "Lote Oportunidad ubicado en el retiro, Antón, provincia de Coclé",
            en: "Opportunity Lot located in Retiro, Anton, province of Cocle"
        },
    },
    {
        image: "https://cdn.pixabay.com/photo/2016/07/30/00/03/winding-road-1556177_1280.jpg",
        price: 34900,
        dimensions: "7,015.82 mts2",
        location: "Provincia de Coclé",
        title: {
            es: "Lote Oportunidad ubicado en el retiro, Antón, provincia de Coclé",
            en: "Opportunity Lot located in Retiro, Anton, province of Cocle"
        },
    }
];
