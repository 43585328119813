
import Modal from 'ui/Modal'
import { Grid, KeyItemGrid, LabelKeyItemGrid, ValueKeyItemGrid } from './styled'
import { H3 } from 'ui/Text'
import { Row } from 'ui/Common/common-components'
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import SmartphoneIcon from '@mui/icons-material/Smartphone';

interface IUserContactInfo {
    firstName: string | null,
    firstSurname: string | null,
    email: string | null,
    phoneNumber: string | null,
}

interface IModalUserContactInfoProps {
    isOpen: boolean,
    onClose: () => void
    userData: IUserContactInfo
}

const ModalUserContactInfo = (props: IModalUserContactInfoProps) => {
    const { isOpen, onClose, userData } = props;
    console.log(userData)

    return (
        
        <Modal styleContent={{minWidth: '50vw'}} isOpen={isOpen} onClose={onClose}>
            <div>
                
                <H3 style={{ marginBottom: '1rem' }}>Información de contacto</H3>
                <hr />
                <Grid>
                    <Row alignItems='center'>
                        <KeyItemGrid >
                            <PersonIcon style={{ marginRight: '0.5rem' }} />
                            <LabelKeyItemGrid>Nombre</LabelKeyItemGrid>
                        </KeyItemGrid>
                        <ValueKeyItemGrid> {userData?.firstName} {userData?.firstSurname}</ValueKeyItemGrid>
                    </Row>
                    <Row alignItems='center'>
                        <KeyItemGrid >
                            <EmailIcon style={{ marginRight: '0.5rem' }} />
                            <LabelKeyItemGrid>Email</LabelKeyItemGrid>
                        </KeyItemGrid>
                        <ValueKeyItemGrid>{userData?.email}</ValueKeyItemGrid>
                    </Row>
                    <Row alignItems='center'>
                        <KeyItemGrid >
                            <SmartphoneIcon style={{ marginRight: '0.5rem' }} />
                            <LabelKeyItemGrid>Teléfono</LabelKeyItemGrid>
                        </KeyItemGrid>
                        <ValueKeyItemGrid>{userData?.phoneNumber}</ValueKeyItemGrid>
                    </Row>
                </Grid>
            </div>
        </Modal>
    )
}

export default ModalUserContactInfo