import { httpsCallable,  } from "firebase/functions";
import {ServiceResponse} from 'types'
import {functions} from 'config/firebase'
import {getCardType} from './utils'

interface AuthAndCapturePaguelofacil {
    firstName: string
    firstSurname: string
    cardNumber: string
    expirationYear: string
    expirationMonth: string
    cvv: string
    amount: number
    description: string
    taxAmount: number,
    email: string,
    phone: string,
    concept: string,
    lang: string
}

const authAndCapturePaguelofacil = async ({firstName,firstSurname ,cardNumber, expirationYear, expirationMonth, cvv, amount, taxAmount, email, phone, description, concept, lang  }: AuthAndCapturePaguelofacil): Promise<ServiceResponse> => {

    const cardData = {
        firstName,
        firstSurname,
        cardNumber,
        expMonth: expirationMonth,
        expYear: expirationYear,
        cvv,
        cardType: getCardType(cardNumber).toUpperCase(),
      };
    
      const billData = {
        amount,
        taxAmount,
        email,
        phone,
        description,
        concept,
        lang: lang || 'es',
      };

  try{
    const authCapturePaguelofacil = httpsCallable(functions, 'authCapturePaguelofacilTest'); // httpsCallable(functions, 'authCapturePaguelofacil');
    const result: any = await authCapturePaguelofacil({cardData, billData})
    return { status: 'OK', data: result.data }
  }
  catch(err: any){
    return { status: 'ERROR', message: err.message, data: {} }
  }

}

export default authAndCapturePaguelofacil 
