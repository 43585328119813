
import { theme } from "lib/theme";
import {createTheme,} from "@mui/material";

export const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
});

export const styleAccordion = {
    width: '100%',
    marginBottom: '1rem',
    boxShadow: 'none',
    borderRadius: '12px',
    border: `2px solid ${theme.colors.primary100}`,
}

export const stylesMainSidebarComponent = {
    background: 'transparent',
}

export const MIN_DISTANCE = 1;