import { useState } from "react";
import { useCallback } from "react";
import { Container, Row, DataCardContainer } from "./styled";
import Checkbox from "ui/Checkbox";
import { PayPalButtons } from "@paypal/react-paypal-js"
import ModalLoader from "ui/ModalLoader/modal-loader";
import { useNotification } from "lib/context/AlertContext";
import { useTranslation } from "react-i18next";
import {PaypalPaymentMethodProps} from "./main";
import { IPaypalResponse } from "types/checkout"
import './style.css'

const Paypal = (props: PaypalPaymentMethodProps) => {
  const { showSuccess, showError } = useNotification();
  const {
    onBeginPayment,
    onSuccessfulPayment,
    onFailedPayment,
    infoTransaction,
    setChecked,
    checked,
  } = props;
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  // HANDLERS

  const handleCreateOrder = useCallback((data: any, actions: any) => {
    setLoading(true);

    if (infoTransaction?.amount) {
      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: infoTransaction.amount,
            },
          },
        ],
      });
    } else {
      showError(t('error_processing_payment'));
    }
  }

  , []);

  const handleApprove = useCallback((data: any, actions: any) => {
    return actions.order.capture().then(function (details: IPaypalResponse) {
      // Show a success message to your buyer
      if (details.status === 'COMPLETED') {
        showSuccess(t('payment_successful_thank_you'));
        onSuccessfulPayment && onSuccessfulPayment({...details} as any);
      } else {
        showError(t('error_processing_payment'));
        onFailedPayment && onFailedPayment();
      }
      setLoading(false);

    });
  }
  , []);

  const handleCancel = useCallback((data: any, actions: any) => {
    setLoading(false);
  }, []);

  const handleError = useCallback((err: any) => {
    console.error('error paypal method', err)
    setLoading(false);
  }
  , []);

  return (
    <Container>
      <form>
        <Row justifyContent="space-between">
          <Checkbox
            checked={checked}
            label="Paypal"
            onClick={setChecked}
          />
          <img
            style={{ width: '40px', height: '50px', objectFit: 'contain' }}
            src='https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Flogos%2Fpayment%2Flogo%20paypal.png?alt=media&token=db245c15-0b11-48f5-a2ab-f9ae81da114f'
            loading="lazy" alt="Paypal logo" title="Paypal logo"
          />
        </Row>
        {checked && (
          <DataCardContainer>
              <PayPalButtons 
                style={{ layout: 'horizontal', disableMaxWidth: true }} 
                className="paypal-button"
                createOrder={handleCreateOrder}
                onApprove={handleApprove}
                onCancel={handleCancel}
                onError={handleError}
                onInit={() => onBeginPayment && onBeginPayment()}
              />
          </DataCardContainer>
        )}
      </form>
      <ModalLoader isLoading={loading} />
    </Container>
  );
};

export default Paypal;