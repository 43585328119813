import { useReducer } from "react";
import { Amount, CarouselClassifiedsContainer, ContentClassifiedsContainer, Filter, LinkMore, ListClassifiedContainer, SpaceBetween, TextFilter, TitlesLink} from './styled'
import { MdFilter } from "react-icons/md";
import { ThemeProvider, createTheme } from "@mui/material";
import { theme } from "lib/theme";
import { BannerBrowser } from "../../Banners/BannerBrowser";
import CarouselGeneral from "components/carousel-general";
import Offers from "ui/Offers";
import Skeleton from "@mui/material/Skeleton";
import Carousel from "react-multi-carousel";
import { useTranslation } from "react-i18next";


const ContentOffers = (props: any) => {
    const { data } = props;
    const lightTheme = createTheme({
        palette: {
            mode: 'light',
        },
    });

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };
    const ItemSkeletons = [1, 2, 3, 4, 5, 6];
    const [, dispatchOpen] = useReducer((prev) => !prev, false);
    const { t } = useTranslation();

    return (
        <div>
            <ThemeProvider theme={theme}>
                <Filter>
                    <Amount>+1500 {t('offers')}</Amount>
                    <TextFilter onClick={dispatchOpen}><MdFilter /> {t('filter')}</TextFilter>
                </Filter>
                <ContentClassifiedsContainer>
                    <ListClassifiedContainer> <SpaceBetween>
                        <TitlesLink>{t('all_offers')}</TitlesLink>
                        <LinkMore>{t('view_more')}</LinkMore>
                    </SpaceBetween>

                        <CarouselClassifiedsContainer>

                            {
                                data.length > 0 ? <CarouselGeneral items={data} renderItem={Offers}
                                    itemsToShow={4} itemsTablet={2} itemsMobile={1} /> :
                                    (
                                        <Carousel
                                            swipeable
                                            draggable
                                            showDots={false}
                                            responsive={responsive}
                                            ssr={true}
                                            infinite={true}
                                            autoPlay={true}
                                            autoPlaySpeed={3000}
                                            keyBoardControl={true}
                                            transitionDuration={500}
                                            containerClass="carousel-container"
                                            itemClass="carousel-item-padding-40-px"
                                            arrows={false} // Añade esta línea para ocultar los botones de siguiente y anterior
                                        >
                                            {ItemSkeletons.map((item) => (
                                                <div key={item}>
                                                    <ThemeProvider theme={lightTheme}>
                                                        <Skeleton variant={"rounded"} height={200} width={"90%"} style={{ margin: "0 auto" }} />
                                                        <div style={{ width: "90%", margin: "0 auto" }}>
                                                            <Skeleton variant={"text"} width={"20%"} />
                                                            <Skeleton variant={"text"} width={"60%"} />
                                                            <Skeleton variant={"text"} width={"30%"} />
                                                        </div>
                                                    </ThemeProvider>
                                                </div>
                                            ))}
                                        </Carousel>
                                    )
                            }

                        </CarouselClassifiedsContainer>
                    </ListClassifiedContainer>
                    <ListClassifiedContainer color={"#FDFDFE"} pad={"1rem .4rem 0 .4rem"}>
                        <SpaceBetween>
                            <TitlesLink>{t('sponsored_offers')}</TitlesLink>
                            <LinkMore>{t('ver_todas')}</LinkMore>
                        </SpaceBetween>
                        <CarouselClassifiedsContainer>
                            {data.length > 0 ?
                                <CarouselGeneral items={data} renderItem={Offers}
                                    itemsToShow={4} itemsTablet={2} itemsMobile={1} />
                                :
                                (<Carousel
                                    swipeable
                                    draggable
                                    showDots={false}
                                    responsive={responsive}
                                    ssr={true}
                                    infinite={true}
                                    autoPlay={true}
                                    autoPlaySpeed={3000}
                                    keyBoardControl={true}
                                    transitionDuration={500}
                                    containerClass="carousel-container"
                                    itemClass="carousel-item-padding-40-px"
                                    arrows={false} // Añade esta línea para ocultar los botones de siguiente y anterior
                                >
                                    {ItemSkeletons.map((item) => (
                                        <div key={item}>
                                            <ThemeProvider theme={lightTheme}>
                                                <Skeleton variant={"rounded"} height={200} width={"90%"} style={{ margin: "0 auto" }} />
                                                <div style={{ width: "90%", margin: "0 auto" }}>
                                                    <Skeleton variant={"text"} width={"20%"} />
                                                    <Skeleton variant={"text"} width={"60%"} />
                                                    <Skeleton variant={"text"} width={"30%"} />
                                                </div>
                                            </ThemeProvider>
                                        </div>
                                    ))}
                                </Carousel>
                                )
                            }
                        </CarouselClassifiedsContainer>
                    </ListClassifiedContainer>
                    <ListClassifiedContainer color={"#FDFDFE"} pad={"1rem .4rem 0 .4rem"}>
                        <SpaceBetween>
                            <TitlesLink>{t('the_most_offers')}</TitlesLink>
                            <LinkMore>{t('view_more')}</LinkMore>
                        </SpaceBetween>
                        <CarouselClassifiedsContainer>
                            {data.length > 0 ?
                                <CarouselGeneral items={data} renderItem={Offers}
                                    itemsToShow={4} itemsTablet={2} itemsMobile={1} />
                                :
                                (
                                    <Carousel
                                        swipeable
                                        draggable
                                        showDots={false}
                                        responsive={responsive}
                                        ssr={true}
                                        infinite={true}
                                        autoPlay={true}
                                        autoPlaySpeed={3000}
                                        keyBoardControl={true}
                                        transitionDuration={500}
                                        containerClass="carousel-container"
                                        itemClass="carousel-item-padding-40-px"
                                        arrows={false} // Añade esta línea para ocultar los botones de siguiente y anterior
                                    >
                                        {ItemSkeletons.map((item) => (
                                            <div key={item}>
                                                <ThemeProvider theme={lightTheme}>
                                                    <Skeleton variant={"rounded"} height={200} width={"90%"} style={{ margin: "0 auto" }} />
                                                    <div style={{ width: "90%", margin: "0 auto" }}>
                                                        <Skeleton variant={"text"} width={"20%"} />
                                                        <Skeleton variant={"text"} width={"60%"} />
                                                        <Skeleton variant={"text"} width={"30%"} />
                                                    </div>
                                                </ThemeProvider>
                                            </div>
                                        ))}
                                    </Carousel>
                                )
                            }

                        </CarouselClassifiedsContainer>
                    </ListClassifiedContainer>
                    <BannerBrowser />
                </ContentClassifiedsContainer>
            </ThemeProvider>
        </div>

    )
}

export default ContentOffers
