import { useSelector } from 'react-redux';
import { itemsCart } from 'redux/cart'


interface Language {
    en: string;
    es: string;
}

interface CartItem {
    id: string;
    name: Language;
    price: string;
    images: string[];
    count: number;
}

const useGetProductInCart = () => {
  const cartItems = useSelector(itemsCart);

  const getProduct = (idProduct: number | string): CartItem | null => {
    const product = cartItems.find((item: CartItem) => item.id == idProduct);
    return product || null;
  }

  return getProduct;
};

export default useGetProductInCart;
