import { useState, useCallback } from 'react';
import { collection, getDocs, query, where, DocumentData, orderBy, } from "firebase/firestore/lite";
import { WhereFilterOp, FieldPath } from "firebase/firestore";
import { firestore } from 'config/firebase'

interface FirestoreDoc extends DocumentData {
}

interface Filter {
  field: string;
  operator: WhereFilterOp;
  value: string | number | boolean | null | FieldPath | number[];
}

interface OrderBy {
  field?: string;
  direction?: 'asc' | 'desc';
}

interface FetchOptions {
  path: string;
  filters?: Filter[];
  orderFilter?: OrderBy;
}

export const useGetFirestoreCollection = () => {
  const [loadingGetFirestoreCollection, setLoading] = useState<boolean>(true);
  const [errorGetFirestoreCollection, setError] = useState<Error | null>(null);
  const [collectionData, setCollectionData] = useState<FirestoreDoc[] | null>(null);

  const fetchCollection = useCallback(async (options: FetchOptions) => {
    setLoading(true);
    setError(null);

    try {
      const { path, filters = [], orderFilter } = options;
      const db = firestore;

      let firestoreQuery = collection(db, path);

      filters.forEach((filter: Filter) => {
        const { field, operator, value } = filter;
        firestoreQuery = query(firestoreQuery, where(field, operator, value)) as any;
      });

      if (orderFilter?.field && orderFilter.direction) {
        const { field, direction } = orderFilter;
        firestoreQuery = query(firestoreQuery, orderBy(field, direction)) as any;
      }

      const querySnapshot = await getDocs(firestoreQuery);

      const data = querySnapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id } as FirestoreDoc));
      setCollectionData(data);

      setLoading(false);
    } catch (error: any) {
      setError(error);
      setLoading(false);
    }
  }, []);

  function clearCollectionData() {
    setCollectionData(null);
  }


  return { loadingGetFirestoreCollection, errorGetFirestoreCollection, collectionData, fetchCollection, clearCollectionData };
};
