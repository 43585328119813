import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import {
    FormWrapper,
    ButtonWrapper,
    Section,
    ContentWrapper,
    TextWrapper,
    FieldWrapper,
    H1,
} from './styled';
import { H2 } from "ui/Text";
import { useTranslation } from 'react-i18next';
import { useNotification } from 'lib/context/AlertContext';
import Input from 'ui/TextInput';
import Select from 'ui/Select';
import { Button } from "ui/Buttons";
import { useSubmitFormEarnMoney } from 'hooks/modules/earn-money/use-submit-form-earn-money';

const OPTIONS_SELECT = [
    { value: 'Invierte en productos', label: 'Invierte en productos' },
    { value: 'Vendedor asociado de productos', label: 'Vendedor asociado de productos' },
    { value: 'Vendedor asociado de servicios', label: 'Vendedor asociado de servicios' },
    { value: 'Refiere y gana', label: 'Refiere y gana' },
];

function getValidationSchema(translator) {
    const FormSchema = Yup.object().shape({
        name: Yup.string()
            .required(translator('please_enter_your_name'))
            .matches(/^[a-zA-Z\s]+$/, translator('name_can_only_contain_letters_and_spaces'))
            .min(2, translator('name_must_be_at_least_2_characters'))
            .max(50, translator('name_can_be_at_most_50_characters')),
        lastName: Yup.string()
            .required(translator('please_enter_your_last_name'))
            .matches(/^[a-zA-Z\s]+$/, translator('name_can_only_contain_letters_and_spaces'))
            .min(2, translator('name_must_be_at_least_2_characters'))
            .max(50, translator('name_can_be_at_most_50_characters')),
        phone: Yup.string()
            .required(translator('please_enter_your_phone_number'))
            .matches(/^\+?(\d{3,4}[-\s]?){2,4}\d{3,4}$/, translator('phone_number_must_be_between_10_and_15_digits')),
        email: Yup.string()
            .email(translator('invalid_email_format'))
            .required(translator('please_enter_your_email_address'))
            .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, translator('invalid_email_format')),
        businessModel: Yup.string().required(translator('please_choose_a_business_model')),
    });

    return FormSchema;
}

const FormEarnMoney = () => {
    const { t } = useTranslation();
    const { showSuccess, showError } = useNotification();
    const { submitForm } = useSubmitFormEarnMoney();

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            await submitForm(values);
            showSuccess(t('the_form_was_submitted_successfully'));
            resetForm();
        } catch (error) {
            showError(t('error_submitting_form'));
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Section>
            <ContentWrapper>
                <H1>{t('open_your_account_with_us_and_start_generating_extra_income')}</H1>
            </ContentWrapper>

            <FormWrapper>
                <TextWrapper>
                    <H2>{t('receive_personalized_attention')}</H2>
                </TextWrapper>
                <Formik
                    initialValues={{
                        name: '',
                        lastName: '',
                        phone: '',
                        email: '',
                        businessModel: '', // Initial value should be an empty string
                    }}
                    validationSchema={getValidationSchema(t)}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting, setFieldValue, handleSubmit }) => (
                        <Form>
                            <FieldWrapper>
                                <Field
                                    as={Input}
                                    type="text"
                                    placeholder={t('name')}
                                    id="name"
                                    name="name"
                                    label={t('name')}
                                    backgroundWhite
                                />
                                <ErrorMessage name="name" component="div" />
                            </FieldWrapper>
                            <FieldWrapper>
                                <Field
                                    as={Input}
                                    type="text"
                                    placeholder={t('last_name')}
                                    id="lastName"
                                    name="lastName"
                                    label={t('last_name')}
                                    backgroundWhite
                                />
                                <ErrorMessage name="lastName" component="div" />
                            </FieldWrapper>
                            <FieldWrapper>
                                <Field
                                    as={Input}
                                    type="tel"
                                    placeholder={t('phone')}
                                    id="phone"
                                    name="phone"
                                    label={t('phone')}
                                    backgroundWhite
                                />
                                <ErrorMessage name="phone" component="div" />
                            </FieldWrapper>
                            <FieldWrapper>
                                <Field
                                    as={Input}
                                    type="email"
                                    placeholder={t('email')}
                                    id="email"
                                    name="email"
                                    label={t('email')}
                                    backgroundWhite
                                />
                                <ErrorMessage name="email" component="div" />
                            </FieldWrapper>
                            <FieldWrapper>
                                <Field
                                    as={Select}
                                    id="businessModel"
                                    name="businessModel"
                                    label={t('business_model')}
                                    placeholder={t('choose_a_business_model')}
                                    options={OPTIONS_SELECT}
                                    onChange={(selectedOption) => {
                                        setFieldValue('businessModel', selectedOption); // Set the value of businessModel correctly
                                    }}
                                />
                                <ErrorMessage name="businessModel" component="div" />
                            </FieldWrapper>
                            <ButtonWrapper>
                                <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    onClick={handleSubmit}
                                >
                                    {t('send')}
                                </Button>
                            </ButtonWrapper>
                        </Form>
                    )}
                </Formik>
            </FormWrapper>
        </Section>
    );
};

export default FormEarnMoney;
