import styled from "@emotion/styled";
import { THEME } from "constants/theme/theme";

export const ContainerImage = styled.div`
    width: 100%;
    overflow: hidden;
`;

export const Img = styled.img`
    width: 100%;
    height: 90%;
    min-height: 300px;
    object-fit: contain;
    max-height: 75vh;

    /* margin-bottom: .5rem;  */
`;

// Video

export const VideosContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: .2rem;
    padding-bottom: .7rem;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    //background-color: #FAFAFA;
    //border: 1px solid ${THEME.colors.black100};
    /* border-radius: 8px; */
    //margin-top: 1rem;
    
    @media only screen and (max-width: 1200px) {
        overflow-x: scroll;
    }

    &:hover {
        cursor: pointer;
       // background-color: ${THEME.colors.black200};
       // color: ${THEME.colors.white};
    }

`;

export const SwipperContainer = styled.div`
    width: 90vw;
    height: 100%;
    display: flex;
    align-self: center;

    @media only screen and (max-width: 992px) {
        width: 100%;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
`;

export const VideoThumbnailContainer = styled.div`
    width: 122px;
    height: 69px;
    background-color: #000000;
    position: relative;
    display: inline-block;
    overflow: hidden;

    video {
        display: block;
        width: 101%;
        height: 101%;
        max-height: 70px;
        cursor: pointer;
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

export const PlayIconOverlay = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
`;