import styled from 'styled-components';
import { THEME } from 'constants/theme/theme';

export const Container = styled.div`
    min-width: 50vw;
    color: ${THEME.colors.black100};
`;

interface IGrid {
    isOtherSelected: boolean;
    isSelected: boolean;
}


export const Grid = styled.div<IGrid>`
    width: 100%;
    display: grid;
    flex-direction: column;
    grid-template-columns: repeat(${props => props.isOtherSelected ? 1 : 2}, 1fr);
    grid-gap: 1rem;
    margin: 1rem 0rem;
    justify-content: center;
    align-items: center;
`;

export const GridItem = styled.div<IGrid>`
    width: 100%;
    display: ${props => props.isOtherSelected ? 'none' : 'flex'};
    justify-content: center;
    align-items: center;
    min-height: 5rem;
    border: 1px solid ${props => props.isSelected ? 'transparent' : THEME.colors.black100};
    border-radius: 1rem;
`;

export const SubItemGrid = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    justify-content: center;
    align-items: center;
    color: ${THEME.colors.black100};
    font-weight: 500;
    cursor: pointer;
`;

export const ContentChoiceConfig = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;