import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
`;

export const InputWrap = styled.div`
  position: relative;
  width: ${props => props.size || "100%"};
  z-index: 1;
  margin-bottom: -10px;

  @media only screen and (max-width: 768px) {
   width: 100%;
  }
`


export const Error = styled.p`
  color: #F1851F;
  ${props => props.theme.typography.body.sm.regular};
  margin: 8px 0px 10px 0px;
`;
