import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onSnapshot, doc } from 'firebase/firestore';
import { firestoreFull as firestore } from 'config/firebase';
import { setUser, userInfo as userInfoRedux } from 'redux/user';

const useSyncUserWithRedux = () => {
    const dispatch = useDispatch();
    const userInfo: any = useSelector(userInfoRedux as any)
    const userId = userInfo?.uid;

    useEffect(() => {
        if (!userId) return;

        const userDocRef = doc(firestore, 'Users', userId);
        const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                const userData = { ...docSnapshot.data(), id: docSnapshot.id };
                dispatch(setUser(userData as any));
            } else {
                console.log("No such document!");
                alert('Este usuario no existe')            }
        });

        return () => unsubscribe();
    }, [userId, dispatch]);

};

export default useSyncUserWithRedux;
