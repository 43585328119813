import axios from 'axios';
const PATH_CLOUD_FUNCTION = 'https://existtransaction-otf22pa5xa-uc.a.run.app';

const useExistOrderUid = () => {
    
    const existOrderUid = async (uid: string):Promise<boolean> => {
        try {
            const response = await axios.post(`${PATH_CLOUD_FUNCTION}`, {
                transactionUid: uid
            });
            return response.data.data.exists;
        } catch (error) {
            throw error;
        }
    }

    return {
        verifyOrderUid: existOrderUid
    }
}

export default useExistOrderUid;

