import parse from 'html-react-parser';
function parseHtmlToText(html) {

    const parsed = parse(html);
    // console.log("parsed",parsed);

    // if (typeof parsed === 'string') {
    //     return parsed;
    // }

    // const extractText = (nodes) => {
    //     if (!nodes) return '';

    //     if (typeof nodes === 'string') {
    //         return nodes;
    //     }

    //     if (Array.isArray(nodes)) {
    //         return nodes.map(extractText).join('');
    //     }

    //     if (typeof nodes === 'object' && nodes.props) {
    //         return extractText(nodes.props.children);
    //     }

    //     return '';
    // };

    // const text = extractText(parsed);

    return parsed;
}

export default parseHtmlToText;
