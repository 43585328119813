import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { InputWrap, Error, Container } from './styled';
import Input from 'ui/TextInput';
import PhoneInput from 'ui/PhoneInput';
import TextArea from 'ui/TextArea';
import { Button } from 'ui/Buttons';
import Select from 'ui/Select';
import DatePicker from 'ui/DatePicker';
import { H2 } from 'ui/Text';
import dayjs from 'dayjs';
import { validationSchema } from './main';
import { YachtInquiryType } from 'types';
import useYachtInquiry from 'hooks/modules/services/yachts/useCreateYachtInquiry/useCreateYachtInquiry';
import ModalLoader from 'ui/ModalLoader';
import { useTranslation } from 'react-i18next';
import { useNotification } from 'lib/context/AlertContext';

const Destinations = [
    { label: 'Taboga', value: 'taboga' },
    { label: 'Contadora', value: 'contadora' },
    { label: 'Bahía de Panamá', value: 'bahia de panamá' },
];
interface Props {

}

const FormInquiryYachts: React.FC<Props> = (props: Props) => {
    const {showSuccess, showError, showWarning} = useNotification();
    const { sendInquiry, loading, error } = useYachtInquiry();
    const { t } = useTranslation();

    async function handleCreateYachtInquiry(values: YachtInquiryType): Promise<boolean> {
        const response = await sendInquiry(values);

        let success = false;

        if (response) {
            showSuccess('Su consulta ha sido enviada con éxito');
            success = true;
        } else {
            console.log('err', error);
            showError('Ha ocurrido un error, por favor intente de nuevo');
        }

        return success;
    }


    return ( 
        <Container>
            <H2>{t('inquiry_form')}</H2>
            <Formik
                initialValues={{
                    peopleCount: '',
                    destination: '',
                    fullName: '',
                    phone: '',
                    additionalComments: '',
                    date: dayjs().add(1, 'day').format('YYYY-MM-DD'),
                    createdAt: new Date().toISOString(),
                    status: 'pending',
                }}
                validationSchema={validationSchema}
                onSubmit={async (values: YachtInquiryType, { setSubmitting, resetForm }) => {
                    try {
                        const response = await handleCreateYachtInquiry(values);
                        if (response) {
                            resetForm();
                        }
                    } catch (error) {
                        console.log('err', error);
                        alert('Ha ocurrido un error, por favor intente de nuevo');
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ isSubmitting, errors, setFieldValue, handleSubmit}) => (
                    <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <InputWrap>
                            <Field as={Input} label={t('people_quantity')} name="peopleCount" type="number" />
                            <ErrorMessage name="peopleCount" component={Error} />
                        </InputWrap>

                        <InputWrap>
                            <Field as={Select} label={t('destination')} name="destination" options={Destinations} hideNeverChoice onChange={(e: any) => setFieldValue('destination', e)
                            } />
                            <ErrorMessage name="destination" component={Error} />
                        </InputWrap>

                        <InputWrap>
                            <Field as={DatePicker} label={t('date')} name="date" onChange={(e: any) => setFieldValue('date', e)}  onlyNewDate={true}/>
                            <ErrorMessage name="date" component={Error} />
                        </InputWrap>

                        <InputWrap>
                            <Field as={Input} label={`${t('full_name')}(${t('name_and_last_name')})`} name="fullName" type="text" />
                            <ErrorMessage name="fullName" component={Error} />
                        </InputWrap>

                        <InputWrap style={{ zIndex: 10 }}>
                            <Field as={PhoneInput} placeholder={t('phone')} name="phone" maxLength={20}
                                onChange={(e: any) => setFieldValue('phone', e)}
                            />
                            <ErrorMessage name="phone" component={Error} />
                        </InputWrap>

                        <InputWrap>
                            <Field as={TextArea} label={t('additional_comments')} name="additionalComments" />
                            <ErrorMessage name="additionalComments" component={Error} />
                        </InputWrap>

                        <Button style={{marginTop: '1rem'}} type="submit" onClick={handleSubmit} onDisabledClick={() => showWarning(t('complete_all_fields'))} disabled={isSubmitting || Object.keys(errors).length > 0}>
                            {t('send')}
                        </Button>
                    </Form>
                )}
            </Formik>
            <ModalLoader isLoading={loading} />
        </Container>
    );
};

export default FormInquiryYachts;
