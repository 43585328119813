import {
  ButtonsContainer,
  ProductDescription,
  ProductImage,
  Price,
  Title,
  TransButton,
  Container,
  ProductInfoContainer,
  // PriceDiscount,
  PriceRegular,
  ProductPriceContainer,
  DiscountBatch 
} from "./styled";
import { useNotification } from "lib/context/AlertContext";
import { MdShoppingCart } from "react-icons/md";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import useAddToCart from "hooks/cart/useAddToCart";
import { useTranslation } from "react-i18next";
import useIsAuth from "hooks/user/useIsAuth/useIsAuth";
import { StrokedButton } from "../Buttons";
import { completeChars } from "./main";
import { Product } from "types";
import { ROUTES } from "constants/routes";
import useProductInCartCheck from "hooks/cart/useProductInCartCheck";
import { PriceType } from "types";
import { ProductsCategoryType } from 'types'
import { parseCurrencyToSymbol } from 'utils/common'

interface Props {
  data: Product;
  size?: string;
  sizeSm?: string;
  priceType?: PriceType;
  buttonText?: string;
  mainCategory?: ProductsCategoryType;
}

const ProductCard = (props: Props) => {
  const navigate = useNavigate();
  const addToCart = useAddToCart();
  const productInCartCheck = useProductInCartCheck();
  const { showWarning, showSuccess } = useNotification();
  const isAuth = useIsAuth();
  const { t } = useTranslation();

  const isProductInCart = productInCartCheck(props.data.id);

  const { data, buttonText, mainCategory } = props;
  const { id, name, price, priceType, mainProductCategory } = data;

  const handleAddToCart = () => {
    if (isAuth) {
      addToCart(data as any);
    } else {
      showWarning("Debes iniciar sesión para agregar productos al carrito");
    }
  };

  const handleViewDetailProduct = (id: number | string) => {
    navigate(`${ROUTES.PRODUCT_DETAIL}?uid=${id}${mainCategory ? `&mainCategory=${mainCategory}` : ''}`);
  };

  const handleShowAlertProductAdded = () => {
    showSuccess("El producto ya se encuentra en el carrito");
  };

  return (
    <Container  width={window.innerWidth < 360 ? "100%" : "98%"}>
          {
          priceType === 'discount' && (
            <DiscountBatch>{t('discount')}</DiscountBatch>
          )
        }
      <ProductImage
        onClick={() => handleViewDetailProduct(id)}
        src={data.images.length > 0 ? data.images[0] : ""}
        loading="lazy"
        alt={name.es}
        title={name.es}
      />
      <ProductInfoContainer>
        <ProductPriceContainer>
          {(priceType === 'range' || mainProductCategory === 'wholesale') && price && (
            <Title style={{color: 'gray', marginRight: '8px', marginBottom: '12px'}}>
              {t('from')}
            </Title>  
          )}
          {
            priceType === 'discount' ? (
              <div style={{display: 'flex', width: '100%'}}>      
                <div>
                  <Price>{parseCurrencyToSymbol(data.currency)}{data.salePrice}</Price>    
                </div>
                {
                  mainProductCategory === 'store' && (
                    <div style={{display: 'flex', margin: '.0rem .5rem',}}>
                      <PriceRegular> Regular:</PriceRegular>
                      <Price isDiscount color='gray'>{parseCurrencyToSymbol(data.currency)}{data.regularPrice}</Price>
                    </div>
                  )
                }
              </div>

            ) : (
              price ? (
                <Price>{parseCurrencyToSymbol(data.currency)}{data.price}</Price>
              ) : (
                <Price>{t('check_out_price')}</Price>
              )
            )
          }
          </ProductPriceContainer>
        <ProductDescription>
          {window.innerWidth > 768
            ? completeChars(name.es.substring(0, 100), 100)
            : window.innerWidth < 465
              ? completeChars(name.es.substring(0, 50), 50)
              : completeChars(name.es.substring(0, 80), 90)}
        </ProductDescription>
        <ButtonsContainer>
          <StrokedButton
            width="100%"
            height="32px"
            onClick={() => handleViewDetailProduct(id)}
            style={{
              minWidth: "100px",
            }}
          >
            {buttonText || t('view_more') }
          </StrokedButton>
          { isProductInCart ? (
            <CheckCircleIcon
              style={{
                color: "#3B9901",
                width: "30px",
                height: "30px",
                cursor: "pointer",
              }}
              onClick={handleShowAlertProductAdded}
            />
          ) : (
            <>
              {
                mainProductCategory === 'store' && (
                  <TransButton
                    height="30px"
                    width="30px"
                    onClick={() => handleAddToCart()}
                    bgColor={"#3A36DB"}
                  >
                    <MdShoppingCart style={{
                      margin: '0 8px',
                    }} color={"#FDFDFE"} fontSize='medium' />
                  </TransButton>
                )
              }
            </>
          )}
        </ButtonsContainer>
      </ProductInfoContainer>
    </Container>
  );
};

export default ProductCard;
