import { useSelector } from 'react-redux';
import { itemsCart } from 'redux/cart'



interface Language {
    en: string;
    es: string;
}

interface CartItem {
    id: string;
    name: Language;
    price: string;
    images: string[];
}

const useProductInCartCheck = () => {
  const cartItems = useSelector(itemsCart);

  const isProductAddedToCart = (idProduct: number | string) => {
    const product = cartItems.find((item: CartItem) => item.id == idProduct);
    const isAdded = product ? true : false;
    return isAdded;
  };

  return isProductAddedToCart;
};

export default useProductInCartCheck;
