import React from "react";

// components
import { BuyButtonsContainer, Content, ContentProductsContainer, DescriptionGrid, DescriptionItem, DescriptionItemDesc, DescriptionItemTitle, DescriptionTitle, GalleryContainer, General, GeneralTitle, Header, ItemContainer, Name, Options, Price, Ratings, SpaceBetween, SpanReview } from './styled'
import { createTheme, ThemeProvider } from "@mui/material";
import { theme } from "lib/theme";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "components/media-gallery";
import { P } from "ui/Text";
import { Button } from "ui/Buttons";
import Rating from "components/Rating";
import { useTranslation } from 'react-i18next';


const DetailsOffer = (props: any) => {
    const { item } = props;

    let images: any = [];

    for (let i = 0; i < item.images.length; i++) {
        images.push({
            original: item.images[i],
            thumbnail: item.images[i],
        },)
    }

    const lightTheme = createTheme({
        palette: {
            mode: 'light',
        },
    });

    const { t } = useTranslation();
    
    return (
        <ThemeProvider theme={theme}>
            <Content>
                <Name>{item.title.es}</Name>
                <ContentProductsContainer>
                    <GalleryContainer>
                        <ImageGallery data={images} currentSelected={0}/>
                    </GalleryContainer>
                    <ItemContainer>
                        <Header>
                            <SpaceBetween>
                                <Ratings>
                                    <Price>{`$${item.price}`}</Price>
                                    <ThemeProvider theme={lightTheme}>
                                        <Rating value={4} />
                                    </ThemeProvider>
                                    <SpanReview>({'review'})</SpanReview></Ratings>
                                <BuyButtonsContainer>
                                    <Button>{t('buy')}</Button>
                                </BuyButtonsContainer>
                            </SpaceBetween>
                        </Header>

                        {/*<FullName>{}</FullName>*/}

                        <hr />
                        <Options>
                            <DescriptionTitle>{t('details')}</DescriptionTitle>
                            <DescriptionGrid>
                                <DescriptionItem>
                                    <DescriptionItemTitle>{t('category')}</DescriptionItemTitle>
                                    <DescriptionItemDesc>{item.categoryName.es}</DescriptionItemDesc>
                                </DescriptionItem>
                            </DescriptionGrid>
                        </Options>
                        <hr />
                        <General>
                            <GeneralTitle>{t('general_details')}</GeneralTitle>
                            <P style={{
                                // montserrat family
                                fontFamily: 'Montserrat',
                                fontWeight: '500',
                                fontSize: '1rem'
                            }}>{item.description.es}</P>
                        </General>
                    </ItemContainer>
                </ContentProductsContainer>
            </Content>
        </ThemeProvider>
    )
}

export default DetailsOffer
