import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/fonts/Outfit.ttf';
import './assets/fonts/Montserrat.ttf';
import './assets/fonts/Roboto.ttf';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(<App />)
reportWebVitals();
