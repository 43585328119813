import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MainLayout from "views/layout/MainLayout";
import { Container, Content } from "./styled";
import Categories from "views/containers/SliderCategories";
import ContentServices from "../components/content-services";
import { getMostDemandedServices } from "services/services";
import useCategories from "hooks/useGetCategories/useGetCategories";
import useBanners from "hooks/useGetBanners/useGetBanners";
import useAnalytics from "hooks/useAnalytics/useAnalytics";
import { useTranslation } from "react-i18next";
import BannerFooterMobile from "components/banner-footer-mobile/banner-footer-mobile";
import { BREAKPOINTS } from "constants/index";
import { getCountry } from "redux/common";
import { docDataServicePartnerMock } from "__mocks__/servicesMock";

const Services = () => {
  const registerLog = useAnalytics();
  const country = useSelector(getCountry);
  const { t } = useTranslation();

  const { categories } = useCategories(`${country}/Services/Categories`);
  const { images } = useBanners(`${country}/Banners/ServicesBanners/Es/Main`);
  const [services, setServices] = useState<any[]>([]);
  const [route, setRoute] = useState("");
  const [setTypeCategory] = useState("");

  const categoriesServices = categories.filter(
    (category) => category.isActive === true
  );

  useEffect(() => {
    if (!services.length) {
      getMostDemandedServices().then((res) => {
        setServices(res);
      });
    }
  }, [services]);

  useEffect(() => {
    registerLog({
      screenName: "Services screen",
      pageLocation: window.location.href,
      pagePath: window.location.pathname,
      pageTitle: "Services screen",
    });
  }, [registerLog]);

  return (
    <MainLayout type="services">
      <Container>
        <Categories
          images={images}
          category={route}
          onSelectCategory={setRoute}
          handleType={setTypeCategory}
          background={"linear-gradient(180deg, #0089FF 0%, #0071D3 100%)"}
          type={"Servicios"} // debe estar en español para que funcione
          data={categoriesServices}
        />
        <Content>
          {services.length > 0 && <ContentServices data={services} />}
        </Content>
        {window.innerWidth < BREAKPOINTS.lg && (
          <BannerFooterMobile
            data={docDataServicePartnerMock}
            secondaryText="Regístrate en nuestra plataforma gratis"
            text="¿Prestas algún servicio y deseas tener mayor visibilidad?"
            isShowImg={false}
          />
        )}
      </Container>
    </MainLayout>
  );
};

export default Services;
