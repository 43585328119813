import Modal from '../Modal'
import Loader from '../Loader';

interface IProps {
    isLoading: boolean;
    whiteBackground?: boolean;
}

const ModalLoader = (props: IProps) => {
    const { isLoading, whiteBackground } = props;
    return (
        <Modal whiteBackground={whiteBackground} hideCloseIcon isOpen={isLoading} onClose={() => { }} styleContent={{ width: '200px', borderRadius: '50%', padding: '28px' }} >
            <Loader />
        </Modal>
    )
}

export default ModalLoader;
