import React, {FC} from 'react'
import {Container, ContainerBar, ListU, ListItem, Paragraph, Span, Upper, Subtitle, Title, ListO} from './styled'
import { useTranslation } from 'react-i18next';
// CONTAINERS
import MainLayout from 'views/layout/MainLayout'
import {ThemeProvider} from "@mui/material";
import {theme} from "lib/theme";

const Terms: FC = () => {
    const { t } = useTranslation();

    return (

        <MainLayout>
            <Container id="home-container">
                <ContainerBar id="home-wrap">
                    <ThemeProvider theme={theme}>
                        <Title>{t('terms_and_conditions')}</Title>
                        <Subtitle>{t('last_update')}: 10/04/2023</Subtitle>
                        <Paragraph weight={700}>WHAT DO YOU NEED 7 </Paragraph>
                        <Paragraph weight={700}>{t('refunds_and_returns_policy')}</Paragraph>
                        <Paragraph>{t('if_you_are_not_satisfied_with_your purchase_please_contact_our_customer_service_department')}</Paragraph>
                        <Paragraph>{t('the_product_must_be_returned_to_wdyn7_within_7_business_days')}</Paragraph>
                        <Paragraph>{t('if_the_product_is_returned_unopened')}</Paragraph>
                        <Paragraph>{t('refunds_will_be_made_within_7_business_days')}</Paragraph>
                        <Paragraph weight={700}>{t('please_note_that_wdyn7_and_or_the_Retailer_does_not_allow returns_or_offer_refunds_for_the_following_products')}:</Paragraph>
                        <ListU>
                            <ListItem>{t('products_purchased_free_of_charge')}.</ListItem>
                            <ListItem>{t('products_purchased_by_customers_from_any_country_other_than_panama')}.</ListItem>
                        </ListU>
                        <Paragraph weight={700}>{t('wdyn7_and_or_the_retailer_will_only_accept_returns_when_the_following_requirements_are_met')}:</Paragraph>
                        <ListU>
                            <ListItem>{t('the_request_was_made_within_the_stipulated_time')}.</ListItem>
                            <ListItem>{t('the_product_was_returned_in_the_same_condition_in_which_it_was_delivered_and_retains_its_original_packaging_and_labeling')}.</ListItem>
                            <ListItem>{t('products_that_are_sealed_can_be_returned_without_a_seal_only_in_case_of_defects')}.</ListItem>
                            <ListItem>{t('apple_products_and_other_brands_that_have_a_plastic_that_is_the_seal_of_not_having_opened_the_box_or_adulterated_inside_the_return_is_not_applicable')}.</ListItem>
                            <ListItem>{t('if_the_product_is_an_electronic_item_it_must_have_all_its_stickers')}.</ListItem>
                        </ListU>
                        <Paragraph weight={700}>{t('defective_items')}</Paragraph>
                        <Paragraph>{t('if_you_discover_what_you_believe_to_be_a_manufacturing_defect')}.

                        </Paragraph>
                        <Paragraph>{t('said_defect_if_applicable_must_be_covered_by_the_warranty_conditions_of_your_product')}.

                        </Paragraph>
                        <Paragraph>{t('please_refer_to_the_information_related_to_product_warranty')}.

                        </Paragraph>
                        <Paragraph>{t('products_sold_through_our_website_will_receive_support')}.

                        </Paragraph>
                        <Paragraph weight={700}>{t('modifications_to_the_refund_and_returns_policy')}</Paragraph>
                        <Paragraph>{t('wdyn7_reserves_the_right_to_update_this_refunds_and_returns_policy_at_any_time')}.
                        </Paragraph>
                        <Paragraph weight={700}>{t('our_secure_payment')}</Paragraph>
                        <ListU>
                            <ListItem>{t('with')} SSL</ListItem>
                            <ListItem>{t('using')} Visa/Mastercard/Paypal/Ach/Nequi/Yappy</ListItem>
                        </ListU>
                        <Paragraph weight={700}>{t('security_and_trust_guarantee')}</Paragraph>
                        <Paragraph><Span>{t('the')} SSL (Secure Socket Layer)</Span> {t('is_a_security_protocol_developed_to_ensure_that_data_transmission')}.

                        </Paragraph>
                        <Paragraph weight={700}>{t('terms_of_use_and_conditions_of_service')}</Paragraph>
                        <Paragraph weight={700}>{t('acceptance_of_terms_of_use')}</Paragraph>
                        <Paragraph>{t('each_time_you_use_or_access_our_website_you_agree_to_follow_our_policies_terms_of_use_and_conditions_of_service')}.

                        </Paragraph>
                        <Paragraph weight={700}>{t('our_service')}</Paragraph>
                        <Paragraph>{t('you_agree_that_the_owners_of_this_website_reserve_the_exclusive_right')}.

                        </Paragraph>
                        <Paragraph weight={700}>{t('your_responsibilities_and_registration_obligations')}</Paragraph>
                        <Paragraph>{t('to_use_this_website_you_must_register_and_provide_truthful_personal_information')}.

                        </Paragraph>
                        <Paragraph weight={700}>{t('use_of_our_website')}</Paragraph>
                        <Paragraph>{t('to_become_a_user_of')} <Span>WDYN7</Span>, {t('in_order_to_access_promotions_and_to_purchase_products_or_services_offered_on_our_website')}.
                        </Paragraph>
                        <Paragraph>{t('you_must_complete_the_registration_form_with_your_personal_information')}.

                        </Paragraph>
                        <Paragraph>{t('each_user_may_only_be_the_holder_of_one_1_account')}.

                        </Paragraph>
                        <Paragraph>{t('wdyn7_reserves_the_right_to_request_any_proof')}.

                        </Paragraph>
                        <Paragraph>{t('the_user_will_be_responsible_for_all_operations_carried_out')}.

                        </Paragraph>
                        <Paragraph>{t('the_user_agrees_to_notify_us_immediately_of_any_unauthorized_use')}.

                        </Paragraph>
                        <Paragraph><Span>WDYN7</Span> {t('reserves_the_right_to_reject_any_registration_request_or_to_cancel_a_previously_accepted_registration')}.

                        </Paragraph>
                        <Paragraph weight={700}><Upper>{t('payment_methods')}</Upper></Paragraph>
                        <Paragraph>{t('the_products_and_services_offered_on_our_website_can_only_be_paid_for_with_the_methods_specifically_indicated_in_each_case')}.
                        </Paragraph>
                        <Paragraph><Span>WDYN7</Span> {t('may_indicate_certain_purchase_conditions_depending_on_the_payment_method_used_by_the_user_and_delivery_policies')}. </Paragraph>
                        <Paragraph weight={700}>{t('contracts_and_purchases_executed')}</Paragraph>
                        <Paragraph>{t('through_our_website_retailers_will_make_offers_of_products_and_services')}.
                        </Paragraph>
                        <Paragraph>{t('in_order_to_inform_the_user_or_consumer_about_this_validation')}.</Paragraph>
                        <Paragraph>{t('the_sale_and_dispatch_of_the_products_is_subject_to_their_availability')}.

                        </Paragraph>
                        <Paragraph weight={700}>{t('promotions')}</Paragraph>
                        <Paragraph>{t('the_promotions_offered_on_our_website_are_not_necessarily_the_same_as_those_offered_by_other_sales_channels')}.
                        </Paragraph>
                        <Paragraph weight={700}>{t('dispatch_of_products')}</Paragraph>
                        <Paragraph>{t('products_purchased_through_our_website_will_be_subject_to_the_shipping_and_delivery_conditions')}.</Paragraph>
                        <Paragraph>WDYN7 {t('will_send_users_the_details_by_email_so_that_they_can_track_the_status_of_the_shipment')}.
                        </Paragraph>
                        <Paragraph>WDYN7 {t('and_or_the_retailer_cannot_guarantee_the_delivery_to_some_regions_of_the_country_of_products_considered_oversized_or_overweight')}.
                        </Paragraph>
                        <Paragraph weight={700}>{t('consent_to_use_of_information')}</Paragraph>
                        <Paragraph>{t('account_registration_and_posting_of_ads')}.</Paragraph>
                        <Paragraph weight={700}>{t('terms_of_consent_and_account_deletion')}</Paragraph>
                        <Paragraph>{t('registered_users_who_wish_to_delete_their_account')}.</Paragraph>
                        <Paragraph weight={700}>{t('registration_and_password')}</Paragraph>
                        <Paragraph>{t('you_are_responsible_for_maintaining_the_confidentiality_of_your_password')}.</Paragraph>
                        <Paragraph weight={700}>{t('his_conduct')}</Paragraph>
                        <Paragraph>{t('you_agree_that_all_information_or_data_of_any_kind')}</Paragraph>
                        <Paragraph>{t('you_will_not_provide_any_content_or_engage_in_any_conduct_which_may_be_unlawful')},</Paragraph>
                        <Paragraph>{t('pornographic_designed_to_interfere_with_or_disrupt_the_proper_working_of_this_website')};</Paragraph>
                        <Paragraph>{t('impersonate_or_misrepresent_your_affiliation_with_any_person_or_entity')};</Paragraph>
                        <Paragraph>{t('you_will_not_collect_harvest_loan_or_distribute_any_data_about_or_belonging_to_other_users')};</Paragraph>
                        <Paragraph>{t('you_will_not_provide_any_content_that_could_give_rise_to_our_civil_or_criminal_liability')};</Paragraph>
                        <Paragraph>{t('you_will_not_open_multiple_free_accounts')}.</Paragraph>
                        <br/>
                        <br/>
                        <Paragraph weight={700}>(DROPSHIPING)</Paragraph>
                        <Paragraph weight={700}>{t('international_purchase_service')}</Paragraph>
                        <Paragraph>{t('our_international_purchasing_services_are_carried_out_through_a_network_of_reliable_suppliers_approved')}.
                        </Paragraph>
                        <Paragraph>{t('we_are_governed_by_all_the_laws_of_the_republic_of_panama_based_on_fair_trade')}.

                        </Paragraph>
                        <Paragraph><Span>{t('reliability_policy_registration')}</Span> {t('the_personal_information_provided_by_the_client_is_confidential')}:</Paragraph>
                        <ListU>
                            <ListItem>{t('copy_of_identity_card_copy_of_notice_of_ruc_operations')} (digital).
                            </ListItem>
                            <ListItem>{t('address')}.</ListItem>
                            <ListItem>{t('cell_phone_number')}.</ListItem>
                            <ListItem>{t('email')}.</ListItem>
                            <ListItem>{t('payment_method')}.</ListItem>
                            <ListItem>{t('kind_of_product')}.</ListItem>
                        </ListU>
                        <Paragraph><Span>{t('purchasing_policies_with_us')}</Span> Rol {t('of')} <Span>WDYN7</Span>: {t('intermediary_and_carrier')}</Paragraph>
                        <Paragraph>{t('purchasing_needs_are_those_of')} <Span>{t('customers')}</Span> {t('therefore_we_will_buy_through_purchase_instructions_received')}.
                        </Paragraph>
                        <Paragraph>{t('for_customers_who_buy_through_us_payment_must_be')} <Span>100%</Span> {t('of_the_purchase_value_plus_purchase_insurance_purchase')}.

                        </Paragraph>
                        <Paragraph>{t('to_ensure_a_value_purchase_through_us')}.
                        </Paragraph>
                        <Paragraph weight={700}>{t('purchase_guarantee')}</Paragraph>
                            <Paragraph>{t('at_the_time_of_purchasing_the_item')}.
                            </Paragraph>
                        <Paragraph weight={700}>{t('valuable_item_warranty')}</Paragraph>
                        <Paragraph>{t('any_item_with_a_value_greater_than')}.

                        </Paragraph>
                        <Paragraph>{t('responsibility_of')} <Span>WDYN7</Span> {t('in_the_purchase')}:
                        </Paragraph>
                        <ListU>
                            <ListItem>{t('representation_in_disputes_with_the_seller_and_carrier')}.
                            </ListItem>
                            <ListItem>{t('track_your_purchases')}.
                            </ListItem>
                            <ListItem>{t('management_of_returning_the_item_to_the_seller')}.
                            </ListItem>
                        </ListU>
                        <Paragraph>{t('the_company_is_not_responsible_for_the_purchase_or_shipment_of_restricted_or_prohibited_items')}.
                        </Paragraph>
                        <Paragraph>{t('the_client_agrees_to_pay_taxes')}.

                        </Paragraph>
                        <Paragraph><Span>WDYN7</Span>, {t('undertakes_to_pay_receive_handle_and_transport_your_cargo')}.

                        </Paragraph>
                        <Paragraph><Span>{t('package_withdrawal_policy')}</Span> {t('the_client_has_a_period_of')}.
                        </Paragraph>
                        <Paragraph><Span>{t('claim_policies')} WDYN7</Span> {t('every_item_received_must_be_reviewed_within_our_offices')}.
                        </Paragraph>
                        <Paragraph>{t('the_customer_must_be_sure_and_read_the_entire_description_of_the_item_they_wish_to_purchase')}.
                        </Paragraph>
                        <Paragraph weight={700}><Upper>{t('payment_methods')}</Upper></Paragraph>
                        <ListU>
                            <ListItem>{t('ach_bank_transfer_every_client_must_register_the_company_email_associated_with_the_bank_account')}.
                            </ListItem>
                            <ListItem>Nequi</ListItem>
                            <ListItem>Yappy</ListItem>
                            <ListItem>{t('credit_card')}.
                            </ListItem>
                            <ListItem>PayPal</ListItem>
                        </ListU>
                        <Paragraph weight={700}>{t('this_document_is_a_formal_contract')}.

                        </Paragraph>
                        <Paragraph weight={700}>{t('shipping_and_returns')}
                        </Paragraph>
                        <Paragraph weight={700}>{t('package_shipping')}
                        </Paragraph>
                        <Paragraph>{t('as_a_general_rule_packages_are_sent_within')}.
                        </Paragraph>
                        <Paragraph>{t('items_that_mention_shipping_included')}.
                        </Paragraph>
                        <Paragraph>{t('we_are_responsible_for_any_damage_to_your_packages_after_shipping')}.

                        </Paragraph>
                        <Paragraph weight={700}><Upper>{t('terms_and_conditions')}</Upper>
                        </Paragraph>
                        <Paragraph weight={700}>WHAT DO YOU NEED 7 (DROPSHIPING)

                        </Paragraph>
                        <Paragraph weight={700}>{t('online_shopping_service')}
                        </Paragraph>
                        <Paragraph>{t('we_carry_out_our_online_shopping_services_on_online_pages_that_are_considered_reliable')}.

                        </Paragraph>
                        <Paragraph>{t('we_are_governed_by_all_the_laws_of_the_republic_of_panama_based_on_fair_trade')}.

                        </Paragraph>
                        <Paragraph><Span>{t('reliability_policy_registration')}</Span> {t('the_personal_information_provided_by_the_client_is_confidential')}:
                        </Paragraph>
                        <ListO>
                            <ListItem>{t('copy_of_identity_card')} (digital).</ListItem>
                            <ListItem>{t('residence_address')}.</ListItem>
                            <ListItem>{t('cell_phone_number')}.</ListItem>
                            <ListItem>{t('email')}.</ListItem>
                            <ListItem>{t('payment_method')}.</ListItem>
                        </ListO>
                        <Paragraph><Span>{t('purchasing_policies_with_us')}</Span> Rol {t('of')} <Span>WDYN7</Span>: {t('intermediary_and_carrier')}</Paragraph>
                        <Paragraph>{t('the_purchases_are_made_by_the_clients')}.</Paragraph>

                        <Paragraph>{t('for_customers_who_buy_through_us')}.</Paragraph>

                        <Paragraph>{t('to_ensure_a_value_purchase_through_us')}</Paragraph>
                        <Paragraph weight={700}>{t('purchase_guarantee')}
                        </Paragraph>
                        <Paragraph>{t('at_the_time_of_purchasing_the_item')}.

                        </Paragraph>
                        <Paragraph weight={700}>{t('value_item_guarantee')}

                        </Paragraph>

                        <Paragraph>{t('all_items_must_be_insured_by_the_client')}.
                        </Paragraph>
                        <Paragraph>{t('responsibility_of')} <Span>WDYN7</Span> {t('in_the_purchase')}:
                        </Paragraph>
                        <ListU>
                            <ListItem>{t('representation_in_disputes_with_the_seller_and_carrier')}.
                            </ListItem>
                            <ListItem>{t('track_your_purchases')}.
                            </ListItem>
                        </ListU>
                        <Paragraph>{t('the_company_is_not_responsible_for_the_purchase_or_shipment_of_restricted_or_prohibited_items')}.

                        </Paragraph>
                        <Paragraph>{t('the_client_agrees_to_pay_taxes')}.
                        </Paragraph>
                        <Paragraph><Span>WDYN7</Span>, {t('undertakes_to_pay_receive_handle_and_transport_your_cargo')}.
                        </Paragraph>
                        <br/>
                        <br/>
                        <Paragraph weight={700}>{t('cargo_removal_policy')}

                        </Paragraph>
                        <Paragraph>{t('the_client_has_a_period_of_15_days_to_remove_the_cargo')}.

                        </Paragraph>
                        <Paragraph weight={700}>{t('claim_policies')} WDYN7.

                        </Paragraph>
                        <Paragraph>{t('the_customer_must_be_sure_and_read_the_entire_description_of_the_item_they_wish_to_purchase')}.

                        </Paragraph>
                        <Paragraph weight={700}><Upper>{t('payment_methods')}</Upper>
                        </Paragraph>
                        <ListU>
                            <ListItem>{t('ach_bank_transfer_every_client_must_register_the_company_email_associated_with_the_bank_account')}.
                            </ListItem>
                            <ListItem>Nequi.</ListItem>
                            <ListItem>Yappi.</ListItem>
                            <ListItem>{t('credit_card')}.</ListItem>
                            <ListItem>Paypal.</ListItem>
                        </ListU>
                        <Paragraph weight={700}>{t('this_document_is_a_formal_contract')}.
                        </Paragraph>
                    </ThemeProvider>
                </ContainerBar>
            </Container>
        </MainLayout>
    )
}

export default Terms
