import { StatusPayment } from "types";

export function getColorByStatus(status: StatusPayment) {
    switch (status) {
        case "pending":
        return "#FFA500";
        case "approved":
        return "#038A25"; 
        case "rejected":
        return "#FF0000";
        default:
        return "#000000";
    }
}

export function getTextByStatus(status: StatusPayment) {
    switch (status) {
        case "pending":
        return "Pendiente";
        case "approved":
        return "Aprobado";
        case "rejected":
        return "Rechazado";
        default:
        return "Desconocido";
    }
}