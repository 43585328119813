import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { THEME } from 'constants/theme/theme';
import { useNotification } from 'lib/context/AlertContext';

interface IDatePickerProps {
    label: string;
    onChange: (value: string) => void;
    useISO8601?: boolean;
    defaultValue?: dayjs.Dayjs | string;
    disableFullWidth?: boolean;
    value?: dayjs.Dayjs | string;
    clearDate?: boolean;
    onlyNewDate?: boolean;
    minAge?: number; 
    maxAge?: number; 
}

export default function DatePicker(props: IDatePickerProps) {
    const { 
        label, 
        onChange, 
        useISO8601, 
        defaultValue, 
        disableFullWidth, 
        value, 
        clearDate, 
        onlyNewDate,
        minAge = 0,
        maxAge = 0 
    } = props;

    const { showWarning } = useNotification();
    
    const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | string | null>(
        defaultValue ? dayjs(defaultValue) : null
    );

    const minDate = minAge > 0 ? dayjs().subtract(minAge, 'year') : undefined;
    const maxDate = maxAge > 0 ? dayjs().subtract(maxAge, 'year') : undefined;

    useEffect(() => {
        if (value) {
            setSelectedDate(value);
        } else {
            if (value === '') {
                setSelectedDate(null);
            }
        }

        if (clearDate === true) {
            setSelectedDate(null);
        }

    }, [value, clearDate]);

    const handleDateError = (error: any) => {
        if (error === 'invalidDate' && onlyNewDate) {
            alert('Solo puede seleccionar días después de hoy');
        }
    };

    const validateDate = (date: dayjs.Dayjs | null) => {
        if (date) {
            if ((minDate && date.isBefore(minDate)) || (maxDate && date.isAfter(maxDate))) {
                showWarning(`La fecha debe estar entre ${minDate?.format('MM/DD/YYYY') ||  '01/01/1940'} y ${maxDate?.format('MM/DD/YYYY')}`);
                setSelectedDate(null);
                onChange('');
                return false;
            }
        }
        return true;
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <MuiDatePicker
                label={label || 'Inserte label'}
                value={selectedDate ? dayjs(selectedDate) : null}
                minDate={minDate}
                maxDate={maxDate}
                onError={handleDateError}
                onChange={(newValue) => {
                    if (validateDate(newValue)) {
                        if (useISO8601 === true) {
                            const date: any = newValue ? newValue.format() : '';
                            onChange(date);
                        } else {
                            const date: any = newValue ? newValue.format('MM/DD/YYYY') : '';
                            onChange(date);
                        }
                        setSelectedDate(newValue);
                    }
                }}
                sx={{
                    '&.MuiFormControl-root': {
                        width: disableFullWidth ? 'auto' : '100%',
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: THEME.colors.primary,
                            borderRadius: '0.6rem',
                            border: `2px solid ${THEME.colors.primary}`
                        },
                        '&:hover fieldset': {
                            borderColor: THEME.colors.primary,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: THEME.colors.primary,
                        },
                    },
                }}
            />
        </LocalizationProvider>
    );
}


// import { useState, useEffect } from 'react';
// import dayjs from 'dayjs';
// import 'dayjs/locale/es';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
// import { THEME } from 'constants/theme/theme';

// interface IDatePickerProps {
//     label: string;
//     onChange: (value: string) => void;
//     useISO8601?: boolean;
//     defaultValue?: dayjs.Dayjs | string;
//     disableFullWidth?: boolean;
//     value?: dayjs.Dayjs | string;
//     clearDate?: boolean;
//     onlyNewDate?: boolean;  // new prop for restricting date selection
// }

// export default function DatePicker(props: IDatePickerProps) {
//     const { 
//         label, 
//         onChange, 
//         useISO8601, 
//         defaultValue, 
//         disableFullWidth, 
//         value, 
//         clearDate, 
//         onlyNewDate
//     } = props;
    
//     const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | string | null>(
//         defaultValue ? dayjs(defaultValue) : null
//     );

//     useEffect(() => {
//         if (value) {
//             setSelectedDate(value);
//         } else {
//             if (value === '') {
//                 setSelectedDate(null);
//             }
//         }

//         if (clearDate === true) {
//             setSelectedDate(null);
//         }

//     }, [value, clearDate]);

//     const handleDateError = (error: any) => {
//         if (error === 'invalidDate' && onlyNewDate) {
//             alert('Solo puede seleccionar días después de hoy');
//         }
//     };

//     return (
//         <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
//             <MuiDatePicker
//                 label={label || 'Inserte label'}
//                 value={selectedDate ? dayjs(selectedDate) : null}
//                 minDate={onlyNewDate ? dayjs() : undefined}
//                 onError={handleDateError}
//                 onChange={(newValue) => {
//                     if (useISO8601 === true) {
//                         const date: any = newValue ? newValue.format() : '';
//                         onChange(date);
//                     } else {
//                         const date: any = newValue ? newValue.format('MM/DD/YYYY') : '';
//                         onChange(date);
//                     }
//                     setSelectedDate(newValue);
//                 }}
//                 sx={{
//                     '&.MuiFormControl-root': {
//                         width: disableFullWidth ? 'auto' : '100%',
//                     },
//                     '& .MuiOutlinedInput-root': {
//                         '& fieldset': {
//                             borderColor: THEME.colors.primary,
//                             borderRadius: '0.6rem',
//                             border: `2px solid ${THEME.colors.primary}`
//                         },
//                         '&:hover fieldset': {
//                             borderColor: THEME.colors.primary,
//                         },
//                         '&.Mui-focused fieldset': {
//                             borderColor: THEME.colors.primary,
//                         },
//                     },
//                 }}
//             />
//         </LocalizationProvider>
//     );
// }