import { FC, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Container, ContentForm, InputWrap, InputsContainer, SectionTitleWithBackground, Grid } from "../styled";
import ImagePickerV2 from "ui/ImagePickerV2"
// import Select from "ui/Select";
import { Formik, Form, Field } from 'formik';
import Input from 'ui/TextInput';
import { P } from "ui/Text";
import GoogleMapInput from "ui/GoogleMapInput";
import { TextError } from "ui/Text";
import TextArea from "ui/TextArea";
import { Button } from "ui/Buttons";
import { useNotification } from "lib/context/AlertContext";
import YesOrNot from "components/YesOrNot";
import { classifiedTechFormSchema } from '../main'
import { ITechClassified, IFormPropertyTechClassified } from "types";
import { MAX_LENGTH_DESCRIPTION, MAX_LENGTH_TITLE, MAX_IMAGES_12 } from "constants/constants";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { onlyNumbers } from "utils/common";
import YearPicker from "ui/YearPicker/year-picker";

// import { languageCollectionTransform, transformItemNameToValueLabel } from "utils/language-transform-collection";
// Icons
interface Props {
    category?: string;
    userUid: string;
    onSubmit?: (values: ITechClassified) => void;
    createClassifiedSuccess: boolean;
    formProperties: IFormPropertyTechClassified[];
    language: string;
    categoryType: string;
   
}

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_WDYN_GOOGLE_MAPS_API_KEY;

const NewTechForm: FC<Props> = (props: Props) => {
    const { category, onSubmit, userUid, createClassifiedSuccess, categoryType} = props; 
    const formRef: any = useRef(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { showError } = useNotification();

    const initialPosition = { lat: 8.98251176638921, lng: -79.528036556417 };
    console.log('createClassifiedSuccess', categoryType)


    useEffect(() => {
        if (createClassifiedSuccess && formRef.current) {
            formRef.current.resetForm();
            setTimeout(() => {
                navigate('/classifieds');
            }, 2000);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createClassifiedSuccess]);

    

    return (
        <Container>
            <h2 style={{ color: '#000' }}>{category || ''}</h2>
            <Formik
                innerRef={formRef}
                initialValues={{
                    title: '',
                    description: '',
                    category: category || '',
                    brand: '',
                    model: '',
                    year: '',
                    isNew: false,
                    location: '',
                    price: '',
                    images: [],
                    userId: userUid,
                    categoryType: categoryType || ''
                }}
                validationSchema={classifiedTechFormSchema}
                onSubmit={(values: ITechClassified) => {
                    onSubmit && onSubmit(values);
                }}
            >
                {({ errors, touched, values, setFieldValue, handleSubmit, handleChange }) => (
                    <ContentForm>
                        <Form>
                            <div>
                                <ImagePickerV2
                                    maxImages={MAX_IMAGES_12}
                                    listImages={values?.images || [] as any}
                                    onChange={(files) => {
                                        setFieldValue('images', files);
                                    }}
                                />
                            </div>
                            <InputsContainer>
                                <SectionTitleWithBackground>{t('general_information')}</SectionTitleWithBackground>
                                <InputWrap>
                                    <Field as={Input} label={t('classified_title')} name="title" maxLength={MAX_LENGTH_TITLE} />
                                    <TextError>{touched.title && errors.title && errors.title}</TextError>
                                </InputWrap>
                                <InputWrap>
                                    <Field as={TextArea} label={t('description')} name="description" maxLength={MAX_LENGTH_DESCRIPTION} />
                                    <TextError>{touched.description && errors.description && errors.description}</TextError>
                                </InputWrap>
                                <SectionTitleWithBackground>{t('article_information')}</SectionTitleWithBackground>
                                <Grid>
                                    <InputWrap>
                                        <Field
                                            as={Input}
                                            label={t('brand')}
                                            value={values.brand}
                                            maxLength={20}
                                            onChange={(e) => { handleChange('brand')(e.target.value); }} />
                                        <TextError>{touched.brand && errors.brand && errors.brand}</TextError>
                                    </InputWrap>
                                    <InputWrap>
                                        <Field
                                            as={Input}
                                            label={t('model')}
                                            maxLength={40}
                                            value={values.model}
                                            onChange={(e) => { handleChange('model')(e.target.value); }} />
                                        <TextError>{touched.model && errors.model && errors.model}</TextError>
                                    </InputWrap>
                                    <InputWrap>
                                        <InputWrap>
                                            <Field
                                                as={YearPicker}
                                                label={t('year')}
                                                value={values.year}
                                                onChange={(e) => {
                                                    handleChange('year')(e);
                                                }} />
                                            <TextError>{touched.year && errors.year && errors.year}</TextError>
                                        </InputWrap>
                                    </InputWrap>
                                </Grid>
                                <Grid columns={window.innerWidth < 768 ? 2 : 3}>
                                    <InputWrap>
                                        <YesOrNot
                                            label={t('is_new')}
                                            isYes={values.isNew}
                                            onChange={(e) => { setFieldValue('isNew', e); }}
                                        />
                                        <TextError>{touched.isNew && errors.isNew && errors.isNew}</TextError>
                                    </InputWrap>
                                </Grid>


                                <SectionTitleWithBackground>{t('additional_information')}</SectionTitleWithBackground>
                                <InputWrap>
                                    <P>{t('location')}</P>
                                    <GoogleMapInput
                                        initialPosition={initialPosition}
                                        apiKey={GOOGLE_MAPS_API_KEY || ''}
                                        onChange={(e) => {
                                            setFieldValue('location', e);
                                        }}
                                    />
                                    <TextError>{touched.location && errors.location && <div>{errors.location}</div>}</TextError>
                                </InputWrap>
                                <SectionTitleWithBackground>{t('price')}</SectionTitleWithBackground>
                                <InputWrap>
                                    <Field
                                        as={Input}
                                        label={t('price')}
                                        value={values.price}
                                        onChange={(e) => { handleChange('price')(onlyNumbers(e.target.value)); }}
                                        maxLength={12}
                                    />
                                    <TextError>{touched.price && errors.price && <div>{errors.price}</div>}</TextError>
                                </InputWrap>
                            </InputsContainer>
                            <InputsContainer style={{ marginTop: '2rem' }}>
                                <Button
                                    type="submit"
                                    onClick={() => handleSubmit()}
                                    disabled={Object.keys(errors).length > 0}
                                    onDisabledClick={() => {
                                        showError(t('please_complete_all_required_fields'));
                                        console.log('errors', errors)
                                    }}
                                    IconRight={<NewspaperIcon />}
                                >{t('create_classified')}</Button>
                            </InputsContainer>
                        </Form>
                    </ContentForm>
                )}
            </Formik>
        </Container>
    );
}

export default NewTechForm;
