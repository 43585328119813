import React from "react";
import {
  Browser,
  BrowserDesc,
  FlexColumn,
  SmallSection,
  ContainerText,
  Text,
  HighlightText,
  SecondaryText,
 // ImageWrapper,
 // BrowserImg,
} from "./styled";
import { Button } from "ui/Buttons";
// import payCart from "assets/pagosCart.png";
// import ModalSelector from "modules/services/pages/detail/components/modal-selector";
import { useTranslation } from "react-i18next";


interface PropsBannerFooterMobile {
  data?: any;
  text?: string;
  secondaryText?: string;
  isShowImg?: boolean;
}

const BannerFooterMobile: React.FC<PropsBannerFooterMobile> = (props) => {
  const { text, secondaryText, isShowImg = true } = props;
  const { t } = useTranslation();

  const handleOnClick = () => {
   // abrir enlace
    window.open("https://api.whatsapp.com/send?phone=50764050893&text=Hola%20deseo%20m%C3%A1s%20informaci%C3%B3n%20de%20sus%20servicios%20de%20marketing%20y%20como%20anunciar%20mi%20producto%20o%20servicio%20con%20ustedes", "_blank");
        
  };

  return (
    <SmallSection background="#ffffff" pav="1rem" pah="1rem">
      <FlexColumn pav="0.2rem" pah="0.2rem">
        <Browser>
          <BrowserDesc>
            <ContainerText>
              <Text>
                <HighlightText>{text}</HighlightText>
              </Text>
              <SecondaryText>{secondaryText}</SecondaryText>
            </ContainerText>
            <Button
              style={{
                width: "40%",
                alignSelf: "left",
                marginTop: "10px",
              }}
              onClick={handleOnClick}
            >
              {t('join_now')}
            </Button>
            {/*isShowImg ? (
              <ImageWrapper>
                <BrowserImg src={payCart} loading="lazy" alt="Pay Cart" title="Pay Cart" />
              </ImageWrapper>
            ) : null */}
          </BrowserDesc>
        </Browser>
      </FlexColumn>
      {/* <ModalSelector
        docData={data}
        showContactModal={showContactModal}
        setShowContactModal={setShowContactModal}
      /> */}
    </SmallSection>
  );
};

export default BannerFooterMobile;
