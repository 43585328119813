import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { firestoreFull as firestore } from "config/firebase";
import { DBCountryReference } from "types";
import { DB_ROUTES } from "constants/routes";

const COLLECTION_PATH = `${DBCountryReference.PAN}${DB_ROUTES.POSTS_PRODUCTS_STORE}`;

function createQuery(filters, category: number) {
  const baseQuery = collection(firestore, COLLECTION_PATH);
  let queryWithFilters: any = baseQuery;

  if (filters.order) {
    queryWithFilters = query(queryWithFilters, orderBy(filters.order));
  }
  if (filters.category || category !== 0) {
    queryWithFilters = query(
      queryWithFilters,
      where("idCategory", "==", filters.category ?? category)
    );
  }
  if (filters.price) {
    queryWithFilters = query(
      queryWithFilters,
      where("price", ">=", filters.price[0]),
      where("price", "<=", filters.price[1])
    );
  }

  return queryWithFilters;
}

export async function getProducts(filters, category: number = 0): Promise<any> {
  const { path } = filters;

    const productsPath = collection(firestore, path);
    const queryEqualsCategory = query(
      productsPath,
      where("listIdCategory", "array-contains-any", [category])
    );
    const querySnapshot = await getDocs(queryEqualsCategory);

    return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
}

export const getProductById = async (uid, collectionPath: string) => {
  try {
    const productRef = doc(firestore, collectionPath, uid.toString());
    const productSnap = await getDoc(productRef);

    if (productSnap.exists()) {
      return { ...productSnap.data(), id: productSnap.id };
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.log("Error getting document:", error);
    return null;
  }
};

export const getProductsByCategory = async (id: number): Promise<any> => {
  try {
    const productsPath = collection(firestore, COLLECTION_PATH);
    const queryEqualsCategory = query(
      productsPath,
      where("idCategory", "==", id)
    );
    const querySnapshot = await getDocs(queryEqualsCategory);
    

    return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  } catch (error) {
    console.log("Error getting products by category id:", error);
    return null;
  }
};

const EXCLUDE_CATEGORIES_PRE = []; //[1296, 1290, 1289, 311, 312, 313, 1149, 308, 255, 1281, 1301, 216];
const EXCLUDE_CATEGORY = [220];

export const getProductsByMultipleCategories = async (
  ids: number[],
  collectionPath: string
): Promise<any> => {
  try {
    const productsRef = collection(firestore, collectionPath);
    const queryEqualsCategory = query(
      productsRef,
      where("listIdCategory", "array-contains-any", ids),
      orderBy("createdAt", "desc")
    );

    const querySnapshot = await getDocs(queryEqualsCategory);

    const products = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    const filteredProducts = products
    
    .filter(
      (product: any) =>
        !EXCLUDE_CATEGORIES_PRE.some((excluded) =>
          product.listIdCategory.includes(excluded)
        )
    );

    const productsByCategory: { [key: number]: any[] } = {};


    filteredProducts.forEach((product: any) => {
      product.listIdCategory.forEach((categoryId: number) => {
        if (!productsByCategory[categoryId]) {
          productsByCategory[categoryId] = [];
        }

        // Clonar el producto antes de modificar su idCategory
        const productClone = { ...product, idCategory: categoryId };

        // Asegurarte de que el producto no se repita en la misma categoría
        if (
          !productsByCategory[categoryId].some(
            (existingProduct) => existingProduct.id === productClone.id
          )
        ) {
          productsByCategory[categoryId].push(productClone);
        }
      });
    });

    // BORRA LA CATEGORÍAS QUE ESTÁN EN EXCLUDE_CATEGORY
    EXCLUDE_CATEGORY.forEach((categoryId: number) => {
      delete productsByCategory[categoryId];
    });

    const groupedProducts = Object.values(productsByCategory);

    return groupedProducts.filter((products: any[]) => products.length >= 5);
  } catch (error) {
    console.log("Error getting products by category id:", error);
    return null;
  }
};
