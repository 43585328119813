
import { Container, Label, NewCardContainer, ActionButton, ActionText, ActionContainer, Badge, BadgeContainer} from "./styled";
import { Semibold } from "ui/Text";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { H3 } from "ui/Text";
import { THEME } from 'constants/theme/theme'
import { useTranslation } from "react-i18next";


interface AddressCardProps {
    fullName?: string;
    street?: string;
    phone?: string;
    country?: string;
    city?: string;
    isSelected?: boolean;
    isNewAddress?: boolean;
    onEdit?: () => void;
    onDelete?: () => void;
    onClickNewAddress?: () => void;
    onClick?: () => void;
    isMainAddress?: boolean;
}

const CreateAddressContent = () => {
    const { t } = useTranslation();

    return (
        <NewCardContainer>
            <AddIcon style={{fontSize: '64px', color: THEME.colors.black100}}/>
            <H3 color={THEME.colors.black100}>{t('add_address')}</H3>
        </NewCardContainer>
    )
}



// Main Component
const CardAddress = ({ fullName, street, phone, country, city, isSelected, isNewAddress, onDelete, onEdit,onClick,onClickNewAddress ,isMainAddress }: AddressCardProps) => {
    const { t } = useTranslation();

    return (
        <Container 
            isSelected={isSelected} 
            isNewAddress={ isNewAddress}  
            onClick={() => {
                if (isNewAddress) {
                    onClickNewAddress && onClickNewAddress()
                }

                if (onClick) {
                    onClick()
                }
            }}
        >
            {
                !isNewAddress ? (
                    <div style={{display: 'flex', flexDirection: 'column', height:'100%'}}>
                        {
                            isMainAddress && (
                                <BadgeContainer>
                                    <Badge>{t('main')}</Badge>
                                </BadgeContainer>
                            )
                        }
                        <div>
                            <Label>{t('name')}</Label>
                            <Semibold size='m'>{fullName || ''}</Semibold>
                        </div>
                        <hr style={{ margin: '4px 0px' }} />
                        <div>
                            <Label>{t('address')}</Label>
                            <Semibold size='s' maxLines={1}>{street || ''}</Semibold>
                        </div>
                        <div>
                            <Label>{t('phone')}</Label>
                            <Semibold size='s'>{phone || ''}</Semibold>
                        </div>
                        <div>
                            <Label>{t('country')}</Label>
                            <Semibold size='s'>{country || ''}</Semibold>
                        </div>
                        <div>
                            <Label>{t('city')}</Label>
                            <Semibold size='s'>{city || ''}</Semibold>
                        </div>
                        <ActionContainer>
                            <ActionButton onClick={onEdit}>
                                <ActionText>{t('edit')}</ActionText>
                                <EditIcon style={{ color: THEME.colors.black80, fontSize: '14px'}} />
                            </ActionButton>
                            <ActionButton onClick={onDelete}>
                                <ActionText isDelete>{t('delete')}</ActionText>
                                <DeleteIcon style={{ color: THEME.colors.alert100, fontSize: '14px'}} />
                            </ActionButton>
                        </ActionContainer>
                    </div>
                ) : (
                    <CreateAddressContent />
                )
            }
        </Container>
    )

}

export default CardAddress;