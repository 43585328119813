import {createSelector} from '@reduxjs/toolkit'
import { User } from 'types'

const getUser = (state: any) => state.user

export const userSelector = createSelector(getUser, (user) => user)
// @ts-ignore
export const userInfo: User = createSelector(getUser, (user) => user.userData)

export const getIpInfoSelector = createSelector(getUser, (user) => user.ipInfo)

export const offlineUserInfoSelector = createSelector(getUser, (user) => user.offlineUserData)

export const errorLoginUserSelector = createSelector(getUser, (user) => user.error)

export const currentYachtNameSelector = createSelector(getUser, (user) => user.yachtName)
