import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { getCountry, getLanguage } from 'redux/common';
import { useGetFirestoreDoc } from 'hooks/useGetDocumentFirestore';
import { useNotification } from 'lib/context/AlertContext';
import { useLocation } from 'react-router-dom';
import { SelectableComponent, SelectableDivs } from './components';
import parseHtmlToText from 'utils/parseHtmlToText';
import { FormWrapper, TitleWrapper, FieldWrapper, FormButtonWrapper, Select, Span, H2 } from './styled';
import { LOGISTIC_PANAMA_PHONE_NUMBER } from "constants/constants";
import { Button } from 'ui/Buttons';
import { H3 } from 'ui/Text';


interface Option {
    id: number;
    label?: string;
    value?: string;
}

interface Category {
    id: number;
    label?: string;
    value?: string;
    icon?: string;
}

const DetailForm = () => {
    const { t } = useTranslation();
    const country = useSelector(getCountry);
    const language = useSelector(getLanguage);
    const { showError } = useNotification();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const id = params.get('id');

    const {
        fetchDoc: fetchPost,
        docData: postData,
        loadingGetFirestoreDoc: loadingFetchPost,
        errorGetFirestoreDoc: fetchError,
    } = useGetFirestoreDoc();
    const [options, setOptions] = useState<Option[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [loadingOptions, setLoadingOptions] = useState(true);
    const [selectedOption, setSelectedOption] = useState<string>('');
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [inversion, setInversion] = useState<string>('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!id) {
                    showError('No se encontró el ID del post');
                    setLoadingOptions(false);
                    return;
                }

                await fetchPost({
                    path: `${country}/Earnmoney/Posts`,
                    uid: id,
                });
            } catch (error) {
                showError(
                    'Ocurrió un error al obtener datos de Firestore. Por favor, inténtalo de nuevo más tarde.'
                );
                setLoadingOptions(false);
            }
        };

        fetchData();
    }, [country, id, fetchPost, showError]);

    useEffect(() => {
        if (postData) {

            if (
                postData.options &&
                Array.isArray(postData.options) &&
                postData.options.length > 0
            ) {
                const optionsList: Option[] = postData.options.map(
                    (option: any, index: number) => ({
                        id: index,
                        label: option[language] || '',
                        value: option[language] || '',
                    })
                );
                setOptions(optionsList);
            }

            if (
                postData.categories &&
                Array.isArray(postData.categories) &&
                postData.categories.length > 0
            ) {
                const categoriesList: Category[] = postData.categories.map(
                    (category: any, index: number) => ({
                        id: index,
                        label: category[language] || '',
                        value: category[language] || '',
                        icon: category.icon || '',
                    })
                );
                setCategories(categoriesList);
            }
        }

        setLoadingOptions(false);
    }, [postData, language, showError]);

    useEffect(() => {
        if (fetchError) {
            showError(
                'Error fetching Firestore document. Please try again later.'
            );
        }
    }, [fetchError, showError]);


    const buildWhatsAppLink = () => {
        // Obtener la URL de la página actual
        const currentUrl = window.location.href;
        const postName = postData?.name?.[language] || '';
        let message = `${t('hello_i_am')} ${selectedOption}. ${t('i_am_interested_in')} ${t('earn_money')} - ${postName}.`;
        
        message += ` ${t('i_am_interested_in_the_category')} ${selectedCategory}.`;
    
        if (inversion) {
            message += ` ${t('my_initial_investment_is')} ${inversion}.`;
        }
            
        return `https://api.whatsapp.com/send?phone=${LOGISTIC_PANAMA_PHONE_NUMBER}&text=${encodeURIComponent(message)}`;
    };
    

    const handleClickSend = (formikProps) => {
        formikProps.setTouched({
            selectedOption: true,
            selectedCategory: true,
            inversion: true,
        });

        formikProps.validateForm().then((errors) => {
            if (Object.keys(errors).length === 0) {
                const whatsappLink = buildWhatsAppLink();
                window.open(whatsappLink, '_blank');
            } 
        });
    };



    
    // Condicional para determinar si inversion debe ser requerido
    const inversionIsRequired = !!postData?.inversionOptions?.[language];

    return (
        <>
            {options.length > 0 && (
                <Formik
                    initialValues={{
                        selectedOption: '',
                        selectedCategory: '',
                        inversion: '',
                    }}
                    validationSchema={Yup.object().shape({
                        selectedOption: Yup.string().required('Seleccione una opción'),
                        selectedCategory: Yup.string().required('Seleccione una categoría'),
                        inversion: inversionIsRequired ? Yup.string().required('Seleccione una opción de inversión') : Yup.string().notRequired(),
                    })}
                    onSubmit={() => {}}
                >
                    {({
                        values,
                        setFieldValue,
                        touched,
                        errors,
                        ...formikProps
                    }) => (
                        <FormWrapper>
                            <TitleWrapper>
                                <H3>{t('to_have_more_information_about_your_need')}</H3>
                            </TitleWrapper>
                            <Form>
                                <FieldWrapper>
                                    <SelectableComponent
                                        options={options}
                                        onSelect={(value) => {
                                            setFieldValue('selectedOption', value);
                                            setSelectedOption(value);
                                        }}
                                    />
                                    {touched.selectedOption && errors.selectedOption && (
                                        <Span>{errors.selectedOption}</Span>
                                    )}
                                </FieldWrapper>
                                <FieldWrapper>
                                    <H2>{t('select_a_category')}</H2>
                                    <SelectableDivs
                                        categories={categories}
                                        onSelect={(value) => {
                                            setFieldValue('selectedCategory', value);
                                            setSelectedCategory(value);
                                        }}
                                    />
                                    {touched.selectedCategory && errors.selectedCategory && (
                                        <Span>{errors.selectedCategory}</Span>
                                    )}
                                </FieldWrapper>
                                <div>
                                    <Field type="hidden" name="selectedOption" />
                                    <Field type="hidden" name="selectedCategory" />
                                </div>

                                {inversionIsRequired && (
                                    <FieldWrapper>
                                        <Field
                                            name="inversion"
                                            as={Select}
                                            defaultValue=""
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setFieldValue('inversion', value);
                                                setInversion(value);
                                            }}
                                        >
                                            {parseHtmlToText(
                                                postData.inversionOptions[language]
                                            )}
                                        </Field>
                                        {touched.inversion && errors.inversion && (
                                            <Span>{errors.inversion}</Span>
                                        )}
                                    </FieldWrapper>
                                )}

                                <div>
                                    <FormButtonWrapper>
                                        <Button
                                            type="button"
                                            onClick={() => handleClickSend(formikProps)}
                                        >
                                            {t('send')}
                                        </Button>
                                    </FormButtonWrapper>
                                </div>
                            </Form>
                        </FormWrapper>
                    )}
                </Formik>
            )}
        </>
    );
};

export default DetailForm;
