import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import usePaymentProcessing from "hooks/checkout/usePaymentCheckout";
import { Button } from "ui/Buttons";
import { Summary, SummaryTitle, ItemDesc, ItemImg, ItemName, ItemQty, ItemPrice, Item, SpaceBetween, SumFinalText, SummarySubtotal, SummaryText, SummaryFinalPrice, SummaryTotal } from "../styled"
import { itemsCart, totalCart, cleanCart } from "redux/cart";
import ModalLoader from "ui/ModalLoader/modal-loader";
import { useNotification } from "lib/context/AlertContext";
import { userSelector } from "redux/user";
import { getPaymentMethod, getAddress } from 'redux/checkout';
import { getLanguage, getCountry } from "redux/common";
import { STEPS_CHECKOUT } from "../constants";
interface StepFourProps {
    onSubmit: (step: number) => void;
    data: any;
    setData: any;
} 

const StepFour = (props: StepFourProps) => {
    const dispatch = useDispatch();
    const { showSuccess, showError } = useNotification();
    const { onSubmit, data, setData } = props;

    const [loading, setLoading] = useState(false);

    const { paymentResponse, processPayment } = usePaymentProcessing();
    const language = useSelector(getLanguage);
    const country = useSelector(getCountry);
    const items = useSelector(itemsCart);
    const userData = useSelector(userSelector)?.userData;
    const paymentMethod = useSelector(getPaymentMethod);
    const address = useSelector(getAddress);
    const total = useSelector(totalCart);

    useEffect(() => { 
        if (paymentResponse?.success) {
            setLoading(false);
            showSuccess('pago realizado con exito')
            dispatch(cleanCart());
            setData(prev => ({
                ...prev,
                paymentResponse,
            }));
                onSubmit(STEPS_CHECKOUT.RESUME_CHECKOUT);
        } else if (paymentResponse?.error) {
            setLoading(false);
            showError('Ha ocurrido un error al procesar el pago')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentResponse, dispatch, onSubmit]);


    const handlePaymentProcessing = () => {
        if ( !loading ) {
            setLoading(true);

            const cartItemsFormatted = items.map(item => ({
                image: item.image,
                count: item.count,
                price: item.price,
                name: item.name,
            }));

            processPayment({
                userUid: userData.uid,
                orderId: null,
                orderUid: null,
                paymentMethodId: paymentMethod,
                shippingAddressId: address.id,
                items: cartItemsFormatted,
                paymentImageCapture: data.dataSuccessPayment? data.dataSuccessPayment.imagePaymentCaptureBase64 : null,
                backupPaymentCaptureReference: data.dataSuccessPayment? data.dataSuccessPayment.backupPaymentCaptureReference : null,
                shippingCost: 0,
                taxAmount: 0,
                country: country,
                language: language,
                typeTransaction: 'product_payment',
                currency: 'USD',
                paymentId: data.dataSuccessPayment? data.dataSuccessPayment.paymentId : null,
            });
            

            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    };

    return (
        <>
            <Summary>
                <SummaryTitle>Resumen del pedido</SummaryTitle>
                {items.length > 0 ?
                    items.map((item, index) => {
                        return <Item key={index}>
                            <ItemDesc>
                                <ItemImg src={item.image} />
                                <ItemQty>{item.count}</ItemQty>
                                <ItemName>{item.name?.es || item.name}</ItemName>
                            </ItemDesc>
                            <ItemPrice>$ {parseFloat(item.price)}</ItemPrice>
                        </Item>
                    })
                    :
                    <h2 style={{ textAlign: "center", marginTop: '20px' }}>No hay productos en el carrito</h2>
                }
                <SpaceBetween>
                    <SumFinalText>Resumen</SumFinalText>
                    <SummarySubtotal>
                        <SummaryText>SubTotal: </SummaryText>
                        <SummaryFinalPrice>${total?.toFixed(2)}</SummaryFinalPrice>
                    </SummarySubtotal>
                </SpaceBetween>
                {/* <SummaryTotal>
                    <SummaryText>Envio: </SummaryText>
                    <SummaryFinalPrice>${data.shipment === "gratuito" ? 0 : 9.99}</SummaryFinalPrice>
                </SummaryTotal> */}
                <hr />
                <SummaryTotal>
                    <SummaryText>Total: </SummaryText>
                    <SummaryFinalPrice>${total?.toFixed(2)}</SummaryFinalPrice>
                </SummaryTotal>
            </Summary>

            <div style={{display: 'flex', width: '100%', justifyContent: 'center', marginTop: '12px'}}>
                <Button type='submit' onClick={handlePaymentProcessing} >Finalizar</Button>
            </div>

            <ModalLoader isLoading={loading} />
        </>
    )
}

export default StepFour;
