import { useState } from 'react';
import { useSelector } from 'react-redux';
import { DeleteClassified, ServiceResponse } from 'types';
import { getCountry } from 'redux/common';
import deleteClassifiedService from 'components/cards/services/classifieds/delete-classifieds';

const useDeleteClassified = () => {
    const country = useSelector(getCountry);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [response, setResponse] = useState<ServiceResponse | null>(null);

    const deleteClassified = async (props: DeleteClassified) => {
        setIsLoading(true);
        setError(null);

        try {

            const deleteprops = {...props, }

            const result = await deleteClassifiedService(deleteprops);
            
            setResponse({
                status: 'OK',
                message: 'Clasificado y sus imágenes eliminados correctamente',
                success: true,
            });

        } catch (error) {
            console.error(error);
            setError('Error al eliminar el clasificado. Inténtalo de nuevo.');
            setResponse({
                status: 'ERROR',
                message: 'Error al eliminar el clasificado. Inténtalo de nuevo.',
                success: false,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return { deleteClassified, isLoading, error, response };
};

export default useDeleteClassified;
