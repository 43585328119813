import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useIsAuth from "hooks/user/useIsAuth/useIsAuth";
import { ContentClassifiedsContainer, LinkMore, ListClassifiedContainer, SpaceBetween, SpanMiddle, TitlesLink, IconImage } from './styled' //  PaneFooter, PaneFooterContainer, PanelContent, PaneSection, PaneTitle, TextFilter,
import { H2 } from 'ui/Text';
import { StrokedButton } from "ui/Buttons";
import { ThemeProvider } from "@mui/material";
import { theme } from "lib/theme";
import { Publish } from "../publish";
import CarouselClassifieds from "../carousel-classifieds/carousel-classifieds";
import { MAIN_ROUTES } from "constants/routes";

interface Props {
    suggestedClassifieds: any;
    realStateClassifieds: any;
    vehicleClassifieds: any;
    employmentClassifieds: any;
    techClassifieds: any;
    constructionClassifieds: any;
    homeClassifieds: any;
    petsClassifieds: any;
    musicClassifieds: any;
    sportsClassifieds: any;
}

const ContentServices = (props: Props) => {
    const { suggestedClassifieds, realStateClassifieds, vehicleClassifieds, employmentClassifieds, techClassifieds, constructionClassifieds, homeClassifieds, petsClassifieds, musicClassifieds, sportsClassifieds } = props;

    const isAuth = useIsAuth();
    const { t } = useTranslation();
    // const location = useLocation();
    // const pathname = location.pathname;
    // const routePath = pathname.substring(pathname.lastIndexOf('/') + 1);

    return (
        <div>
            <ThemeProvider theme={theme}>
                <SpaceBetween>
                    <H2>{t('classifieds')}</H2>
                    {
                        isAuth ? (
                            <Link to={isAuth ? MAIN_ROUTES.MyClassifieds : '#'}>
                                <StrokedButton >
                                    <IconImage src='https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Fclassified-64x64.png?alt=media&token=fff0f7a1-429c-4994-a6be-39f606c781f1' alt='icon classified' title='icon classified' />
                                    <SpanMiddle>{t('my_classifieds')}</SpanMiddle>
                                </StrokedButton>
                            </Link>
                        ) : null
                    }
                </SpaceBetween>

                <ContentClassifiedsContainer>
                    <ListClassifiedContainer pad='1rem 1rem 0rem 1rem'>
                        <SpaceBetween>
                            <TitlesLink>
                                {t('based_on_your_searches')}
                            </TitlesLink>
                        </SpaceBetween>
                        <CarouselClassifieds data={suggestedClassifieds} />
                    </ListClassifiedContainer>
                    <ListClassifiedContainer color={"#FDFDFE"} pad={"1rem .4rem 0 .4rem"}>
                        <SpaceBetween>
                            <TitlesLink>{t('real_estate')}</TitlesLink>
                            <Link to={`/classifieds/category/RealState`}>
                                <LinkMore>{t('view_all')}</LinkMore>
                            </Link>
                        </SpaceBetween>
                        <CarouselClassifieds data={realStateClassifieds} />

                        <SpaceBetween>
                            <TitlesLink>{t('vehicles')}</TitlesLink>
                            <Link to={`/classifieds/category/Vehicles`}>
                                <LinkMore>{t('view_all')}</LinkMore>
                            </Link>
                        </SpaceBetween>
                        <CarouselClassifieds data={vehicleClassifieds} />

                        <SpaceBetween>
                            <TitlesLink>{t('jobs')}</TitlesLink>
                            <Link to={`/classifieds/category/Employments`}>
                                <LinkMore>{t('view_all')}</LinkMore>
                            </Link>
                        </SpaceBetween>
                        <CarouselClassifieds data={employmentClassifieds} />
                        <SpaceBetween>
                            <TitlesLink>{t('technology')}</TitlesLink>
                            <Link to={`/classifieds/category/Tech`}>
                                <LinkMore>{t('view_all')}</LinkMore>
                            </Link>
                        </SpaceBetween>
                        <CarouselClassifieds data={techClassifieds} />
                        <SpaceBetween>
                            <TitlesLink>{t('construction')}</TitlesLink>
                            <Link to={`/classifieds/category/Construction`}>
                                <LinkMore>{t('view_all')}</LinkMore>
                            </Link>
                        </SpaceBetween>
                        <CarouselClassifieds data={constructionClassifieds} />
                        <SpaceBetween>
                            <TitlesLink>{t('home_and_garden')}</TitlesLink>
                            <Link to={`/classifieds/category/Home`}>
                                <LinkMore>{t('view_all')}</LinkMore>
                            </Link>
                        </SpaceBetween>
                        <CarouselClassifieds data={homeClassifieds} />
                        <SpaceBetween>
                            <TitlesLink>{t('pets')}</TitlesLink>
                            <Link to={`/classifieds/category/Pets`}>
                                <LinkMore>{t('view_all')}</LinkMore>
                            </Link>
                        </SpaceBetween>
                        <CarouselClassifieds data={petsClassifieds} />
                        <SpaceBetween>
                            <TitlesLink>{t('music')}</TitlesLink>
                            <Link to={`/classifieds/category/Music`}>
                                <LinkMore>{t('view_all')}</LinkMore>
                            </Link>
                        </SpaceBetween>
                        <CarouselClassifieds data={musicClassifieds} />
                        <SpaceBetween>
                            <TitlesLink>{t('sports')}</TitlesLink>
                            <Link to={`/classifieds/category/Sports`}>
                                <LinkMore>{t('view_all')}</LinkMore>
                            </Link>
                        </SpaceBetween>
                        <CarouselClassifieds data={sportsClassifieds} />

                    </ListClassifiedContainer>
                    <Publish />
                </ContentClassifiedsContainer>
            </ThemeProvider>
        </div>

    )
}

export default ContentServices