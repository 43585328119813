import { useState } from "react";
import { UserOrdersContainer, Order, OrderHeader, OrderBody, OrderName, OrderDesc, OrderHeaderItem, Price, OrderTotal,VerticalLine} from "./styled";
import { H3 } from "ui/Text";
import { useTranslation } from "react-i18next";
import { Button } from "ui/Buttons";
import { IoMdEye } from "react-icons/io";
import { UserOrder} from "types";
import { parseTimestampToDate} from 'utils/common'
import OrderResume from "./order-resume";
import { getColorByStatus, getTextByStatus } from "./main";

interface Props {
    orders: UserOrder[];
}


// MAIN COMPONENT
const UserOrders = (props: Props) => {
    const { orders } = props;
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<UserOrder | null>(null);

    const handleClickOpen = (selectedOrderData: UserOrder) => {
        setSelectedOrder(selectedOrderData);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    

    return (
        <>
            <p>{t('historical_orders')}</p>
            <UserOrdersContainer>
            {orders.length > 0 ? (
                orders.map((item, index) => {
                return (
                    <Order key={index}>
                    <OrderHeader>
                        <OrderHeaderItem>
                            <OrderName>{t('order')}:</OrderName>
                            <OrderDesc>{item.numericOrderId}</OrderDesc>
                        </OrderHeaderItem>
                        <VerticalLine/>
                        <OrderHeaderItem>
                        <OrderName>{t('date')}:</OrderName>
                        <OrderDesc>{parseTimestampToDate(item.date)}</OrderDesc>
                        </OrderHeaderItem>
                        <VerticalLine></VerticalLine>
                        <OrderHeaderItem>
                        <OrderName>{t('status')}:</OrderName>
                        <OrderDesc color={getColorByStatus(item.status)}>{getTextByStatus(item.status)}</OrderDesc>
                        </OrderHeaderItem>
                        <VerticalLine/>
                            <OrderHeaderItem>
                            <OrderName>{t('quantity')}:</OrderName>
                            <OrderDesc>{item.items.length} {t('products')}</OrderDesc>
                        </OrderHeaderItem>
                    </OrderHeader>
                    <OrderBody>
                        <OrderTotal>
                        Total: <Price>${item["totalAmount"]}</Price>
                        </OrderTotal>
                        <Button onClick={() => handleClickOpen(item)}>
                            <IoMdEye style={{margin:'0px 6px'}} />{t('view')}
                        </Button>
                    </OrderBody>
                    </Order>
                );
                })
            ) : (
                <Order>
                    <H3>{t('the_list_of_orders_and_purchases_is_empty')}</H3>
                    {/* <Skeleton variant={"rounded"} width={"100%"} height={300} /> */}
                </Order>
            )}
                {
                    selectedOrder && (
                        <OrderResume    
                            open={open} 
                            handleClose={handleClose}
                            data={selectedOrder}
                        />
                    )
                }
            </UserOrdersContainer>
        </>
    );
};


export default UserOrders;