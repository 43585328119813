import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {createSagasTrigger} from 'lib/redux/sagas/trigger'
import { User, OfflineUser } from 'types/common'
import { IpGeolocationResponse } from 'hooks/system/useIpGeolocation/use-ip-geolocation'

interface IState {
  userData: User | null
  ipInfo: IpGeolocationResponse | null
  offlineUserData: OfflineUser | null
  loading: boolean
  error: boolean
}

export const initialState: IState = {
  userData: null,
  offlineUserData: null,
  ipInfo: null,
  loading: false,
  error: false,
}

export type InitialState = typeof initialState
type SetUser = PayloadAction<User | null>
type SetOfflineUser = PayloadAction<OfflineUser | null>

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: SetUser) { 
      state.userData = action.payload
    },
    setOfflineUser(state, action: SetOfflineUser) {
      state.offlineUserData = action.payload
    },
    setIpInfo(state, action: PayloadAction<IpGeolocationResponse>) {
      state.ipInfo = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    logout(state) {
      state =  {
        userData: null,
        ipInfo: null,
        loading: false,
        error: false,
        offlineUserData: null,
      }
      window.localStorage.clear()
      window.location.href = '/'
    },
  },
})

export const { setUser, setOfflineUser, setIpInfo ,setLoading, setError, logout } = userSlice.actions

export const userLoginSagas = createSagasTrigger<{ email: string; password: string }>('user_login_sagas')
export const loginGoogleSagas = createSagasTrigger<{credential: any}>('login_google_sagas')


export const userReducer = userSlice.reducer
