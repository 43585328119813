/* eslint-disable @typescript-eslint/no-shadow */
import { AlertColor } from '@mui/material';
import React from 'react';
import AlertComponent from 'ui/Alert'
type ContextProps = {
  showError: (message: string) => void
  showWarning: (message: string) => void
  showSuccess: (message: string) => void
}
const NotificationContext = React.createContext<ContextProps | null>(null)

export const NotificationProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [open, setOpen] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [severity, setSeverity] = React.useState<AlertColor | undefined>(undefined)
  const showError = (message: string) => {
    setOpen(true)
    setTimeout(() => {
      setOpen(false)
    }, 4000);
    setSeverity("error")
    setMessage(message)
  }
  const showSuccess = (message: string) => {
    setOpen(true)
    setTimeout(() => {
      setOpen(false)
    }, 4000);
    setSeverity("success")
    setMessage(message)
  }
  const showWarning = (message: string) => {
    setOpen(true)
    setTimeout(() => {
      setOpen(false)
    }, 4000);
    setSeverity("warning")
    setMessage(message)
  }
  const value = { showError, showSuccess, showWarning };
  return (
    <NotificationContext.Provider value={value}>
      <AlertComponent message={message} severity={severity} open={open} />
      {children}
    </NotificationContext.Provider>
  )
}

export const useNotification = () => {
  const context = React.useContext(NotificationContext)
  if (!context) throw new Error("No existe el contexto")
  return context
}
