import { FC, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup";
import {
  Container,
  ContainerBar,
  FormTitle,
  InputForm,
  InputWrap,
  Link,
  LoginContainer,
  LoginForm,
  LoginImg,
  LoginImgWrap,
  LoginWrap,
  Links,
  Paragraph,
  Terms,
  SpanMiddle,
  ErrorContainer
} from "./styled";
import loginSide from "assets/loginSide.png";
import { MdPerson } from "react-icons/md";
import MainLayout from "views/layout/MainLayout";
import { Sidebar } from "components/Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Input from "ui/TextInput";
import { Button } from "ui/Buttons";
import PhoneInput from "ui/PhoneInput";
import ModalLoader from "ui/ModalLoader";
import CountryPicker from "ui/CountryPicker/country-region-picker";
import DatePicker from "ui/DatePicker";
// utils
import verifyNickname from "services/login/verifyNickname";
import verifyEmailAvailability from "utils/verify-email-avalibility";
import { useNotification } from "lib/context/AlertContext";
import { setUser } from "redux/user/reducer";
// services
import createUser from "services/createUser/service-create-user";
import { useTranslation } from 'react-i18next';
import { ROLES } from "constants/roles";

function getRegisterDataSchema(t) {
  return Yup.object().shape({
    firstName: Yup.string().required(t('name_is_required')),
    firstSurname: Yup.string().required(t('last_name_is_required')),
    nickname: Yup.string(),
    phone: Yup.string().required(t('phone_required')),
    countryBorn: Yup.string().required(t('country_required')),
    state: Yup.string().required(t('state_required')),
  });
}

const RegisterUser = (props) => {
  const { onSubmit, userData } = props;
  const { showWarning } = useNotification();
  const [nicknameAvailable, setNicknameAvailable] = useState(true);
  const { t } = useTranslation();

  return (
    <>
      <FormTitle>{t('complete_your_information')}</FormTitle>
      <Formik
        initialValues={{
          ...userData,
          firstName: "",
          firstSurname: "",
          nickname: "",
          phone: "",
          countryBorn: "",
          state: "",
          roleId: ROLES.CUSTOMER,
          dateOfBirth: "",
        }}
        validationSchema={getRegisterDataSchema(t)}
        onSubmit={onSubmit}
      >
        {({ errors, touched, handleSubmit, isSubmitting, setFieldValue, values, setFieldError }) => (
          <Form>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <InputForm>
                <InputWrap>
                  <Field
                    as={Input}
                    type="text"
                    name="firstName"
                    label={t('name')}
                    error={touched.firstName && errors.firstName}
                  />
                  {errors.firstName && touched.firstName ? (
                    <ErrorContainer>
                      <ErrorMessage name="name" />
                    </ErrorContainer>
                  ) : null}
                </InputWrap>
                <InputWrap>
                  <Field
                    as={Input}
                    type="text"
                    label={t('last_name')}
                    name="firstSurname"
                    error={touched.firstSurname && errors.firstSurname}
                  />
                  {errors.firstSurname && touched.firstSurname ? (
                    <ErrorContainer>
                      <ErrorMessage name="firstSurname" />
                    </ErrorContainer>
                  ) : null}
                </InputWrap>
                <InputWrap>
                  <Field
                    as={DatePicker}
                    label={t('Fecha de nacimiento')}
                    name="dateOfBirth"
                    onChange={(value) => setFieldValue("dateOfBirth", value)}
                    error={touched.dateOfBirth && errors.dateOfBirth}
                    value={values.dateOfBirth}
                    maxAge={12}
                    minAge={100}
                    useISO8601
                  />
                  {errors.dateOfBirth && touched.dateOfBirth ? (
                    <ErrorContainer>
                      <ErrorMessage name="dateOfBirth" />
                    </ErrorContainer>
                  ) : null}
                </InputWrap>
                <InputWrap>
                  <Field
                    as={Input}
                    type="text"
                    label="Identificador unico de usuario"
                    name="nickname"
                    onChange={(e) => {
                      setFieldValue("nickname", e.target.value);
                      verifyNickname(e.target.value).then((res) => {
                        if (res) {
                          setFieldError("nickname", "Este identificador ya está en uso");
                          setNicknameAvailable(false);
                          showWarning("Este identificador ya está en uso");
                        } else {
                          setNicknameAvailable(true);
                        }
                      });
                    }}
                    error={touched.nickname && errors.nickname}
                  />
                  {errors.nickname && touched.nickname ? (
                    <ErrorContainer>
                      <ErrorMessage name="nickname" />
                    </ErrorContainer>
                  ) : null}
                  {
                    !nicknameAvailable ? (
                      <ErrorContainer>
                        <p>{t('this_identifier_is_already_in_use')}</p>
                      </ErrorContainer>
                    ) : null
                  }
                </InputWrap>
                <InputWrap zIndex={3}>
                  <Field
                    as={PhoneInput}
                    type="number"
                    label={t('phone')}
                    name="phone"
                    onChange={(value) => setFieldValue("phone", value)}
                    error={touched.phone && errors.phone}
                  />
                  {errors.phone && touched.phone ? (
                    <ErrorContainer>
                      <ErrorMessage name="phone" />
                    </ErrorContainer>
                  ) : null}
                </InputWrap>
                <InputWrap>
                  <CountryPicker
                    value={{ country: values.countryBorn, region: values.state }}
                    onChange={(value: any) => {
                      setFieldValue("countryBorn", value.country);
                      setFieldValue("state", value.region);
                    }}
                  />
                </InputWrap>
                {
                  errors.country && touched.country ? (
                    <ErrorContainer>
                      <ErrorMessage name="country" />
                    </ErrorContainer>
                  ) : null
                }
                {
                  !errors.country && errors.state && touched.state ? (
                    <ErrorContainer>
                      <ErrorMessage name="state" />
                    </ErrorContainer>
                  ) : null
                }
                <div style={{ width: "100%", display: "flex", justifyContent: "center", margin: "1.5rem 0rem" }}>
                  <Button disabled={isSubmitting || Object.keys(errors).length > 0 || !nicknameAvailable} onClick={handleSubmit} type="submit">
                    {t('create_account')}
                  </Button>
                </div>
              </InputForm>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

function getValidationSchemaEmailAndPassword(t) {
  return Yup.object().shape({
    email: Yup.string()
      .email(t('invalid_email_address'))
      .required(t('email_is_required')),
    password: Yup.string()
      .min(6, t('the_password_must_be_at_least_6_characters'))
      .max(20, t('password_must_be_at_most_20_characters'))
      .required(t('password_is_required')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], t('passwords_do_not_match'))
      .required(t('confirm_password_is_required')),
    acceptTerms: Yup.bool().oneOf([true], t('you_must_accept_the_terms_and_conditions'))
  });
}


const CreateUserByEmail = ({ onSubmit }) => { 
  const navigate = useNavigate();
  const { t } = useTranslation();
 
  return (
    <>
      <FormTitle>{t('create_an_account')}</FormTitle>
      <Formik
        initialValues={{ email: "", password: "", confirmPassword: "", acceptTerms: false }}
        validationSchema={getValidationSchemaEmailAndPassword(t)}
        onSubmit={onSubmit}
      >
        {({ errors, touched, isSubmitting, handleSubmit, setFieldValue, values }) => (
          <Form>
            <InputForm>
              <InputWrap>
                <Field
                  as={Input}
                  type="email"
                  name="email"
                  label={t('email')}
                  error={touched.email && errors.email}
                />
                {
                  errors.email && touched.email ? (
                    <ErrorContainer>
                      <ErrorMessage name="email" />
                    </ErrorContainer>
                  ) : null
                }
              </InputWrap>
              <InputWrap>
                <Field
                  as={Input}
                  type="password"
                  name="password"
                  label={t('password')}
                  error={touched.password && errors.password}
                />
                {
                  errors.password && touched.password ? (
                    <ErrorContainer>
                      <ErrorMessage name="password" />
                    </ErrorContainer>
                  ) : null
                }
              </InputWrap>
              <InputWrap>
                <Field
                  as={Input}
                  type="password"
                  name="confirmPassword"
                  label={t('confirm_password')}
                  error={touched.confirmPassword && errors.confirmPassword}
                />
                {
                  errors.confirmPassword && touched.confirmPassword ? (
                    <ErrorContainer>
                      <ErrorMessage name="confirmPassword" />
                    </ErrorContainer>
                  ) : null
                }
              </InputWrap>
            </InputForm>
            <Terms>
              <Checkbox
                style={{ color: "#615EE2", padding: "0 .4rem" }}
                defaultChecked={values.acceptTerms}
                onChange={(e) => setFieldValue("acceptTerms", e.target.checked)}
              />
              <Paragraph>
                {t('i_accept_the_terms_and_conditions')}
              </Paragraph>
            </Terms>
            <div style={{ width: "100%", display: 'flex', justifyContent: 'center', marginBottom: '1.5rem' }}>
              <Button onClick={handleSubmit} disabled={isSubmitting || Object.keys(errors).length > 0} type="submit">{t('create_account')}</Button>
            </div>
          </Form>
        )}
      </Formik>
      <Links>
        <span>{t('do_you_already_have_an_account')}</span>
        <Link onClick={() => navigate("/login")}>
          <MdPerson />
          <SpanMiddle>{t('login')}</SpanMiddle>
        </Link>
      </Links>
    </>
  );
};


interface IUser {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  firstSurname: string;
  phone: string;
  country: string;
  state: string;
}

// Main component
const Register: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showSuccess, showError } = useNotification();
  const { isAuth } = useSelector((state: any) => state.auth);

  const [userData, setUserData] = useState<IUser | object>({});
  const [loadingCreateUser, setLoadingCreateUser] = useState(false);

  const handleSubmitEmailAndPassword = async (values, { setSubmitting }) => {

    try {
      const emailAvailable = await verifyEmailAvailability(values.email);
      if (emailAvailable) {
        setUserData(values);
        setSubmitting(false);
      } else {
        showError("Este correo ya está en uso");
        setSubmitting(false);
      }
    } catch (error) {
      console.error('error verify email',error);
      showError("Error al verificar el correo");
      setSubmitting(false);
    }
  };

  const handleCreateUser = (values, { setSubmitting }) => {
    setLoadingCreateUser(true);
    createUser(values).then((res) => {
      if (res.status === 'OK') {
        showSuccess('Usuario creado correctamente');
        console.log('res create user', res);
        setTimeout(() => {
         dispatch( setUser({ ...res.data }) )
          setLoadingCreateUser(false);
          setSubmitting(false);
          navigate('/');
        }, 1000);
   
      } else {
        showError('Error al crear el usuario');
        setSubmitting(false);
        setLoadingCreateUser(false);
      }
    });
  };

  return (
    <MainLayout hideSearch={true} topbarPaddingBottom='10px'>
      <Container>
        {isAuth && (
          <Sidebar pageWrapId={"home-wrap"} outerContainerId={"home-container"} />
        )}
        <ContainerBar id="home-wrap">
          <LoginContainer>
            <LoginWrap>
              <LoginImgWrap>
                <LoginImg src={loginSide} loading="lazy" />
              </LoginImgWrap>
              <LoginForm>
                {
                  Object.keys(userData).length === 0 ? (
                    <CreateUserByEmail onSubmit={handleSubmitEmailAndPassword} />
                  ) : (
                    <RegisterUser onSubmit={handleCreateUser} userData={userData} />
                  )
                }
              </LoginForm>
            </LoginWrap>
          </LoginContainer>
        </ContainerBar>
      </Container>
      <ModalLoader isLoading={loadingCreateUser} />
    </MainLayout>
  );
};

export default Register;
