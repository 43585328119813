import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  width: 100%;
  background-color: #FDFDFE;
`

export const Terms = styled.div`
  display: flex;
  margin: 0 1.5rem;
  align-items: flex-start;
`

export const DataContainer = styled.form`
  display: flex;
  margin: 2rem auto;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
  justify-content: flex-start;
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`
export const PreviousSteps = styled.div`
  width: 70%;
  margin: 0 auto;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`
export const PreviousStep = styled.div`
  background: #F5F5F6;
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
`

export const MainStepsContainer = styled.div`
  display: flex;
  gap: 1rem;
  border-radius: 1rem;
  width: 100%;
  background: #f5f5f6;
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  //  shadow
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);

  // hover
  &:hover {
    cursor: pointer;
    transform: scale(1.03);
  }

  &:last-child {
    margin-top: 2rem;
    margin-bottom: 0;
  }
`

export const StepDetails = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

export const StepInfo = styled.div`
  display: flex;
  flex-direction: column;
`


export const StepTitle = styled.span`
  ${props => props.theme.typography.body.lg.medium};
`

export const StepDescription = styled.span`
  ${props => props.theme.typography.label.xs.regular};
  color: #406385;
`
export const Step2Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 2rem auto;
  justify-content: center;
  width: 70%;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`

export const Step2Title = styled.p`
  ${props => props.theme.typography.body.lg.medium};
`
export const Input = styled.input`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  border: 2px solid #3A36DB;
  border-radius: 1rem;
  ${props => props.theme.typography.body.lg.input};
  color: #000000;
  outline: none;
  background: white;
  @media only screen and (max-width: 768px) {
    
  }

  &:focus {
    border-color: #3A36DB;
  }

  &:focus ~ .form__label,
  &:not(:placeholder-shown)&:not(:focus) ~ .form__label {
    top: -0.5rem;
    font-size: 0.8rem;
    left: 0.8rem;
  }
`

export const ChangePassword = styled.p`
  color: #3A36DB;
  margin-left: 1rem;
  margin-top: 2rem;
  cursor:pointer;
  ${props => props.theme.typography.body.md.bold};
`

export const InputLabel = styled.label`
  position: absolute;
  left: 1rem;
  top: 1rem;
  padding: 0 2px;
  color: #000000;
  cursor: text;
  transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
  background-color: #ffffff;
  font-size: 13px;
`

export const Info = styled.small`
  color: #406385;
  ${props => props.theme.typography.label.xs.regular};
`

export const InputWrap = styled.div`
  position: relative;
  width: ${props => props.size || "80%"};
  z-index: 1;
  margin-bottom: 10px;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

export const UpdateButtonContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FDFDFE;
  align-self: center;
  border-radius: 16px;
  @media (max-width: 992px) {
    width: 100%;
  }
`
export const TransButton = styled.button`
  display: block;
  width: ${props => props.size || "auto"};
  background: ${props => props.background || "#ffffff"};
  color: ${props => props.background === '#3A36DB' ? "#F5F5F6" : "#3A36DB"};
  margin: ${props => props.margin || "0 auto"};
  border: none;
  ${props => props.theme.typography.body.md.bold};

  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`


export const ShipmentCheckContainer = styled.div`
  display: flex;
  margin: 0 1.5rem;
  align-items: center;
  vertical-align: middle;
`


export const Select = styled.select`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #3A36DB;
  border-radius: 1rem;
  font-family: inherit;
  font-size: inherit;
  color: #000000;
  outline: none;
  padding: 1rem;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Farrowdown.webp?alt=media&token=7dd41691-6faf-42d6-a14b-59247ee56365');
  background-position: 98%;
  background-repeat: no-repeat;
  &:focus {
    border-color: #3A36DB;
  }

  &:focus ~ .form__label,
  &:not(:placeholder-shown)&:not(:focus) ~ .form__label {
    top: -0.5rem;
    font-size: 0.8rem;
    left: 0.8rem;
  }
`


export const TextArea = styled.textarea`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #3A36DB;
  border-radius: 1rem;
  font-family: inherit;
  font-size: inherit;
  color: #000000;
  outline: none;
  padding: .8rem;
  background: white;

  &:focus {
    border-color: #3A36DB;
  }

  &:focus ~ .form__label,
  &:not(:placeholder-shown)&:not(:focus) ~ .form__label {
    top: -0.5rem;
    font-size: 0.8rem;
    left: 0.8rem;
  }
`

export const Paragraph = styled.p`
  ${props => props.theme.typography.body.md.regular};
  margin: 0;
  @media only screen and (max-width: 600px){
    ${props => props.theme.typography.body.md.bold};
  }
`

export const ShipmentContainer = styled.div`
  display: flex;
  width: 60%;
  margin: 2rem auto;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 1200px) {
    width: 90%;
  }
`
export const ShipmentDir = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  &:nth-child(1) {
    border-right: 1px solid #9BA1AA;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    &:nth-child(1) {
      border-right: 0;
      border-bottom: 1px solid #9BA1AA;
    }
  }
`

export const Directions = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin: 0;
  @media only screen and (max-width: 768px) {
    gap: .5rem;
  }

  @media only screen and (max-width: 420px) {
    grid-template-columns: 1fr;
  }
`
export const EmptyDirections = styled.div`
  width: 200%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  @media only screen and (max-width: 420px) {
    width: 100%;
  }
`;
  

export const DirTitle = styled.span`
  ${props => props.theme.typography.body.sm.regular};
`
export const Direction = styled.div`
  width: auto;
  max-width: 49vw;
  border: 1px solid #69727F;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  border-radius: 1rem;
  justify-content: space-between;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    padding: .5rem 1rem;
  }

  @media only screen and (max-width: 420px) {
    max-width: 100%;
  }

`
export const DirectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 2px solid #9ba1aa;
`

export const DirectionBody = styled.div`
  margin-top: 1rem;;
`

export const DirectionLabelName = styled.p`

  @media only screen and (max-width: 768px) {
    font-size: 14px;
    margin: 0;
  }
`

export const DirectionName = styled.div`
  ${props => props.theme.typography.body.lg.medium};
  margin-top: .5rem;
  width: 100%;
  display: flex;
  gap: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;


  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 0px;
  }
`


export const DirectionDesc = styled.span`
  ${props => props.theme.typography.body.lg.bold};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  @media only screen and (max-width: 768px) {
    font-size: 14px;

  }
`
export const PaymentImg = styled.img`
  width: 5%;
  @media only screen and (max-width: 1200px) {
    width: 10%;
  }
  @media only screen and (max-width: 768px) {
    width: 10%;
  }
`

export const SummaryTitle = styled.h2`
  text-align: center;
  color: #000;
  ${props => props.theme.typography.heading.sm.bold};
`

export const SummaryId = styled.p`
  color: gray;
  font-size: 16px;
`

export const SummarySubTitle = styled.p`
  text-align: center;
  ${props => props.theme.typography.label.xs.medium};

`
export const SubtitleSpan = styled.span`
  color: #F1851F;
`

export const Summary = styled.div`
  width: 70%;
  margin: 0 auto;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

export const AditionalComment = styled.p`
  color: #0C121D;
  ${props => props.theme.typography.subheading.md.regular};
`

export const SumFinalText = styled.span`
  color: #0C121D;
  ${props => props.theme.typography.subheading.sm.bold};
`

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;

`
export const SummaryText = styled.span`
  color: #406385;
  ${props => props.theme.typography.body.lg.medium};
`
export const SummarySubtotal = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`
export const SummaryFinalPrice = styled.span`
  ${props => props.theme.typography.subheading.sm.bold};
  color: #0C121D;
`
export const SummaryTotal = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
`

export const DirectionItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`
export const DirectionItemTitle = styled.span`
  ${props => props.theme.typography.subheading.sm.bold};
`
export const DirectionItemDesc = styled.div`
  ${props => props.theme.typography.body.sm.regular};
`
export const AddresInfoItem = styled.p`
  margin-right: 1rem;
  min-height: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 6px;
  ${props => props.theme.typography.body.sm.bold};
`

export const ProfilePicture = styled.div`
  position: relative;
  margin-bottom: 2rem;
  width: fit-content;
`
export const ProfileName = styled.p`
font-weight: 700;
`
export const ProfileImage = styled.img`
  width: 120px;
  height: 120px;
  border:1px solid black;
  object-fit: cover;
  border-radius: 100%;
`

export const ProfileCameraIcon = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 0;
  width: 2rem;
  height: 2rem;
  background: white;
  border-radius: 100%;
  padding: .1rem .5rem;
  border: 1px solid #3A36DB;
`
export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  @media only screen and (max-width: 992px){
    flex-direction: column;
  }
`

export const ProfileInformation = styled.div`
  display: flex;
  flex-direction: column;
`

export const PillSection = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  margin-bottom: 2rem;
`
export const SideSections = styled.div`
  border: 1px solid #cdd0d4;
  border-radius: 1rem;
  padding: 1rem;
  width: 100%;
`

export const SectionTitle = styled.p`
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 1rem;
`

export const PedidosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
export const Pedido = styled.div`
  width: 100%;
  border: 1px solid #cdd0d4;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap:1rem;
`
export const PedidoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #9ba1aa;
  padding-bottom: 1rem;
  flex-wrap: wrap;
  @media only screen and (max-width: 992px){
  }
`

export const PedidoHeaderItem = styled.div`
  @media only screen and (max-width: 992px){
    width: 48%;
  }
`
export const VerticalLine = styled.div`
  border-left: 1px solid black;
  @media only screen and (max-width: 992px) {
    &:nth-child(4) {
      display: none;
    }
  }
`

export const PedidoBody = styled.div`
  display: flex;
  justify-content: space-between;
`
export const PedidoName = styled.span`
  ${props => props.theme.typography.body.sm.regular};
`
export const PedidoDesc = styled.span`
  ${props => props.theme.typography.body.sm.bold};
  color: ${props => props.color || "#0c121d"};
`
export const PedidoTotal = styled.div`
  ${props => props.theme.typography.body.lg.medium};
`

export const PedidoPrice = styled.span`
  ${props => props.theme.typography.body.lg.bold};
`

export const Favorites = styled.span`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: .5rem;
`
export const ChangeDialog = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding:1rem;
  justify-content: center;
`
export const ChangeDialogHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
`

export const ChangeDialogHeaderTitle = styled.span`
  ${props => props.theme.typography.subheading.lg.bold};
`
export const DialogBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`


export const ContactMessage = styled.p`
  text-align: center;
  margin-top: 3rem;
  ${props => props.theme.typography.body.sm.regular};
`

export const Request = styled.div`
  background: #f5f5f6;
  width: 100%;
  border: 1px solid #cdd0d4;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap:1rem;
  box-shadow: 0 3px 8px 2px rgba(2, 64, 154, 0.16);
  @media only screen and (max-width: 768px){
    padding: .5rem;
  }
`

export const Resume = styled.div`
  width: 100%;
  border: 1px solid #cdd0d4;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap:1rem;
  margin-top: 2rem;
  @media only screen and (max-width: 992px){
    gap:0;
  }
`
export const RequestHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-start;
  padding-bottom: 1rem;
  border-bottom: 2px solid #9ba1aa;
  @media only screen and (max-width: 992px){
  }
`

export const RequestHeaderDesc = styled.p`
  margin: 0;
  ${props => props.theme.typography.body.sm.regular};
  @media only screen and (max-width: 992px){
  }
`

export const RequestHeaderPrice = styled.div`
  ${props => props.theme.typography.body.sm.bold};
  flex: 1;
  text-align: right;
  white-space: nowrap;
`
export const RequestBody = styled.div`
  /* display: flex;
  justify-content: space-between;
  flex-wrap: wrap; */
`

export const RequestImg = styled.img`
  width: 40px;
`


export const RequestHBItem = styled.div`
  @media only screen and (max-width: 992px){
    width: 48%;
  }
`


export const RequestHBItemLarge = styled.div`
  @media only screen and (max-width: 992px){
    width: 100%;
  }
`

export const RequestName = styled.span`
  ${props => props.theme.typography.body.sm.regular};
`
export const RequestDesc = styled.span`
  ${props => props.theme.typography.body.sm.bold};
  color: ${props => props.color || "#0c121d"};
`

export const ButtonViewMoreContainer = styled.div`
  display: flex;
  margin: 1rem 0rem;

  @media only screen and (max-width: 480px){
    transform: scale(.8);
    margin-left: -1rem;
  }
` 

export const Badge = styled.div`
  position: absolute;
  color: #000;
  ${props => props.theme.typography.label.xs.regular};
`

export const BadgeButton = styled.div`

`

export const IconImage = styled.img`
  width: 28px;
  height: 28px;
  object-fit: cover;
`