import { useState } from 'react';
import CreateLandingNeedService from 'services/needs/ create-landing-need/create-need-service';

const useCreateLandingNeedRequest = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState(null);

    const createLandingNeedRequest = async (props: any) => {
        setIsLoading(true);
        try {
            const response = await CreateLandingNeedService(props);
            setIsSuccess(true);
            return response;
        } catch (err: any) {
            setError(err);
        } finally {
            setIsLoading(false);
        }
    };

    return {
        createLandingNeedRequest,
        isLoading,
        isSuccess,
        error,
    };
};

export default useCreateLandingNeedRequest;