import { useEffect } from 'react';
import { MdLocationPin } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { getCountry } from "redux/common";
import Price from "components/Price";
import Tooltip from 'ui/Tooltip';
import  ContentCardClassified  from "./content-card-by-type";
import { capitalize } from "lodash";
import { ClassifiedsRoutes } from "constants/classifieds";
import { DescriptionCard, CardImageClassified, Location, TitleCard, CardContainer, ContentCard, CrudActions, ItemAction, Row, TextStatus } from "./styled";
import useDeleteClassified from 'hooks/classifieds/useCreateClassified/use-delete-classified';
import { useNotification } from 'lib/context/AlertContext';
import useEditClassified from 'hooks/classifieds/useUpdateClassified/use-update-classified';
import { MAIN_ROUTES } from 'constants/routes';
import { parseDateIso8601ToDate } from 'utils/common';
import {getColorStatus} from './main';

interface ClassifiedCardProps {
    isOwner?: boolean;
    id?: string;
    data: any;
}
// Se agrego que busque con el selector de country mas no se le asigno un pais como tal
const Classified = (props: ClassifiedCardProps) => {
    const { data, isOwner } = props;
    const { t } = useTranslation();
    console.log('data del classified', data);
    const navigate = useNavigate();
    const country = useSelector(getCountry);
    const { title, images, name, image, price, description, uid, location, properties, imagesReference, categoryType, category, status, reasonToReject, approvedAt
    } = data;
    const { deleteClassified, response: responseDeleteClassified } = useDeleteClassified();
    const {showSuccess, showError} = useNotification();
    const { dataClassifiedEdited: responseEditClassified, } = useEditClassified(); 
    
    const handleEditClick = async () => {
        navigate(MAIN_ROUTES.ClassifiedEdit, { state: {
            id: uid,
            category: categoryType,
            categoryName: category,
            classifiedData: data,
        } })
        };
    
    
    useEffect(() => {
        if (responseEditClassified) {
                if (responseEditClassified.success) {
                    showSuccess("Clasificado editado con éxito");
                } else {
                    showError("El clasificado no se a podido editar");
                }
            }
        }, [responseEditClassified]);
    
    
    
    const handleDeleteClick = async () => {

         const resultDeleted =  await deleteClassified({
            classifiedId: uid,
            imagesReference: imagesReference,
            pathCollection: `${country}/${ClassifiedsRoutes.Posts}`,
        });

    };

    useEffect(() => {
        if (responseDeleteClassified) {
            if (responseDeleteClassified.success === true) {
                showSuccess("Clasificado eliminado con éxito");
            }

            if (responseDeleteClassified.success === false) {
                showError("Ha ocurrido un error al eliminar el clasificado");
            }
        }

    }, [responseDeleteClassified])




    return (
        <CardContainer >
            <CardImageClassified src={images ? images[0] : image} onClick={() => navigate(`/${ClassifiedsRoutes.DetailClassified}?id=${uid}`, { state: { route: `${country}/Classifieds/Posts` } })}/>
            <ContentCard  onClick={() => navigate(`/${ClassifiedsRoutes.DetailClassified}?id=${uid}`, { state: { route: `${country}/Classifieds/Posts` } })}>
                <Row>
                    {price && <Price value={price} currencySymbol="$"/>}
                    {
                        isOwner ? (
                            <Tooltip title={status === 'rejected' ? reasonToReject : status === 'approved' ? `Aprobado el ${parseDateIso8601ToDate(approvedAt)}` : 'Pendiente de aprobación' } backgroundTitle={getColorStatus(status)}>
                                <TextStatus   color={getColorStatus(status)}>{t(status)}</TextStatus>
                            </Tooltip>
                        ) :
                            null
                    }
                </Row>

                <TitleCard>{title ? capitalize(title) : capitalize(name)}</TitleCard>
                {
                    properties?.length > 2 ? <ContentCardClassified properties={properties} /> : <DescriptionCard>{description}</DescriptionCard>
                    
                }                
            </ContentCard>
            <Location>
                {
                    location && (<><MdLocationPin /> {location?.label} </>)
                }
            </Location>
            {
                isOwner ? (
                    <CrudActions>
                        <ItemAction  type='edit' onClick={handleEditClick}>
                            Editar
                        </ItemAction>
                        <ItemAction type='delete' onClick={handleDeleteClick}>
                            Eliminar
                        </ItemAction>
                    </CrudActions>
                ) : null
            }
        </CardContainer>
    )
};

export default Classified;
