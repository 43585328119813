
import { FlagImage, Row } from "./styled";
import { PANAMA_FLAG_URL } from "constants/images";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { THEME } from "constants/theme/theme";

const CountryContentConfigModal = () => {

    return (
        <div>
            <h6>Seleccionar país</h6>
            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <FlagImage src={PANAMA_FLAG_URL} alt="Panama flag" title="Panama flag" />
                <Row>
                    <span>Panamá</span>
                    <CheckCircleIcon style={{ color: THEME.colors.positive80, fontSize: '16px' }} />
                </Row>
            </div>
        </div>
    );

}

export default CountryContentConfigModal;