import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Container, Grid, CardItem } from "./styled";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { THEME } from "constants/theme/theme";
import { P } from "ui/Text";


interface CardSelectTypeAddressProps {
    value: string[];
    onChange: (value: string[]) => void;
    label?: string;
    errorMessage?: string | string[];
    disabled?: boolean;
}

const TYPES_ADDRESS = ['billing', 'shipment'];


const CardSelectTypeAddress: FC<CardSelectTypeAddressProps> = (props: CardSelectTypeAddressProps) => {
    const { value, onChange, label, errorMessage, disabled } = props;
    const { t } = useTranslation();

    function getLabel(value: string) {
        switch (value) {
            case 'billing':
                return t('billing');
            case 'shipment':
                return t('shipment');
            default:
                return '';
        }
    }

    function handleCardClick(value: string) {
        
        if (props.value.includes(value)) {
            onChange(props.value.filter((item) => item !== value));
        } else {
            onChange([...props.value, value]);
        }
    }

    return (
        <Container>
            <P style={{marginBottom: '.5rem', fontSize: '14px', color: THEME.colors.black80}}>{label ? label : `${t('address_type')}`}</P>
            <Grid>          
                {
                    TYPES_ADDRESS.map((type) => {
                        return (
                            <CardItem
                                key={type}
                                selected={value.includes(type)}
                                disabled={disabled}
                                onClick={() => {
                                    if (!disabled) {
                                        handleCardClick(type);
                                    }
                                }}
                            >
                                {
                                    type === 'billing' ? <AttachMoneyIcon style={{ color: disabled ? THEME.colors.black40 : THEME.colors.primary }} /> : <LocalShippingIcon style={{ color: disabled ? THEME.colors.black40 : THEME.colors.primary }} />
                                }
                                {getLabel(type)}
                            </CardItem>
                        )
                    }
                    )
                }
            </Grid>
            <p>
                {
                    errorMessage
                }
            </p>
        </Container>
    )
}

export default CardSelectTypeAddress;
