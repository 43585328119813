

export enum CurrencySymbol {
    USD = '$',
    EUR = '€',
    GBP = '£',
    JPY = '¥',
    RUB = '₽',
    AUD = 'A$',
    CAD = 'C$',
    CHF = 'CHF',
    CNY = 'CN¥',
    HKD = 'HK$',
    NZD = 'NZ$',
    SEK = 'SEK',
    COP = 'COP',
    MXN = 'MXN',
    BRL = 'R$',
    ARS = 'ARS',
    PAB = 'PAB',
    CLP = 'CLP',
    VEF = 'VEF',
    PEN = 'PEN',
    UYU = 'UYU',
}

export enum CurrencyName {
    USD = 'USD',
    EUR = 'EUR',
    GBP = 'GBP',
    JPY = 'JPY',
    RUB = 'RUB',
    AUD = 'AUD',
    CAD = 'CAD',
    CHF = 'CHF',
    CNY = 'CNY',
    HKD = 'HKD',
    NZD = 'NZD',
    SEK = 'SEK',
    COP = 'COP',
    MXN = 'MXN',
    BRL = 'BRL',
    ARS = 'ARS',
    PAB = 'PAB',
    CLP = 'CLP',
    VEF = 'VEF',
    PEN = 'PEN',
    UYU = 'UYU',
}