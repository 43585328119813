import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  min-height: 600px;
  
  @media only screen and (max-width: 992px) {
  }
`;

export const ShippingDaysContainer = styled.div`
  margin-top: .5rem;

  @media only screen and (max-width: 992px) {
    text-align: center;
  }
`

export const FeatureContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`

export const FeatureLabel = styled.div`
  font-weight: 700;
`

export const FeatureValue = styled.div`
`

export const ContentProductsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const ImagesContainer = styled.div`
  width: 40%;

  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`



export const GalleryContainer = styled.div`

`
export const SpanReview = styled.span`
  ${props => props.theme.typography.body.md.regular};
  vertical-align: middle;
`
export const Ratings = styled.div`
  color: #3A36DB;
  display: flex;
  align-items: center;
  gap:.5rem;
  ${props => props.theme.typography.subheading.lg.bold}
  
  @media only screen and (max-width: 992px) {
    justify-content: center;
  }
  
`

export const ItemContainer = styled.div`
  width: 60%;
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
`

export const BuyButtonsContainer = styled.div`
  display: block;
  float: right;
  justify-content: flex-end;
  gap: 1rem;

  @media only screen and (max-width: 992px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
  }
`

export const BuyButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;
  justify-content: center;

`

export const Header = styled.div`
  ${props => props.theme.typography.subheading.lg.bold};
  margin-bottom: 1rem;
`

export const Options = styled.div`
   width: 100%;
`

export const General = styled.div`

`


export const Name = styled.h1`
  color: #0C121D;
  font-family: Outfit, sans-serif;
  font-weight: 700;
  margin-bottom: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  
  @media only screen and (max-width: 768px) {
    font-size: 1.9rem;
    margin-top: 10px;
  }

  // max 480
  @media only screen and (max-width: 480px) {
    font-size: 1.6rem;
  }

`
export const SKU = styled.span`
  ${props => props.theme.typography.body.md.bold}
`
export const Availability = styled.span`
  color: #038A25;
  ${props => props.theme.typography.body.md.bold}
`
export const TextH3 = styled.h3`
  color: #102746;
  ${props => props.theme.typography.subheading.sm.semibold}
  margin: .5rem 0 1rem 0;
`
export const GeneralText = styled.div`
  ${props => props.theme.typography.body.lg.medium};
  color: #0C121D;
`
export const GeneralTitle = styled.h2`
  color: #406385;
  margin-bottom: 10px;
  ${props => props.theme.typography.subheading.md.semibold};
`

export const Reviews = styled.div`
`

export const Review = styled.div`
`

export const ReviewHeader = styled.div`
`

export const ReviewProfile = styled.div`
  border: 1px solid #9BA1AA;
  background: #EBEAFC;
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;

  border-radius: 100%;
  width: 50px;
  height: 50px;
  text-align: center;
`
export const ReviewName = styled.span`
  ${props => props.theme.typography.subheading.xs.semibold};
  color: #406385;
`
export const ReviewRating = styled.div`
  color: #3A36DB;
  ${props => props.theme.typography.subheading.lg.bold};
`
export const ReviewLocation = styled.p`
  line-height: 14.63px;
  color: #0C121D;
  ${props => props.theme.typography.body.sm.regular};
`
export const ReviewOption = styled.p`
  color: #0C121D;
  line-height: 14.63px;
  ${props => props.theme.typography.body.sm.regular};
`
export const ReviewDesc = styled.p`
  color: #0C121D;
  line-height: 17.07px;
  ${props => props.theme.typography.body.md.regular};
`

// Counter component

export const TextCounter = styled.p`
  ${props => props.theme.typography.body.md.regular};
  font-size: .7rem;
  color: #0C121D;
  margin: -2px 0px;
  text-align: center;
`

export const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 1rem;


  @media only screen and (max-width: 992px) {
   gap: 4rem;
  }
`

export const CounterAndText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
`

export const ButtonGoToCart = styled.button`
  display: flex;
  align-items: center;
  gap: 1rem;
  background: #3A36DB;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: .3s;
  ${props => props.theme.typography.body.md.bold};
  &:hover {
    background: #2C2A8E;
  }
  // animacion como si fuese un latido de corazon
  animation: pulse 2s infinite;
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }




`
