// import {ServiceResponse} from '@types'
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../config/firebase";

/**
 * @param credential 
 * @description service to login with google 
 */
const loginWithGoogle = async (credential): Promise<any> => {
  try {
     // Llamada a la función en la nube y espera a que se resuelva la promesa
     const googleAuth = await httpsCallable(functions, 'googleProviderAuth')(credential)
     .then((result) => {
       return result.data;
     })
     .catch((error) => {
       console.log("error cloud function ", error);
     });

  return { ok: true, data: googleAuth}
  }
    catch (err) {
      console.log('error login with google', err)
    return { ok: false, data: err }
    }

}

export default loginWithGoogle
