import { useEffect } from "react";
import { useGetFirestoreCollection } from "hooks/useGetCollectionFirestore/useGetCollectionFirestore";
import { Container } from "./styled";
import { DESTINATIONS_PATH } from "./constants";
import ExtraServicesYachts from "modules/services/pages/yacht/components/Extras/extras-services-container";
import { useTranslation } from "react-i18next";

interface Props {
  category: string;
}

const RelatedServices = (props: Props) => {
  const { category } = props;
  const { t } = useTranslation();

  const {
    collectionData: destinationsList,
    fetchCollection: fetchDestinations,
  } = useGetFirestoreCollection();
  const {
    collectionData: extraServicesData,
    fetchCollection: fetchExtraServiceData,
  } = useGetFirestoreCollection();

  useEffect(() => {
    if (category === "Yatchs") {
      fetchDestinations({
        path: DESTINATIONS_PATH,
      });

      fetchExtraServiceData({
        path: "PANAMA/Services/Categories/Yatchs/ExtraServices",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {category === "Yatchs" && (
        <>
          <ExtraServicesYachts
            data={extraServicesData}
            title={t('extra_services')}
            backgroundColor="transparent"
            hasLink={false}
            path="services/yatchs"
            type="Yatchs"
          />
        </>
      )}
    </Container>
  );
};

export default RelatedServices;
