import { useState, useRef, useEffect } from "react";
import { Options } from "../styled";
import { Container } from "./styled";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs, Navigation, Pagination } from 'swiper/modules';
import VideoPlayer from "components/VideoPlayer";
import Modal from "ui/Modal";
import { ContainerImage, Img, VideosContainer, SwipperContainer, VideoThumbnailContainer, PlayIconOverlay } from "./styled";
// import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './styles.css';
import { TextH3 } from "../styled";


interface Props {
    images: any[];
    currentSelected: number;
    onSelectImage: (index: number) => void;
}

interface ModalVideoProps {
    open: boolean;
    onClose: () => void;
    videos: string[];
    initialIndex: number;
}

const ModalVideo = ({ open, onClose, videos, initialIndex }: ModalVideoProps) => {

    const [indexCurrent, setIndexCurrent] = useState<number>(initialIndex);
    const [stopAllVideos, setStopAllVideos] = useState<boolean>(false);
    const swiperRef = useRef<any>(null);

    useEffect(() => {
        if (initialIndex !== undefined) {
            setIndexCurrent(initialIndex);
        }
    }, [initialIndex]);


    const handleOnClose = () => {
        setStopAllVideos(true);
        setIndexCurrent(0);
        onClose();
    }

    useEffect(() => {
        if (stopAllVideos) {
            setTimeout(() => {
                setStopAllVideos(false);
            }, 1000);
        }

        if (indexCurrent < 0) {
            setStopAllVideos(true);
        }
    }, [indexCurrent]);

    useEffect(() => {
        if (swiperRef.current && indexCurrent >= 0 && indexCurrent < videos.length) {
            swiperRef.current.slideTo(indexCurrent);
        }
    }, [indexCurrent, videos.length]);

    return (
        <Modal
            isOpen={open}
            onClose={handleOnClose}
            title="Vídeos"
            styleContent={{
                height: '80vh',
            }}
        >
            <Container>
                <SwipperContainer>
                    <Swiper
                        modules={[Navigation, Pagination]}
                        slidesPerView={1}
                        navigation={true}
                        pagination={{
                            dynamicBullets: true,
                            clickable: true
                        }}
                        onSwiper={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                        onNavigationNext={() => {
                            setIndexCurrent(indexCurrent + 1);
                        }}
                        onNavigationPrev={() => {
                            setIndexCurrent(indexCurrent - 1);
                        }}
                    >
                        {videos?.map((video: any, index: number) => (
                            <SwiperSlide key={index}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                    }}
                                >
                                    <VideoPlayer
                                        url={video}
                                        controls={true}
                                        playing={!stopAllVideos && indexCurrent === index}
                                        volume={0}
                                        stylePlayerContainer={{
                                            // maxWidth: '40vw',
                                            // maxHeight: '40vh'
                                        }}
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </SwipperContainer>
                {videos.length > 1 && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '1rem',
                            marginTop: '1rem',
                        }}
                    >
                        {videos.map((video: any, index: number) => (
                            <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '1rem',
                                    marginTop: '1rem'
                                }}
                            >
                                <video
                                    src={video}
                                    style={{ width: '80px', height: '80px' }}
                                    onClick={() => setIndexCurrent(index)}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </Container>
        </Modal>
    )
}

export const Image = (props) => {
    const { image } = props;
    return (
        <ContainerImage>
            <Img loading="lazy" alt="Imagen principal del Producto" title="Imagen principal del Producto" src={image?.original || image} />
        </ContainerImage>
    );
};

const countVideos = (images: any[]) => {
    let count = 0;
    images.forEach((image) => {
        if (image.includes('.mp4')) {
            count++;
        }
    });
    return count;
}

const filterImages = (images: any[]) => {
    return images.filter((image) => !image.includes('.mp4')) || [];
}

const filterVideos = (images: any[]) => {
    return images.filter((image) => image.includes('.mp4')) || [];
}

const ImageThumbnails = (props: Props) => {
    const { images, onSelectImage, currentSelected } = props;
    const [openVideoModal, setOpenVideoModal] = useState<boolean>(false);
    const [selectedVideoIndex, setSelectedVideoIndex] = useState<number>(0);

    const handleSelectImage = (index: number) => {
        onSelectImage(index);
    }

    const handleOpenVideoModal = (index: number) => {
        setSelectedVideoIndex(index);
        setOpenVideoModal(true);
    }

    return (
        <Options>
            <Swiper
                modules={[Thumbs, Navigation]}
                watchSlidesProgress
                onSwiper={(swiper) => { }}
                slidesPerView={window.innerWidth > 768 ? 6 : 4}
                spaceBetween={10}
                navigation
            >
                {filterImages(images)?.map((image: any, index: number) => (
                    <SwiperSlide key={index}>
                        <img
                            style={{ width: '80px', height: '80px', border: index === currentSelected ? '1px solid #000' : 'none' }}
                            key={index}
                            src={image}
                            loading="lazy"
                            alt='Imagen de Producto'
                            title='Imagen de Producto'
                            onClick={() => handleSelectImage(index)}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
            {countVideos(images) > 0 && (
              <>
                <TextH3 style={{ marginTop: '30px' }}>Vídeos ({countVideos(images)})</TextH3>
                <VideosContainer>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        {filterVideos(images).map((video: any, index: number) => (
                        <VideoThumbnailContainer key={index} onClick={() => handleOpenVideoModal(index)}>
                            <video
                                src={video}
                            />
                            <PlayIconOverlay>
                                <PlayArrowIcon style={{ fontSize: '2rem', color: 'white' }} />
                            </PlayIconOverlay>
                        </VideoThumbnailContainer>
                        ))}
                    </div>
                </VideosContainer>
              </>
            )}
            <ModalVideo
                open={openVideoModal}
                onClose={() => setOpenVideoModal(false)}
                videos={filterVideos(images)}
                initialIndex={selectedVideoIndex}
            />
        </Options>
    )
}

export default ImageThumbnails;
