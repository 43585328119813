import { LocalProductCategory } from "../types/common";

export const LOGISTIC_PANAMA_PHONE_NUMBER = '50764055638';
export const GENERAL_PANAMA_PHONE_NUMBER = '50764050893';

// STATIC VALUES

export const MAX_LENGTH_DESCRIPTION = 5000;
export const MAX_LENGTH_TITLE = 100;
export const MAX_IMAGES_12 = 12;

// LOGOS
export const LOGO_WHITE_SPANISH = 'https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Flogos%2FSpanish%2Flogo_white_wdyn7.png?alt=media&token=73d0a258-5e43-47e7-a03b-1a3743053b59'
export const LOGO_WHITE_ENGLISH = 'https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Flogos%2FEnglish%2FLogo%20EN.webp?alt=media&token=9b5bdfbf-3b8e-4679-b16b-0d11a8caf439';


export const BREAKPOINTS = {
    sm: 500,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
    xxxl: 1600,
    max: 3000,
}

// :::::: GENERAL :::::: //


export const LEVELS_URGENCY_NEEDS = [
    {
        value: "high",
        label: "Alto",
    },
    {
        value: "medium",
        label: "Medio",
    },
    {
        value: "low",
        label: "Bajo",
    },
];

// :::::: PRODUCTS :::::: //

export const EMPTY_PRODUCT_CATEGORY = 0

export const STORE_PRODUCTS_CATEGORIES: LocalProductCategory[] = [
    {
        id: 1,
        name: {
            es: 'Productos Recientes',
            en: 'Recent Products'
        },
        slug: 'productos-recientes'
    },
    {
        id: 1277,
        name: {
            es: 'Entrega inmediata',
            en: 'Immediate delivery'
        },
        slug: 'entrega-inmediata'
    },
    {
        id: 1278,
        name: {
            es: 'Productos destacados',
            en: 'Featured products'
        },
        slug: 'productos-destacados'
    },
    {
        id: 198,
        name: {
            es: 'Productos en tendencia',
            en: 'Trending products'
        },
        slug: 'productos-en-tendencia'
    },
    {
        id: 199,
        name: {
            es: 'Mujer',
            en: 'Woman'
        },
        slug: 'mujer'
    },
    {
        id: 140,
        name: {
            es: 'Accesorios para vehículos',
            en: 'Vehicle accessories'
        },
        slug: 'entrega-inmediata'
    },
    {
        id: 1270,
        name: {
            es: 'Audífonos',
            en: 'Headphones'
        },
        slug: 'entrega-inmediata'
    },
    {
        id: 224,
        name: {
            es: 'Perfumes para hombres',
            en: 'Men\'s perfumes'
        },
        slug: 'perfumes-para-hombres'
    },
    {
        id: 53,
        name: { 
            es: 'Deportes y aire libre',
            en: 'Sports & Outdoors'
        },
        slug: 'deportes-y-aire-libre'
    },
    {
        id: 186,
        name: {
            es: 'Salud y belleza',
            en: 'Health & Beauty'
        },
        slug: 'salud-y-belleza'
    },
    {
        id: 130,
        name: {
            es: 'Niños',
            en: 'Kids'
        },
        slug: 'ninos'
    },
    {
        id: 219,
        name: {
            es: 'Arte',
            en: 'Art'
        },
        slug: 'arte'
    },
    {
        id: 1269,
        name: {
            es: 'Asistentes inteligentes',
            en: 'Smart assistants'
        },
        slug: 'asistentes-inteligentes'
    },
    {
        id: 186,
        name: {
            es: 'Salud y belleza',
            en: 'Health & Beauty'
        },
        slug: 'salud-y-belleza'
    },
    {
        id: 206,
        name: {
            es: 'Perfumes',
            en: 'Perfumes'
        },
        slug: 'perfumes'
    },
    {
        id: 131,
        name: {
            es: 'Electrónica',
            en: 'Electronics'
        },
        slug: 'electronica'
    },
    {
        id: 1266,
        name: {
            es: 'Domótica',
            en: 'Home automation'
        },
        slug: 'domotica'
    },
    {
        id: 35,
        name: {
            es: 'Para ellos',
            en: 'For them'
        },
        slug: 'hombres'
    },
    {
        id: 60,
        name: {
            es: 'Hogar',
            en: 'Home'
        },
        slug: 'hogar'
    },
    {
        id: 314,
        name: {
            es: 'Juguetes para niñas',
            en: 'Toys for girls'
        },
        slug: 'juguetes-para-ninas'
    },
    {
        id: 313,
        name: {
            es: 'Juguetes para niños',
            en: 'Toys for kids'
        },
        slug: 'juguetes-para-ninos'
    },
    {
        id: 135,
        name: {
            es: 'Suplementos',
            en: 'Supplements'
        },
        slug: 'suplementos'
    },
    {
        id: 306,
        name: {
            es: 'Juguetes',
            en: 'Toys'
        },
        slug: 'juguetes'
    },
    {
        id: 272,
        name: {
            es: 'Tecnología',
            en: 'Technology'
        },
        slug: 'tecnologia'
    }
]

export const STORE_WHOLESALE_PRODUCTS_CATEGORIES: LocalProductCategory[] = [
    {
        id: 1289,
        name: {
            es: 'Productos al mayor',
            en: 'Wholesale products'
        },
        slug: 'productos-al-mayor'
    }
]


// :::::: SERVICES :::::: //

export const CATEGORIES_SERVICES_IDS = {
    machinery: 1301,
    yachts: 205,
    smartHome: 1266,
    home: 60,
    tech: 272,
}

// Status classified

export enum StatusClassified {
    pending = "pending",
    approved = "approved",
    rejected = "rejected",
    finished = "finished",
}

// Checkout

export enum PaymentMethodEnum {
    card = "card",
    paypal = "paypal",
    cash = "cash",
    yappy = "yappy",
}

export enum PaymentMethodIdEnum {
    card = 2,
    paypal = 3,
    cash = 4,
    yappy = 5,
}
