import styled from 'styled-components';

export const UserOrdersContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`

export const Order = styled.div`
    width: 100%;
    border: 1px solid #cdd0d4;
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap:1rem;
`

export const OrderHeader = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #9ba1aa;
    padding-bottom: 1rem;
    flex-wrap: wrap;
`

export const OrderHeaderItem = styled.div`
    @media only screen and (max-width: 992px){
        width: 48%;
    }
`
export const VerticalLine = styled.div`
    border-left: 1px solid black;
    @media only screen and (max-width: 992px) {
        &:nth-child(4) {
        display: none;
        }
    }
`

export const OrderBody = styled.div`
    display: flex;
    justify-content: space-between;
`
export const OrderName = styled.span`
    ${props => props.theme.typography.body.sm.regular};
`
export const OrderDesc = styled.span`
    ${props => props.theme.typography.body.sm.bold};
    color: ${props => props.color || "#0c121d"};
`
export const OrderTotal = styled.div`
    ${props => props.theme.typography.body.lg.medium};
`

export const Price = styled.span`
    ${props => props.theme.typography.body.lg.bold};
`

export const SummaryTitle = styled.h2`
    text-align: center;
    color: #102746;
    ${props => props.theme.typography.heading.sm.bold};
`

export const SummarySubTitle = styled.p`
    text-align: center;
    ${props => props.theme.typography.label.xs.medium};
`
export const SubtitleSpan = styled.span`
    color: #F1851F;
    margin: 0px 10px;
`

export const Summary = styled.div`
    width: 70%;
    margin: 0 auto;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`
export const Item = styled.div`
    background: #F5F5F6;
    display: flex;
    padding: .5rem;
    border-radius: 10px;
    box-shadow: 0px 4px 8px 2px rgba(2, 64, 154, 0.16);
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
`
export const ItemImg = styled.img`
    width: 80px;
    height: 80px;

`
export const ItemDesc = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 90%;
    @media only screen and (max-width: 1200px) {
        width: 80%;
    }
`

export const ItemQty = styled.span`
    ${props => props.theme.typography.body.sm.bold};
`
export const ItemName = styled.span`
    ${props => props.theme.typography.body.sm.regular};
`
export const ItemPrice = styled.span`
    white-space: nowrap;
    width: 10%;

    ${props => props.theme.typography.body.sm.bold};
    @media only screen and (max-width: 1200px) {
        width: 15%;
    }
`;

export const Resume = styled.div`
    width: 100%;
    border: 1px solid #cdd0d4;
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap:1rem;
    margin-top: 2rem;
    @media only screen and (max-width: 992px){
        gap:0;
    }
`

export const SumFinalText = styled.span`
    color: #0C121D;
    ${props => props.theme.typography.subheading.sm.bold};
`

export const SpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;

`
export const SummaryText = styled.span`
    color: #406385;
    ${props => props.theme.typography.body.lg.medium};
`
export const SummarySubtotal = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`
export const SummaryFinalPrice = styled.span`
    ${props => props.theme.typography.subheading.sm.bold};
    color: #0C121D;
`
export const SummaryTotal = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: flex-end;
`

export const FinalFooter = styled.div`
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
`

export const FooterItem = styled.div`
    width: 100%;
`
export const FooterItemTitle = styled.span`
    ${props => props.theme.typography.subheading.sm.bold};
`
export const FooterItemDesc = styled.p`
    ${props => props.theme.typography.body.sm.regular};
`
export const FooterItemSpan = styled.span`
    margin: 0rem .5rem;
    ${props => props.theme.typography.body.sm.bold};
`
