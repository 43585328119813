import React from "react";
import {useNavigate} from "react-router-dom";
import {Browser, BrowserBody, BrowserImg, BSpan, BText, SmallSection, TransButton} from "./styled";

import bannerAd from "assets/bannerPub.png";

export const Publish = (props: any) => {
    let navigate = useNavigate();
    return (
        <SmallSection pav="2rem" pah="2rem">
            {/* <Browser>
                <BrowserBody>
                    <BText>ANUNCIA <BSpan>TU CLASIFICADO</BSpan> GRATIS</BText>
                    <TransButton onClick={()=>navigate("/publishClassified")} fondo={"#3A36DB"}>¡Publicar!</TransButton>
                </BrowserBody>
                    <BrowserImg src={bannerAd} className={"img-fluid"} alt=""/>
            </Browser> */}
        </SmallSection>
    );
};