import { Fragment } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { color, ColorProps, layout, LayoutProps, position, PositionProps, space, SpaceProps } from 'styled-system'
import {BackButtonContainer, TextButton} from './styled'
import { Link } from 'react-router-dom'
import { THEME } from 'constants/theme/theme'
import ArrowLeftIcon from 'assets/icons/ArrowLeftIcon'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


export type BlockProps = LayoutProps & SpaceProps & ColorProps & PositionProps

export const StyledButton = styled.div`
  font-family: Outfit, monospace;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  background-color: #3A36DB;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  height: 40px;
  padding: 8px 16px;
  min-width: 120px;
  max-width: 500px;
  &:hover {
    opacity: 0.9;
  }
  
  @media (max-width: 1080px) {
    font-size: 0.9rem;
  }

`


export const LinkButton = styled(Link) <BlockProps >`
  color: ${(props) => props.theme.colors.primary80};
  font-weight: bold;
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${layout}
  ${space}
  ${color}
  ${position}
  width: ${(props) => props.width || 'auto'};

  @media (max-width: 1080px) {
    font-size: 0.7rem;
  }
  @media (max-width: 620px) {
    font-weight: 700;
    font-size: 0.9rem;
  }
`

export const StrokedButton = styled.div<BlockProps>`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  font-weight: 700;
  background-color: transparent;
  color: ${(props) => (props.disabled ? '#D3D3D3' : '#406385')};
  height: ${(props) => props.height || '40px'};
  padding: 0.2rem 1rem;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #406385;
  text-align: center;
  ${layout}
  ${space}
  ${color}
  ${position}
  font-size: 0.8rem;
  min-width: 160px;

  &:hover {
    background-color: ${(props) => (props.disabled ? 'transparent' : '#406385')};
    color: ${(props) => (props.disabled ? '#D3D3D3' : '#fff')};
  }

  @media (max-width: 1080px) {
    font-size: 0.8rem;
  }

  @media (max-width: 620px) {
    font-size: 0.75rem;
  }

  @media (max-width: 480px) {
    font-size: 0.7rem;
  }
`;

interface IButton {
  disabled?: boolean;
  onClick?: () => void;
  onDisabledClick?: () => void;
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset'
  style?: React.CSSProperties
  width?: string
  backgroundColor?: string
  IconRight?: React.ReactNode
}

export const Button = (props: IButton) => {
  const { disabled, onClick, onDisabledClick, children, style, width, backgroundColor, IconRight } = props

  const bgColor = disabled ? '#D3D3D3' : backgroundColor

  return (
    <StyledButton style={{
      ...style,
      backgroundColor: bgColor,
      width: width
    }} onClick={!disabled ? onClick : onDisabledClick}>
      <Fragment>
        {children}
        {IconRight && <span style={{ marginLeft: '8px' }}>{IconRight}</span>}
      </Fragment>
    </StyledButton>
  )
}

 const StyledCancelButton = styled.div`
  font-family: Outfit, monospace;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  background-color: #fff;
  color: ${THEME.colors.black80};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  height: 40px;
  padding: 8px 0px;
  max-width: 500px;
  &:hover {
    opacity: 0.9;
    transform: scale(1.05);
  }

`

export const CancelButton = (props: IButton) => {
  const { disabled, onClick, onDisabledClick, children, style, width, backgroundColor } = props

  const bgColor = disabled ? '#D3D3D3' : backgroundColor

  return (
    <StyledCancelButton style={{
      ...style,
      backgroundColor: bgColor,
      width: width
    }} onClick={!disabled ? onClick : onDisabledClick}  >{children}</StyledCancelButton>
  )
}

interface IBackButtonProps {
  onClick?: () => void
  style?: React.CSSProperties
  text?: string
  hideIcon?: boolean
}

export const BackButton = ({ onClick, style, text, hideIcon }: IBackButtonProps) => {
  const { t } = useTranslation()

  return (
    <BackButtonContainer onClick={onClick} style={style}>
      {
        !hideIcon && <ArrowLeftIcon />
      }
      <TextButton>{text === 'none' ? '' : !text ? `${t('back')}` : text }</TextButton>
    </BackButtonContainer>
  )
}

const StyledButtonViewMoreAndLess = styled.div`

  font-family: Outfit, monospace;
  font-weight: 600;
  font-size: 1rem;
  color: ${THEME.colors.white};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 4px 8px 2px 8px;
  min-width: 120px;
  max-width: 500px;
  color:  #FBFBFB;
  background-color: ${THEME.colors.primary};
  border-radius: 50px;
  &:hover {
    opacity: 0.9;
  }
  
  @media (max-width: 1080px) {
    font-size: 0.9rem;
  }

`

interface IButtonViewMoreAndLessProps {
  type: 'more' | 'less'
  onClick?: () => void
  style?: React.CSSProperties,
  IconRight?: React.ReactNode
}

export const ButtonViewMoreAndLess = ({ type, onClick, style, IconRight }: IButtonViewMoreAndLessProps) => {

  const { t } = useTranslation()

  return (
    <StyledButtonViewMoreAndLess onClick={onClick} style={style}>
      <p style={{margin: '0px 0px 4px 0px' }}>{type === 'more'? t('view_more') : t('view_less')}</p>
      {
        type === 'more' ? <VisibilityIcon style={{fontSize: '16px'}} /> : <VisibilityOffIcon style={{fontSize: '16px'}} />
      }
      
    </StyledButtonViewMoreAndLess>
  )
}










