import { Alert, AlertColor } from '@mui/material';
//import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
type NotificationProps ={
    open: boolean,
    message: string,
    severity: AlertColor | undefined,
}

const ToastView: React.FC<NotificationProps> = ({ open, message, severity}) => {

  return (
    <Box sx={{ width: '100%',}}>
      {open? 
      <Alert
      severity={severity}
      sx={{
        position: 'fixed',
        top:70,
        left:10,
        right:10,
        zIndex: 10000,
        borderRadius:2,
      }}
      >
        {message}
      </Alert>
        :null}
    </Box>
    );
  
};

export default ToastView

