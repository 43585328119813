import {SagaIterator} from '@redux-saga/core'
import {all, call, put, takeLatest} from 'redux-saga/effects'
import login from 'services/login/email'
import loginWithGoogle from 'services/login/google'
import {setError, setLoading, setUser, userLoginSagas, loginGoogleSagas} from 'redux/user/reducer'

function* loginSaga(action: any) {
  yield put(setLoading(true))
  try {
    const { data: user, status } = yield call(login, action?.payload?.email, action?.payload?.password)
    if (status === 'OK') {
      yield put(
        setUser({
          email: user.email, 
          token: user.session.idToken,
          ...user
        })
      )      
      yield put(setError(false))
      // redirect to home
      setTimeout(() => {
        window.location.href = '/'
      }, 1000)
    } else {
      // yield put(setError('Error al intentar iniciar sesión'));
      // clean user
      yield put(setUser(null))
    }
  } catch (err: any) {
    yield put(setError(`Error servicio iniciando sesión, ${err}`))
  } finally {
    yield put(setLoading(false))
  }
}

function* loginGoogleSaga(action: any) {
  yield put(setLoading(true))
  try {
    const { data: user } = yield call(loginWithGoogle, action?.payload?.credential)
   if (user.uid) {
      yield put(
        setUser({
          ...user
        })
      )
   } else {
      console.error('loginGoogleSaga error', user)
      yield put(setError('Login google error'))  
   }
  }
  catch (err: any) {}
}

export function* userGoogleSagas(): SagaIterator {
  yield all([takeLatest(loginGoogleSagas.trigger, loginGoogleSaga)])
  yield all([takeLatest(userLoginSagas.trigger, loginSaga)])
}



